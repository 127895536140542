import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link, useHistory} from "react-router-dom";
//import {
//  TransitionGroup,
//  CSSTransition,
//} from 'react-transition-group'
//import ScrollToTop from './ScrollToTop'
import Cookies from 'js-cookie'
//import PropTypes from "prop-types";
//
//
//import Home from './../pages/home'
//import Produtos from './../pages/produtos'
//import Comprar from './../pages/comprar'
//import About from './../pages/aboutUs'
//import Why from './../pages/porque'
//import Resell from './../pages/resell'
//import SocialCauses from './../pages/socialcauses'
//import Contacts from './../pages/contactos'
//import Faq from './../pages/faq'
//import ErrorPage from "./../pages/404";


/*Images*/ 
import conta from './../../assets/icons/icon-perfil.png'
import cart from './../../assets/icons/icon-carrinho.png'
//import blog from './../../assets/icons/blog.png'






/* Test Imports */ 


import logo from "./../../assets/logos/babulogo.png"




const Selector = ()=>{
  let history = useHistory();
  let language = localStorage.getItem("chosenlanguage");

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const langChange = (e)=>{
    let lang = e.target.value;
    localStorage.setItem("chosenlanguage", lang)
    switch(lang){
      case 'pt-PT':
          document.getElementById("pt").click();break;
      case 'es-ES':
        document.getElementById("es").click();break;
      case 'fr-FR':
        document.getElementById("fr").click();break;
      default:
        document.getElementById("en").click();break;
  }
  }
 return(
  <div className="selector-wrapper">
    <select name="lang" id="langSelect" value={language} onChange={(e)=>langChange(e)}>
      <option value="en-GB">EN</option>
      <option value="es-ES">ES</option>
      <option value="fr-FR">FR</option>
      <option value="pt-PT">PT</option>
    </select>
  </div>
 )
}



class Header extends Component {
    constructor(props){
        super(props)
        this.state={
          openClose:false,
          lang:"",
        }
        this.openCloseMenu = this.openCloseMenu.bind(this)
        this.langChange = this.langChange.bind(this)
    
      }
      
      componentDidMount(){
        switch(localStorage.getItem("chosenlanguage")){
          case "en-GB": this.setState({lang:"EN"}); break;
          case "es-ES": this.setState({lang:"ES"}); break;
          case "fr-FR": this.setState({lang:"FR"}); break;
          case "pt-PT": this.setState({lang:"PT"}); break;
          default: this.setState({lang:"EN"}); break;
        }
      }
    
      componentWillUnmount(){
        this.catchingIndex();
      }

      langChange(e){
        let lang = e.target.value;
        this.setState({lang});
        localStorage.setItem("chosenlanguage", lang)
        window.location.reload(false);
      }
    
      openCloseMenu(event){

        this.setState({openClose: !this.state.openClose})
        let body = document.getElementsByTagName('body')[0]
        body.classList.toggle('menu-open');
        if(body.classList.contains('menu-open') === this.state.openClose){
          body.classList.toggle('menu-open');
        }
    
      }
      
      catchingIndex(theindex){
        this.setState({indexservices:theindex})
      
      }
  render() {
    let url='https://blog.babueco.com';
    let item_cart = Cookies.get('woocommerce_items_in_cart');
    let item_count = Cookies.get('shopCartCount');
    let language = localStorage.getItem("chosenlanguage");


    return (
            <header className="menu">
              <div className="abovemenu"><p>Gastos de envío gratis en pedidos superiores a 10€</p></div>
              <nav className="navegacao">
                  <div className="navegacao__logo">
                      <Link to="/"><img src={logo} alt=""/></Link>                  
                  </div>
                  <div className="navegacao__menus">
                    <Router>
                       <Selector />
                    </Router>
                    <div className="menus__perfil icon">
                        <a href="https://shop.babueco.com/es/mi-cuenta/">
                        <img src={conta} alt=""/>
                        </a>
                    </div>
                    <div className="menus__carrinho icon">
                        <a href="https://shop.babueco.com/es/carrito/">
                        <img src={cart} alt=""/>
                        {item_count ? <span className="bolinha">{item_count}</span> : ""}
                        </a>
                    </div>

                      <div className="menus__hamburger" >
                          <div className="hamburger"   onClick={this.openCloseMenu} ><span></span></div>
                          <div className={ this.state.openClose ? "itens__hamburger opened" : "itens__hamburger"} onClick={this.openCloseMenu}>
                            <ul >
                              {/*<a href="https://natal.babueco.com/" className="neat_feature" target="_blank"><li className="menu-item">Cascanueces</li></a>*/}
                              <OldSchoolMenuLink activeOnlyWhenExact={true} to="/" label="Inicio"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/productos" label="Productos"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/tienda" label="Tienda"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/porque" label="¿Por qué Babu?"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/sobre" label="Quiénes somos"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/causas" label="Causas sociales"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/preguntasfrecuentes" label="Preguntas frecuentes"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/donde" label="Dónde encontrar"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/contactos" label="Contactos"  onClick={this.openCloseMenu} />
                            
                            </ul>
                            <div className="filler"></div>
                          </div>
                      </div>
                  </div>
              </nav>
      </header> 
    )
  }
}

const OldSchoolMenuLink = ({ label, to, activeOnlyWhenExact, onClick }) => (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <li  onClick={onClick}  className={match ? "menu-item active" : "menu-item"}>
          <Link to={to} className="menu-item-in" >{label}</Link>
        </li>
      )}
    />
  );


export default Header
