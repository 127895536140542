import React, { Component } from 'react'



import escova from './../../../../assets/produtos/escova.png'


export default class Cabelo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/cepillo-de-pelo/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Cepillo de pelo</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={escova} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composición</h3>
                                      <p>Cepillo para el pelo cuyo mango y cerdas han sido creados al 100% con bambú. Disfruta de una comodidad inigualable al cepillarte el cabello gracias a su perfecta ergonomia.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Caucho natural</h3>
                                      <p>Hecho de caucho natural, este cepillo tiene cerdas redondeadas que causan un efecto de masaje en el cuero cabelludo.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Uso</h3>
                                      <p>Para un buen uso, le recomendamos que se quite el cabello diariamente, lo lave manualmente y en caso de contacto con agua que luego se coloca en un lugar seco.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






