import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 

import floss from './../../../../assets/produtos/dentalfloss.png'


import panda from './../../../../assets/produtos/info/pandacloud.png'


// utilização

import fio1 from './../../../../assets/produtos/fio/fio1.mp4'
import fio2 from './../../../../assets/produtos/fio/fio2.mp4'
import fio3 from './../../../../assets/produtos/fio/fio3.mp4'



export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/dental-floss/",
        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }


  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Dental floss</h5></div>
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={floss} alt="FIO" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Size</h3>
                                    <p>Babu's dental floss is 50 meters long, allowing for an average of 250 uses.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Composition</h3>
                                    <p>This nylon floss has in its composition activated charcoal to aid in the removal of impurities, allowing for fresher, cleaner and healthier teething.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Characteristics</h3>
                                    <p>The packaging is designed to minimize waste so that the packaging itself contains dental floss and will only be placed in recycling at the end of the product's life.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
            <section className="utilizar">
                <div className="utilizar_container">
                    <div className="panda">
                        <img src={panda} alt="" />
                    </div>
                    <div className="utilizar_title">
                        <h3>How to floss?</h3>
                        <p>Find out how to floss moving your mouse over images</p>
                    </div>
                    <div className="illustration_container">
                        <div className="illustration_content">
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step1" ? true : false}
                                        playsinline	
                                        url={[fio1]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Use about 40cm of dental floss and wrap around the middle fingers.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step2" ? true : false}
                                        playsinline	
                                        url={[fio2]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Hold the floss with the index and flea thumb.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step3" ? true : false}
                                        playsinline	
                                        url={[fio3]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Floss each tooth with a C motion. Repeat the process 2 times on each tooth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </section>
    </main>
    )
  }
}
