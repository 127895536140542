import React, { Component } from 'react'



import escova from './../../../../assets/produtos/escova.png'


export default class Cabelo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/bamboo-hairbrush/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Hairbrush</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={escova} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composition</h3>
                                      <p>Biodegradable hairbrush with handle and bristles made from 100% bamboo. Designed for ease of use and comfort when brushing your hair. </p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Natural Rubber</h3>
                                      <p>Made from natural rubber, the rounded tips on the bristles create a massage effect on the scalp.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Use</h3>
                                      <p>To keep in optimum condition, we recommend removing the hair on a daily basis, washing it by hand and leaving to dry naturally.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






