import React, { Component } from 'react'

import Beach from './../../assets/produtos/dirtybeach.jpg'
import redPanda from './../../assets/produtos/panda-vermelho-carlosnunes.jpg'
import guru from './../../assets/produtos/causas003.jpg'
import causas from './../../assets/produtos/causas.jpg' 

export default class socialCauses extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
  render() {
    return (
    <div className="causes">
        <section className="hero">
            <div className="cta__">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={causas} alt=""/>
                </div>
                <div className="cta__containertext">
                    <div className="container__text--white">
                        <h2>Causas sociais</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="introduction">
            <div className="container_textintro">
                <p>
                A Babu considera que a sua missão é muito mais do que disponibilizar alternativas ecológicas para vários produtos de consumo. Temos a convicção que devemos apoiar causas, organizações e pessoas que sejam apaixonadas por tornar o nosso planeta cada vez melhor, mais saudável e acima de tudo mais harmonioso.

                Assim sendo a Babu dedica parte das suas receitas no apoio a três vertentes: 
                <ul>
                    <li><span>Preservação ambiental</span></li>
                    <li><span>Conservação das espécies</span></li>
                    <li><span>Reeducação para melhores hábitos e conduta</span></li>
                </ul>
                </p>
            </div>  
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Preservação ambiental</h2>
                        <div className="paragrafos">
                            <p>Apoiamos organizações e pessoas que desenvolvem acções de preservação do meio  ambiente, mais concretamente no que concerne à limpeza e recolha de objectos poluentes e/ou lixo inadvertidamente descartado em praias e florestas.  
                            Estas acções de recolha não só melhoram a qualidade destes locais, como também são um meio de sensibilização para uma maior consciência ambiental e civismo público.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={Beach} alt="" />
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={redPanda} alt="" />
                </div>
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Conservação das espécies
                        </h2>
                        <div className="paragrafos">
                            <p>Estabelecemos parcerias com instituições que têm como propósito proteger e promover o desenvolvimento de várias espécies de seres vivos que estão ameaçadas no seu habitat natural e que podem nestas instituições viver em condições semelhantes à natureza e, quando possível, serem reintegradas no seu meio de origem, fomentando assim a preservação da espécie e mitigando os riscos de extinção.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Reeducação para melhores hábitos e condutas
                        </h2>
                        <div className="paragrafos">
                            <p>Mais do que resolver o problema imediato da consequência, é importante recuar um pouco e focarmo-nos também na causa, de modo a que não se repitam os mesmos resultados. É por isso que a Babu aposta também em organizações e instituições de ensino que têm a paixão de transmitir uma visão melhorada do papel da humanidade e o seu impacto no nosso ecossistema através de workshops, palestras e acções de sensibilização em novos hábitos de consumo, assim como novas formas de lidar com o desperdício ou mesmo reduzi-lo por completo.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={guru} alt="" />
                </div> 
            </div>
        </section>

    </div>
    )
  }
}
