import React, { Component } from 'react'
import ReactPlayer from 'react-player'

// Videos

import cerdas from './../../../../assets/cerdas.mp4'



/*images */ 

import panda from './../../../../assets/produtos/info/panda.png'

import kids from './../../../../assets/produtos/info/kids.png'


import carbonBrush from './../../../../assets/produtos/info/carvao_img.png'

import adultBrush1 from './../../../../assets/produtos/info/escova_cor1.png'
import adultBrush2 from './../../../../assets/produtos/info/escova_cor2.png'
import adultBrush3 from './../../../../assets/produtos/info/escova_cor3.png'
import adultBrush4 from './../../../../assets/produtos/info/escova_cor4.png'
import kidsBrush1 from './../../../../assets/produtos/info/nuvemkids1.png'
import kidsBrush2 from './../../../../assets/produtos/info/nuvemkids2.png'

import EscovaHoriz from './../../../../assets/produtos/info/escova_horizontal.png'

import Regua from './../../../../assets/produtos/info/reguakids.png'


class Escovas extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/escovas-de-dentes/",
            progress: "100%",
            current_img: "adultone",
            kids: false,
            carbon:false,
            adult:false,
        }
        this.handleProgressBar = this.handleProgressBar.bind(this);
        this.changeImg = this.changeImg.bind(this);
    }

    handleProgressBar(progress){
        let newprogress = progress; 
        this.setState({
            progress: newprogress
        })
    }

    changeImg(text){
        let newimg = text;
        this.setState({
            current_img: newimg
        })
    }

    adultColours(){
        let adult = !this.state.adult;
        this.setState({adult})
    }

    kidsColours(){
        let kids = !this.state.kids;
        this.setState({kids})
    }

    carbonColours(){
        let carbon = !this.state.carbon;
        this.setState({carbon})
    }

  render() {
    return (
        <main>
            <section className="infoproducts">

                <section className="tipos">
                    
                    <div className="brush">
                        <div className="brush__title"><h5>Escovas de dentes</h5></div>
                         
                        <div className="brush__controls"> 
                            <div className="measures">
                                <div className="blockb blockbr block1"></div>
                                <div className="blockb blockbl block2"></div>
                                <div className="blockm block--3">3 cm</div>
                                <div className="blockm block--16">16 cm</div>
                            </div>
                            <div className="escova__img">
                                <img src={EscovaHoriz} alt="" />
                            </div>
                        </div>
                    </div>
                    <article className="info__escovas">
                        <section className="info__escovasB">
                            <div className="info__sec info__sec--one">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>As cerdas</h3>
                                        <p>As cerdas foram desenhadas num formato crescente de forma a chegar aos pontos mais difíceis criando uma escovagem mais eficaz</p>
                                    </div>
                                </div>               
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Cabo ergonómico</h3>
                                        <p>As Babu estão desenhadas para se adaptarem perfeitamente à sua mão e boca de modo a que o seu manuseamento seja perfeito no processo de escovagem dos seus dentes, dando-lhe a possibilidade de optar por dois tamanhos: adulto e criança.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Benefícios</h3>
                                        <p>É a planta com maior concentração de sílica em todo o planeta, que reforça o trabalho do cálcio para a construção de ossos e dentes fortes. Os nutrientes do bambu também são benéficos para a gengiva, fortalecendo-a.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                        <div className="dureza">
                            <div className="adulto">
                                <div className="tipo__dureza"><h4>Tipos de cerdas</h4></div>
                                <div className="adulto__dureza">
                                    <ul>
                                        <li onClick={() => this.handleProgressBar("5%")}>Extra suave</li>
                                        <li onClick={() => this.handleProgressBar("40%")}>Suave</li>
                                        <li onClick={() => this.handleProgressBar("70%")}>Média</li>
                                        <li onClick={() => this.handleProgressBar("100%")}>Firme</li>
                                    </ul>
                                    <div style={{width:this.state.progress}} className="progressbar">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cor">
                            <div className="cor_adulto">
                                <div className="cor__title"><h5>Cores das cerdas</h5></div>
                                <div className="cor__choices">
                                    <div className="cor__item"><img src={adultBrush1} alt="" /><h6>Verde e branco</h6></div>
                                    <div className="cor__item"><img src={adultBrush2} alt="" /><h6>Amarelo e verde</h6></div>
                                    <div className="cor__item"><img src={adultBrush3} alt="" /><h6>Azul e verde</h6></div>
                                    <div className="cor__item"><img src={adultBrush4} alt="" /><h6>Azul e branco</h6></div>

                                </div>  
                            </div>
                        </div>
                    </section>
                    <section className="hero">
                        <div className="cta__ carbon">
                            <div className="cta__image">
                                <img className="diagonal--inverted" src={carbonBrush} alt="" />
                            </div>
                            <div className="cta__containertext" >
                                <div className="container__text">
                                    <h2>Escova de carvão ativado</h2>
                                    <div className="paragrafos">
                                        <p>A nossa escova Babu de carvão ativado é ideal na eliminação de maus odores,
                                        bactérias, manchas causadas pela alimentação, contribuindo também para a proteção da placa bacteriana.</p>
                                        <br/>
                                        <p>Tal como as outras escovas Babu as cerdas são em nylon sem BPA com diferença do componente de carvão ativado de bambu.</p>
                                    </div>
                                    <div className="carbon">
                                        <h6>Características</h6>
                                        <div className="carbon_brush">
                                            <div className="carbon_brush--info">
                                                <div className="bola dureza--medium"></div>
                                                <h6>Dureza média</h6>
                                            </div>
                                            <div className="carbon_brush--info">
                                                <div className="bola only--color"></div>
                                                <h6>Cor única</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </section>
                    <section className="kids_section">
                        <div className="panda_section">
                            <div className="panda_img"><img src={panda} alt=""/></div>
                        </div>
                        <article className="kids_container">
                            <div className="kids_title">
                                <h3>Já conhece as Babu</h3>
                                <img src={kids} alt=""/>
                            </div>
                            <div className="kids_info">
                                <div className="kids_info--img">
                                    <img src={Regua} alt=""/>
                                </div>
                                <div className="kids_info--text">
                                    <div className="description">
                                        <div className="description-content">
                                            <p>
                                                As crianças podem desde já ajudar a perservar o planeta com as Babu Kids.<br/>
                                                As nossas escovas foram desenhadas para uma adaptação perfeita ao pulso e dentição de uma criança.
                                            </p>
                                            <br/>
                                            <span>Disponível em cerda extra suave</span>
                                        </div>
                                    </div>
                                    <div className="colours">
                                        <h4>As nossas cores</h4>
                                        <div className="colours-container">
                                            <div className="colour-box"> <img src={kidsBrush1} alt=""/></div>
                                            <div className="colour-box"> <img src={kidsBrush2} alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </article>                 
                    </section>
                    <div className="buynow-btn">
                        <a href={this.state.link}><span>Compre já</span></a>
                    </div>
                    <div className="used">
                        <div className="used"><h3>O que fazer às escovas Babu depois de serem utilizadas?</h3></div>
                        <div className="video__info">
                            <ReactPlayer
                                playing
                                playsinline	
                                url={[cerdas]}
                                loop
                                muted
                            />
                        </div>
                    </div>
            </section>
        </main>
    )
  }
}

export default Escovas
