import React, { Component } from 'react'


import talheres from './../../../../assets/produtos/comprar/talheres.png'


export default class Talheres extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/cutlery-set/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Cutlery</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={talheres} alt="talheres" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Amount</h3>
                                      <p>This set features three bamboo cutlery pieces: spoon, fork and knife.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Use</h3>
                                      <p>A set that allows you to choose an ecological alternative and meet the needs for any meal away from home.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durability</h3>
                                      <p>To preserve their durability they should be washed by hand and placed in a dry place.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






