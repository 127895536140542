import React, { Component } from 'react'
import ReactPlayer from 'react-player'

// Videos

import cerdas from './../../../../assets/cerdas.mp4'



/*images */ 

import panda from './../../../../assets/produtos/info/panda.png'

import kids from './../../../../assets/produtos/info/kids.png'


import carbonBrush from './../../../../assets/produtos/info/carvao_img.png'

import adultBrush1 from './../../../../assets/produtos/info/escova_cor1.png'
import adultBrush2 from './../../../../assets/produtos/info/escova_cor2.png'
import adultBrush3 from './../../../../assets/produtos/info/escova_cor3.png'
import adultBrush4 from './../../../../assets/produtos/info/escova_cor4.png'
import kidsBrush1 from './../../../../assets/produtos/info/nuvemkids1.png'
import kidsBrush2 from './../../../../assets/produtos/info/nuvemkids2.png'

import EscovaHoriz from './../../../../assets/produtos/info/escova_horizontal.png'

import Regua from './../../../../assets/produtos/info/reguakids.png'





class Escovas extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/toothbrushes/",
            progress: "100%",
            current_img: "adultone",
            kids: false,
            carbon:false,
            adult:false,
        }
        this.handleProgressBar = this.handleProgressBar.bind(this);
        this.changeImg = this.changeImg.bind(this);
    }

    handleProgressBar(progress){
        let newprogress = progress; 
        this.setState({
            progress: newprogress
        })
    }

    changeImg(text){
        let newimg = text;
        this.setState({
            current_img: newimg
        })
    }

    adultColours(){
        let adult = !this.state.adult;
        this.setState({adult})
    }

    kidsColours(){
        let kids = !this.state.kids;
        this.setState({kids})
    }

    carbonColours(){
        let carbon = !this.state.carbon;
        this.setState({carbon})
    }

  render() {
    return (
        <main>
            <section className="infoproducts">

                <section className="tipos">
                    
                    <div className="brush">
                        <div className="brush__title"><h5>Toothbrush</h5></div>
                        <div className="brush__controls"> 
                            <div className="measures">
                                <div className="blockb blockbr block1"></div>
                                <div className="blockb blockbl block2"></div>
                                <div className="blockm block--3">3 cm</div>
                                <div className="blockm block--16">16 cm</div>
                            </div>
                            <div className="escova__img">
                                <img src={EscovaHoriz} alt="" />
                            </div>
                        </div>
                    </div>
                    <article className="info__escovas">
                        <section className="info__escovasB">
                            <div className="info__sec info__sec--one">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>The bristles</h3>
                                        <p>The bristles have been designed in a growing shape to reach the hardest spots fora  more effective brushing.</p>
                                    </div>
                                </div>               
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Ergonomic handle</h3>
                                        <p>Babu's toothbrushes are designed to fit your hand and mouth perfectly, making your brushing process perfect.  There are two sizes: adult and child.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Benefits</h3>
                                        <p>Bamboo is the plant with the highest concentration of silica on the planet, which strengthens the work of calcium to build strong bones and teeth. The nutrients in bamboo are also beneficial to the gum by strengthening it.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                        <div className="dureza">
                            <div className="adulto">
                                <div className="tipo__dureza"><h4>Bristle Types</h4></div>
                                <div className="adulto__dureza">
                                    <ul>
                                        <li onClick={() => this.handleProgressBar("5%")}>Extra soft</li>
                                        <li onClick={() => this.handleProgressBar("40%")}>Soft</li>
                                        <li onClick={() => this.handleProgressBar("70%")}>Medium</li>
                                        <li onClick={() => this.handleProgressBar("100%")}>Firm</li>
                                    </ul>
                                    <div style={{width:this.state.progress}} className="progressbar">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cor">
                            <div className="cor_adulto">
                                <div className="cor__title"><h5>Bristle Colors</h5></div>
                                <div className="cor__choices">
                                    <div className="cor__item"><img src={adultBrush1} alt="" /><h6>Green and white</h6></div>
                                    <div className="cor__item"><img src={adultBrush2} alt="" /><h6>Yellow and green</h6></div>
                                    <div className="cor__item"><img src={adultBrush3} alt="" /><h6>Blue and green</h6></div>
                                    <div className="cor__item"><img src={adultBrush4} alt="" /><h6>Blue and white</h6></div>
                                </div>  
                            </div>
                        </div>
                    </section>
                    <section className="hero">
                        <div className="cta__ carbon">
                            <div className="cta__image">
                                <img className="diagonal--inverted" src={carbonBrush} alt="" />
                            </div>
                            <div className="cta__containertext" >
                                <div className="container__text">
                                    <h2>Activated carbon brush</h2>
                                    <div className="paragrafos">
                                        <p>Our Babu Activated Charcoal Brush is ideal for eliminating bad odors, bacteria, food stains and contributing to the protection of the bacterian plaque.</p>
                                        <br/>
                                        <p>Like all the other Babu toothbrushes, the bristles are made of nylon without BPA. The difference is the bamboo activated carbon component.</p>
                                    </div>
                                    <div className="carbon">
                                        <h6>Characteristics</h6>
                                        <div className="carbon_brush">
                                            <div className="carbon_brush--info">
                                                <div className="bola dureza--medium"></div>
                                                <h6>Medium hardness</h6>
                                            </div>
                                            <div className="carbon_brush--info">
                                                <div className="bola only--color"></div>
                                                <h6>Single color</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </section>
                    <section className="kids_section">
                        <div className="panda_section">
                            <div className="panda_img"><img src={panda} alt=""/></div>
                        </div>
                        <article className="kids_container">
                            <div className="kids_title">
                                <h3>Do you already know </h3>
                                <img src={kids} alt=""/>
                            </div>
                            <div className="kids_info">
                                <div className="kids_info--img">
                                    <img src={Regua} alt=""/>
                                </div>
                                <div className="kids_info--text">
                                    <div className="description">
                                        <div className="description-content">
                                            <p>
                                            Kids can now help preserve the planet with Babu Kids. Our toothbrushes are designed for a perfect fit to a child's wrist and teeth.
                                            </p>
                                            <br/>
                                            <span> Available in extra soft bristles.</span>
                                        </div>
                                    </div>
                                    <div className="colours">
                                        <h4>Our colors</h4>
                                        <div className="colours-container">
                                            <div className="colour-box"> <img src={kidsBrush1} alt=""/></div>
                                            <div className="colour-box"> <img src={kidsBrush2} alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </section>
                    <div className="buynow-btn">
                        <a href={this.state.link}><span>Buy now</span></a>
                    </div>
                    <div className="used">
                        <div className="used"><h3>What to do with Babu toothbrushes after use?</h3></div>
                        <div className="video__info">
                            <ReactPlayer
                                playing
                                playsinline	
                                url={[cerdas]}
                                loop
                                muted
                            />
                        </div>
                    </div>
            </section>
        </main>
    )
  }
}

export default Escovas
