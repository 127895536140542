import React, { Component } from 'react'
//import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/whybabu.png'


//import escolher from './../../assets/why_pt.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>Why Babu?</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>WHY BAMBOO?</h4>
                <p>
                    Bamboo's characteristics as a plant, its remarkable properties as a material and its current applications in the world create a wide range of possible uses, enhanced environment and improved quality of life. 
                    <br/>
                    The growth of world population has caused strong pressures on the environment, decreasing the quantity and quality of forest resources, thus contributing to the interest in new plant resources, such as bamboo, wich has been rediscovered as a renewable and non-polluting resource.
                    <br/>
                    Today, in a world where sustainable development is no longer an option but a necessity, bamboo can be used abundantly and replace many materials in the manufacture of various products, due to the shorter growth time and the ability to be an environmental regenerator.

                </p>
            </div>
          </div>
          <div className="aboutus__sabias">
              <div className="sabias__boxtitle">
                  <h3 className="sabias__titulo">DID YOU KNOW THAT BAMBOO…</h3>
              </div>
              <div className="sabias porque">
                  <div className="sabias__column">
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>GROWS VERY FAST</h4>
                          </div>
                          <p>Bamboo is the fastest growing plant in the world, currently the record has been reached in Japan, where this plant grew 121 centimeters in just 24 hours!</p>
                      </div>
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>HAS MEDICAL PROPERTIES</h4>
                          </div>
                          <p>Bamboo charcoal has several medicinal properties, its use is quite varied, sometimes used in soaking baths, for example as its leaves are used in teas, with multiple benefits.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>IT´S GREAT FOR TEETH</h4>
                        </div>
                        <p>It is the plant with the highest concentration of silica on the planet, which reinforces the work of calcium to build strong bones and teeth.<br/> The bamboo nutrients are also beneficial to the gum, strengthening it.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>IT'S A PAPER SUBSTITUTE</h4>
                        </div>
                        <p>It is a robust plant that develops its own antibacterial components and grows very well without the need of pesticides. Its porous fibers can produce breathing tissues and are as soft as silk. </p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>HAS MULTIPLE PURPOSES</h4>
                        </div>
                        <p>There are currently over 4000 possible uses for bamboo and with each passing day new possibilities are discovered and applied. Recent studies indicate that ethanol can be removed from bamboo and can also be used as fuel.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>REGENERATIVE CAPACITIES</h4>
                        </div>
                        <p>It can regenerate after cutting, can live up to 100 years and, if properly treated, can become a durable material for many generations.<br/> A low-cost renewable raw material with many possibilities for use, bamboo is being increasingly used for environmental conservation.</p>
                    </div>
                  </div>

              </div>
          </div>

          <div className="aboutus__sabias">
            <div className="sabias__boxtitle">
                <h3 className="sabias__titulo">WHY IS CHOOSING A BABU TOOTHBRUSH SO IMPORTANT?</h3>
            </div>
            <div className="sabias porque">
                <div className="sabias__column">
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4> ALTERNATIVE TO TRADITIONAL TOOTHBRUSHES</h4>
                        </div>
                        <p>It is a real alternative to plastic brushes by helping to solve a problem that affects us all, as traditional brushes are responsible for tons of waste each year, causing a huge environmental impact.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>BIODEGRADABLE<br/> PRODUCTS</h4>
                        </div>
                        <p>Unlike traditional toothbrushes, that take more than 400 years to decompose, Babu Bamboo toothbrushes are totally biodegradable.<br/> In addition to being environmentally friendly, you are using a product from nature.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>NATURAL ANTIBACTERIAL</h4>
                        </div>
                        <p>Bamboo, as a natural antibacterial, has in its constitution agents that prevent the proliferation of harmful bacteria.<br/> It is also a non-porous material that prevents fungi and bacteria contamination, helping to improve your oral hygiene.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>ERGONOMIC <br/> Handle</h4>
                        </div>
                        <p>Babu's toothbrushes are designed to fit your hand and mouth perfectly, making your brushing process perfect.  There are two sizes: adult and child.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>THE BEST ORAL CLEANING EXPERIENCE</h4>
                        </div>
                        <p>Babu's bristles are specially designed to give you the best brushing experience, protecting your teeth and gums while leaving them clean and healthy.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>SMALL GESTURES, BIG CHANGES</h4>
                        </div>
                        <p>Small attitudes made repeatedly by many have a huge impact!<br/> Using Babu toothbrushes will help make big changes happen. While having a much better brushing experience than with traditional ones, it will also ensure a better future for coming generations.</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog