import React, { Component } from 'react'



import cotonetes from './../../../../assets/produtos/pastadedentes.png'


export default class Pasta extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/dentrifice/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Dentifrice</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={cotonetes} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composition</h3>
                                      <p>Le premier dentifrice Babu, à base d'ingrédients naturels et sans SLS (Sodium lauryl sulfate) ni Triclosan. Ne convient pas aux enfants. Contient du fluor.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Saveur</h3>
                                      <p>A un goût de citron, de sorte qu'après le brossage, vous ressentez une fraîcheur de propreté combinée au confort de savoir qu'il est parfaitement sain sans introduire de produits chimiques nocifs dans le corps.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>100% recyclable</h3>
                                      <p>Babu s'est fortement engagé à présenter un dentifrice avec des ingrédients sains, mais aussi pratique, hygiénique et surtout: avec la plus petite empreinte carbonique possible. Nous avons donc opté pour un tube 100% recyclable, avec la plus faible émission de CO2 sur l'ensemble de son cycle de vie, du processus de production à son recyclage.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
        </section>
      </main>
      )
    }
  }






