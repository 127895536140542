import React, { Component } from 'react'

// Media 

import Envio from './../../assets/portes_pt.png'



//babu
import escovas from './../../assets/produtos/comprar/escovas.png'
import escovaskids from './../../assets/produtos/comprar/escovaskids.png'
import canas from './../../assets/produtos/comprar/canas-transportadora.png'
import cotonetes from './../../assets/produtos/comprar/cotonetes.png'
import konjac from './../../assets/produtos/comprar/esponjakonjac.png'
import fiodental from './../../assets/produtos/comprar/fiodental.png'
import cabelo from './../../assets/produtos/comprar/cabelo.png'
import pasta from './../../assets/produtos/comprar/pastadedentes.png'



//babulife
import talheres from './../../assets/produtos/comprar/c-talheres.png'
import estojo from './../../assets/produtos/comprar/c-estojo.png'
import coffeespoons from './../../assets/produtos/comprar/c-coffeespoons.png'
import straw from './../../assets/produtos/comprar/palhinha.png'
import colhergarfo from './../../assets/produtos/comprar/c-forkspoon.png'


import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"


import fotoproduct from './../../assets/produtos/foto-produtos.png' //foto background
import lifeproduct from './../../assets/produtos/kitshot.jpg'
import benficalogo from "./../../assets/logos/benficalogo.png"
import Natallogo from "./../../assets/logos/selllogont.png"
import Valentine from "./../../assets/logos/valentine.png"



class Comprar extends Component {
    constructor(props){
        super(props)
        this.state={
            higiene:[
                {id:"escova", name:"Escovas de dentes", img:escovas, link:"https://shop.babueco.com/produto/escovas-de-dentes/" },
                {id:"kids", name:"Escovas de dentes infantil", img:escovaskids, link:"https://shop.babueco.com/produto/escova-de-dentes-kids/" },
                {id:"cana", name:"Canas de transporte", img:canas, link:"https://shop.babueco.com/produto/cana-transportadora/" },
                {id:"fio", name:" Fio dentário", img:fiodental, link:"https://shop.babueco.com/produto/fio-dentario/" },
                {id:"cotonetes", name:"Cotonetes", img:cotonetes, link:"https://shop.babueco.com/produto/cotonetes/" },
                {id:"konjac", name:"Esponjas konjac", img:konjac, link:"https://shop.babueco.com/produto/esponja-de-konjac/" },
                {id:"escovacabelo", name:"Escova de cabelo", img:cabelo, link:" https://shop.babueco.com/produto/escova-de-cabelo-bambu/" },
                {id:"pastadedentes", name:"Pasta de dentes", img:pasta, link:"  https://shop.babueco.com/produto/pasta-de-dentes/" },
                

            ],
            babulife:[
                {id:"palhinhas", name:"Palhinhas", img:straw, link:"https://shop.babueco.com/produto/palhinhas/" },
                {id:"colhercafe", name:"Colheres de café", img:coffeespoons, link:"https://shop.babueco.com/produto/colheres-de-cafe/" },
                {id:"talheres", name:"Colher-garfo", img:colhergarfo, link:"https://shop.babueco.com/produto/colher-garfo/" },
                {id:"talheres", name:"Talheres", img:talheres, link:"https://shop.babueco.com/produto/talheres/" },
                {id:"estojo", name:"Estojo de refeição", img:estojo, link:"https://shop.babueco.com/produto/estojo-de-refeicao/" },

            ],
            switch:"higiene",
        }
        this.handleSwitch = this.handleSwitch.bind(this);
    }

handleSwitch(prop){
    let nextstate = prop;
    this.setState({switch:nextstate})
}

  render() {
    return (
        <main className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Loja</h2>
                                <p>Os produtos Babu podem fazer parte da sua vida e contribuir para a proteção do nosso planeta. Fique atento a todas as novidades!</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div>
                        <div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div>
                        <div className="benfica"><a href="https://benfica.babueco.com/" target="_blank"><img src={benficalogo} alt="" /></a></div>
                        {/*<div className=""><a href="https://shop.babueco.com/en/produto/babu-valentine" target="_blank"><img src={Valentine} alt="" /></a></div>*/}


                    </div>
                </header>
            </header>
            
            <section className="item-list">
            {this.state.switch === "higiene" ? 
                this.state.higiene.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <a href={item.link} ><img src={item.img} alt=""/></a>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                this.state.babulife.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <a href={item.link} ><img src={item.img} alt=""/></a>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            </section>
            <section className="envio-section">
                <div className="envio-img" >
                    <img src={Envio}  alt=""/>
                </div>
            </section>


        </main>
    )
  }
}

export default Comprar