import React, { Component } from 'react'
import $ from "jquery";
import FAQ from "./extras/FAQ";

//<img src={imgcontact} alt=""/>

import seta from './../../assets/icons/seta.png'
import imgcontact from './../../assets/produtos/foto-fala-connosco.png'

class Faq extends Component {
constructor(props){
    super(props)
    this.state={
        name:"",
        email:"",
        message:"",

    }
    this.handleAddition = this.handleAddition.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForm  = this.handleSubmit.bind(this);
}

handleAddition(e){
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleForm(){
    this.setState({
        formactivation:!this.state.formactivation
    })
}


  handleSubmit(e) {
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: ''
        });

        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "mailer.php",
        type: 'POST',
        data: {
            'name': this.state.name,
            'email': this.state.email,
            'message': this.state.message,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Su mensaje ha sido recibido!</h2><p>¡Nuestros agentes se pondrán en contacto con usted lo antes posible!</p></div>'
            });
            $('#formContact').slideUp();
            $('#formContact').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Lo sentimos pero hubo un error en el algoritmo</h1><p>Inténtelo más tarde o comuníquese directamente con info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
  }
  render() {
    return (
        <main className="titlewrapper faq">
            <header className="hero contact">
                <div className="cta__">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={imgcontact} alt=""/>
                    </div>
                    <div className="cta__containertext">
                        <div className="container__text--white">
                            <h2>Preguntas frecuentes</h2>
                        </div>
                    </div>
                </div>
            </header>
            <section className="faq_sec">
                <div className="faq_container">
                  <FAQ>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q1">
                        {(isOpen, onToggle) => {
                          return (
                              <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>¿Dónde se hacen los cepillos de dientes Babu?</span>
                              </div>
                              );
                              
                            }
                        }
                      </FAQ.Question>
                      <FAQ.Answer id="q1"> Los Babu se fabrican en Asia, donde se encuentra el bambú del mango de nuestros cepillos. La planta es el bambú Moso (Phyllostachys Edulis), una de las especies de bambú con mayor tasa de crecimiento del mundo sin comprometer el ecosistema, ya que no es comestible para distintas especies, tal como los osos panda. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q2">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>¿De qué están hechos los cepillos de dientes Babu?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q2"> El material de las cerdas es nailon libre de BPA y como todavía no existen alternativas biodegradables (excluyendo las cerdas de pelo de cerdo que, aunque sean biodegradables, son terribles en muchos aspectos), recomendamos que se retire las cerdas después de usar el cepillo y se las deposite en un ecopunto. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q3">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>¿Cuánto tiempo dura el cepillo de dientes?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q3"> El tiempo promedio es de 2 a 3 meses de uso, pero depende de varios factores, como la dureza de la cerda elegida, la presión ejercida durante el cepillado, la cantidad de usos diarios, entre otros. La mejor manera de analizar la durabilidad del cepillo de dientes es observar la conservación de las cerdas: cuando las cerdas ya tienen un alto desgaste significa que ha llegado el momento de cambiarlo.</FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q4">
                        {(isOpen, onToggle) => {
                            return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Después del cepillado ¿dónde debo guardar mi cepillo de dientes?</span>
                            </div>
                            );
                        }}
                        </FAQ.Question>
                        <FAQ.Answer id="q4">El bambú es un material muy absorbente y, por lo tanto, es recomendable que después del cepillado guardes tu Babu en un lugar seco y/o sin contacto constante con el agua, con el fin de evitar la humedad. Al estar en contacto con la humedad el Babu presenta un aspecto más oscuro.</FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q5">
                            {(isOpen, onToggle) => {
                            return (
                                <div className="question-tab">
                                    <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                    <span>¿Qué hacer con el cepillo de dientes cuando se le acaba su vida útil?</span>
                                </div>
                            );
                            }}
                        </FAQ.Question>
                        <FAQ.Answer id="q5">Cuando cambies tu Babu, te recomendamos que cortes las cerdas y las deposites en un ecopunto. A excepción de las cerdas, todo el resto del material es biodegradable, por lo que te recomendamos que deposites el envase en un ecopunto azul (para papel) y el mango puede enterrarse al lado de un árbol, por ejemplo.</FAQ.Answer>
                    </FAQ.QAItem>
                  </FAQ>
                </div>
           </section>
            <section className="contactus faq">
                <div className="container_title">
                        <h2>¿Tienes más preguntas?</h2>
                </div>
                <div className="container">
                    <form className="contactus__form" id="formContact" action="mailer.php" onSubmit={this.handleSubmit}>
                        <label htmlfor="name"> <span>Nombre</span>
                            <input type="text" name="name" value={this.state.name} onChange={this.handleAddition} required />
                        </label>
                        <label htmlfor="email"> <span>E-mail</span>
                            <input type="email" name="email" value={this.state.email} onChange={this.handleAddition} required/>
                        </label>
                        <label htmlfor="message"> <span>Mensaje</span>
                            <textarea name="message" value={this.state.message} onChange={this.handleAddition} required></textarea>
                        </label>
                        <input className="button__babu" type="submit" value="Enviar" />
                    </form>
                </div> 
            </section>
        </main>
    )
  }
}

export default Faq
