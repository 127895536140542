import React, { Component } from 'react'


import cotonetes from './../../../../assets/produtos/cotonetes.png'


export default class Swabs extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/cotton-swabs/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Cotton swabs</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={cotonetes} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composition</h3>
                                      <p>Our cotton swabs are made of bamboo and extra soft cotton.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Packing</h3>
                                      <p>Our packaging is made of cardboard and contains a total of 100 units.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Biodegradable</h3>
                                      <p>Babu cotton swabs are also biodegradable to protect our planet.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






