import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 


import esponjas from './../../../../assets/produtos/info/esponjas.png'


// utilização

import Esponja from './../../../../assets/produtos/konjac/esponja.mp4'
import Esfregar from './../../../../assets/produtos/konjac/esfrega.mp4'
import Minacao from './../../../../assets/produtos/konjac/minacao.mp4'


export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/konjac-sponges/",
        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }

  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Konjac sponges</h5></div>
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={esponjas} alt="konjac img" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Pure</h3>
                                    <p>If you have mixed or dry skin and are looking for a deep and smooth exfoliation, Pure konjac sponge is just right for you.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Activated charcoal</h3>
                                    <p>If you have oily, acne-prone skin, we suggest the konjac activated charcoal sponge which after exfoliation will rebalance the oilier areas of the face.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Characteristics</h3>
                                    <p>Our face scrub sponges are made from konjac root, 100% natural and without harmful ingredients.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
                <div className="buynow-btn">
                    <a href={this.state.link}><span>Buy now</span></a>
                </div>
            </section>
            <section className="tipos videos">
                <div className="brush">
                    <div className="brush__title"><h5>Use</h5></div>
                    <p>Find out how to use the konjac sponge by moving the mouse over images</p>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step1" ? true : false}
                                playsinline	
                                url={[Esponja]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Soak in water</h3>
                                    <p>Soak your konjac sponge until completely moist.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step2" ? true : false}
                                playsinline	
                                url={[Esfregar]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                                
                            </div>  
                            <div className="info__div">
                                <div className="text">
                                    <h3>Massage</h3>
                                    <p>Gently massage your face in a circular motion for a more complete cleansing.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                           <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>    
                                <ReactPlayer
                                playing={this.state.step === "step3" ? true : false}
                                playsinline	
                                url={[Minacao]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>After using</h3>
                                    <p>After using the konjac sponge you should store it in a dry place. Although it has antibacterial properties, it is advisable to change it after one month of use.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
      </section>
    </main>
    )
  }
}
