import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 



import panda from './../../../../assets/produtos/info/panda.png'
import kids from './../../../../assets/produtos/info/kids.png'

import canahorizontal from './../../../../assets/produtos/info/cana/canahorizontal.png'
import regua from './../../../../assets/produtos/info/cana/reguakids.png'




import canas from './../../../../assets/cana_fr.mp4'


export default class CanasInfo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/etui-de-transport/",
        }
    }
  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
            
            <div className="brush">
                <div className="brush__title"><h5>Étui de voyage</h5></div>
                <div className="brush__controls"> 
                    <div className="measures brush">
                        <div className="blockb blockbr block1"></div>
                        <div className="blockb  block2"></div>
                        <div className="blockm block--3"></div>
                        <div className="blockm block--16">21 cm</div>
                    </div>
                    <div className="escova__img">
                        <img src={canahorizontal} alt="" />
                    </div>
                </div>
            </div>
            <article className="info__escovas">
                <section className="info__escovasB">
                    <div className="info__sec info__sec--one">
                        <div className="info__div">
                            <div className="text">
                                <h3>Taille</h3>
                                <p>L’Étui de voyage de taille adulte, mesure 21 cm de haut et 2,5 cm de diamètre, la taille idéale pour tenir dans n'importe quel sac ou sac à dos.</p>
                            </div>
                        </div>               
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Avantages</h3>
                                <p>L’Étui de voyage est 100% en bambou. En plus de nous aider à transporter la brosse à dents, il la protège contre les mauvaises odeurs, bactéries et poussière.</p>
                            </div>
                        </div>
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Utilisation</h3>
                                <p>L’Étui de voyage est assez polyvalent dans son utilisation. En plus d'être utilisé pour transporter les brosses à dents, il peut être utilisé à transporter des pailles ou, même, à servir de boîte de rangement. L’étui vous attend !</p>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            </section>
            <section className="kids_section">
                <div className="panda_section">
                    <div className="panda_img"><img src={panda} alt=""/></div>
                </div>
                <article className="kids_container">
                    <div className="kids_title">
                        <h3>Connaissez-vous les Babu</h3>
                        <img src={kids} alt=""/>
                    </div>
                    <div className="kids_info">
                        <div className="kids_info--img">
                            <img src={regua} alt=""/>
                        </div>
                        <div className="kids_info--text">
                            <div className="description">
                                <div className="description-content">
                                    <p>
                                        Avec 17 cm de hauteur et 2,5 cm de diamètre, l’étui a la taille idéale pour tenir dans n'importe quel sac ou sac à dos.
                                        <br/>
                                        <br/>
                                        Fabriqué 100 % en bambou, cet étui portable, en plus de contribuer à la prévention contre odeurs, bactéries ou poussière, il permet aussi, le transport et la protection de la brosse à dents Babu.
                                    </p>

                                </div>
                            </div>
                            <div className="colours">
                                <div className="colours-container">
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
              </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
              <div className="used">
                <div className="used"><h3>Préservez votre Étui de voyage</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>

      </section>
    </main>
    )
  }
}
