import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import {
//  TransitionGroup,
//  CSSTransition,
//} from 'react-transition-group'
import ScrollToTop from './components/ScrollToTop'
//import Cookies from 'js-cookie'
//import PropTypes from "prop-types";


import Home from './pages/home'
import Produtos from './pages/produtos'
import Comprar from './pages/comprar'
import About from './pages/aboutUs'
import Why from './pages/porque'
import Resell from './pages/resell'
import SocialCauses from './pages/socialcauses'
import Contacts from './pages/contactos'
import Faq from './pages/faq'
import ErrorPage from "./pages/404";
import Hallowgreen from './components/Featured'




// componennts 
import Header from './components/header'
import Footer from './components/footer'

function App() {
  return (
    <div className="wrapper">
      <Router>
        <Route render={({ location })=>(
          <>
          <Header/>
        
          <ScrollToTop>
            <Switch location={location} >
                <Route exact path="/" render={()=> <Home />} />
                <Route path="/produtos" component={Produtos}/>
                <Route path="/loja" component={Comprar}/>
                <Route path="/sobre" component={About}/>
                <Route path="/porque" component={Why}/>
                <Route path="/causas" component={SocialCauses}/>
                <Route path="/contactos" component={Contacts}/>
                <Route path="/onde" component={Resell}/>
                <Route path="/perguntasfrequentes" component={Faq}/>
                <Route path="/Hallowgreen" component={Hallowgreen}/>


                <Redirect from="/productos" to="/produtos"/>
                <Redirect from="/tienda" to="/loja"/>
                <Redirect from="/sobre" to="/sobre"/>
                <Redirect from="/porque" to="/porque"/>
                <Redirect from="/causas" to="/causas"/>
                <Redirect from="/contactos" to="/contactos"/>
                <Redirect from="/donde" to="/onde"/>
                <Redirect from="/preguntasfrecuentes" to="/perguntasfrequentes"/>


                <Redirect from="/products" to="/produtos"/>
                <Redirect from="/store" to="/loja"/>
                <Redirect from="/about" to="/sobre"/>
                <Redirect from="/why" to="/porque"/>
                <Redirect from="/causes" to="/causas"/>
                <Redirect from="/contacts" to="/contactos"/>
                <Redirect from="/wheretofind" to="/onde"/>
                <Redirect from="/faq" to="/perguntasfrequentes"/>

                <Redirect from="/produits" to="/produtos"/>
                <Redirect from="/boutique" to="/loja"/>
                <Redirect from="/apropos" to="/sobre"/>
                <Redirect from="/pourquoi" to="/porque"/>
                <Redirect from="/causessociales" to="/causas"/>
                <Redirect from="/contact" to="/contactos"/>
                <Redirect from="/Outrouver" to="/onde"/>
                <Redirect from="/questions" to="/perguntasfrequentes"/>
                  
                
                <Route component={ErrorPage}/>

            </Switch>
          </ScrollToTop>
          </>
      )}/>
      <Footer/>
      </Router>
    </div>
    );
}

export default App;
