import React, { Component } from 'react'

import feature from './../../assets/destaque/gads.png'

import ghostie from './../../assets/destaque/ghostie.png'
import broom from './../../assets/destaque/broom.png'
import witch from './../../assets/destaque/brush_witch.png'
import spidey from './../../assets/destaque/spidey.png'
import bgHallow from './../../assets/destaque/bg-hallow.png'




const Regulamento = () => {
    return(
        <div className="rg-text">
            <h3>Regulamento</h3>
            <ul>
                <li>Aconselhamos só aos verdadeiros corajosos participarem neste passatempo 🎃👻</li>
                <li>Passatempo decorre até às 23h59 de dia 2 de novembro de 2021</li>
                <li>Limita-se a participação a uma só pessoa e a um correspondente endereço de e-mail</li>
                <li>A Babu irá proceder à selecção das participações recebidas com respostas correctas enviadas até às 23h59 de dia 2 de novembro de 2021</li>
                <li>O vencedor será divulgado nas redes sociais</li>
                <li>O vencedor será contactado diretamente pela Babu, via e-mail, em resposta à sua participação</li>
                <li>Os participantes autorizam a Babu a divulgar os seus nomes e o resultado do passatempo nas redes sociais da Babu: Instagram e Facebook (@babueco)</li>
            </ul>

        </div>
    )
}

const Formulário = () => {
    return(
          <p style={{color:"#fff",margin:"3em auto"}}>Lamentamos mas terminou o tempo de participação do evento :( </p>
    )
}



class Featured extends Component {
    constructor(){
        super()
        this.witch = React.createRef();
        this.featured= true;
        this.state={
            formactivation: false,
            switch:"higiene",
        }
        this.handleAddition = this.handleAddition.bind(this)
    }
    componentDidMount(){
        console.log(`Hi there! how come you got here?
        This website was done by Creative Discovery! Interested in our work ? Check us out at https://creativediscovery.pt!`);
        try {
            setTimeout(() => {
                this.witch.scrollIntoView({ block: 'end',  behavior: 'smooth' })
            }, 700);
        } catch (error) {
            console.log("oh well!")
        }

    }

    handleSwitch(prop){
        let nextstate = prop;
        this.setState({switch:nextstate})
    }

    handleAddition(e){
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    
    spookyMove(e){
   /* check events */
        
    }




  render() {

    return (
        <main className="home_babu featured">
            <header className={this.featured ? "home featured" : "hero"}>
                <div className="hero__container">
                   <img src={feature} alt="" />
                </div>
            </header>
            <section className="home secondImage featured" style={{backgroundImage:`url(${bgHallow})`}} ref={(el)=>this.witch=el}>
                <div className="hero__container">
                   <div className="brush_witch-container">
                    <img className="brush_witch" src={witch} alt="" />
                   </div>
                   <div className="speech_balloon-container">
                       <div className="speech_balloon">
                            <p>Não te assustes, sou apenas a escova de cabelo da Babuuuuuu! Estou aqui para te lançar um desafio assustadoramente divertido!</p>
                            <img className="spidey" src={spidey} alt="" />
                            <span className="triangle"></span>
                       </div>
                   </div>
                </div>
            </section>
            <section className="produtos">

                <div className="featured_container" id="Witch">
                    <img className="broom" src={broom} alt="" />
                    <div className={this.state.switch === "higiene" ? "featured_content" : "featured_content regulamento"}>
                        <header className="menu__comprar menu__wrapper">
                            <header className="produtos__header">
                                <div className="header__content">
                                    <div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><h5>Participar</h5></div>
                                    <div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><h5>Regulamento</h5></div>
                                </div>
                            </header>
                        </header>
                        {this.state.switch ==="higiene" ? <Formulário/> : <Regulamento/>}
                    </div>
                    <img className="ghostie" src={ghostie} onMouseOver={this.spookyMove} alt="" />
                </div>
                
            </section>
      </main>
    )
  }
}

export default Featured

