import React, { Component } from 'react'
import ReactPlayer from 'react-player'






import escovilhao from './../../../../assets/produtos/paginapalhinhas/escovilhao.png'
import imagemprincipal from './../../../../assets/produtos/paginapalhinhas/imagemprincipal.png'
import palhinhas from './../../../../assets/produtos/paginapalhinhas/palhinhas.png'

import canas from './../../../../assets/palhinhas.mp4'




export default class Straw extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/pailles-a-boire/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Pailles à boire</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={imagemprincipal} alt="palhinhas" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Avantages</h3>
                                      <p>Nos pailles sont 100% écologiques parce qu’elles sont réutilisables et fabriquées en bambou.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Ensemble</h3>
                                      <p>Vous pouvez acheter les pailles individuellement ou dans un ensemble de 4 pailles, un écouvillon de nettoyage et un sac en tissu pour transporter.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Écouvillon </h3>
                                      <p>L’écouvillon a des filaments en fibre de noix de coco, un matériel totalement biodégradable, qui permet le nettoyage de toutes les impuretés des pailles.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
              <section className="hero">
                <div className="cta__ straws">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={escovilhao} alt="" />
                    </div>
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Fibre de noix de coco</h2>
                            <div className="paragrafos">
                                <p>L’écouvillon en fibre de noix de coco est sans plastique et fabriquée avec des matériels naturels qui ne nuisent pas l'environnement. Il a été conçu pour nettoyer les endroits les plus difficiles des pailles.</p>
                            </div>
                        </div>
                    </div> 
                </div>
              </section>
              <section className="hero">
                <div className="cta__ straws inverted">
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Ensemble de pailles</h2>
                            <div className="paragrafos">
                                <p>L’ensemble de pailles à boire Babu est idéal pour partager une bonne boisson avec la famille et amis.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cta__image">
                        <img className="" src={palhinhas} alt="" />
                    </div> 
                </div>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
              <div className="used">
                <div className="used"><h3>Comment nettoyer vos pailles Babu ?</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>







        </section>
      </main>
      )
    }
  }






