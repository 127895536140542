import React, { Component } from 'react'
import { Route, Link, Switch,  } from "react-router-dom";

/* componentes */


//Higiene

import Escova from './../components/produtos/higiene/escova'
import Canas from './../components/produtos/higiene/canas'
import Fio from './../components/produtos/higiene/fio'
import konjac from './../components/produtos/higiene/konjac'
import Swabs from './../components/produtos/higiene/swabs'
import Cabelo from './../components/produtos/higiene/cabelo'
import Pasta from './../components/produtos/higiene/pasta'



//babu-life
import Straw from './../components/produtos/babulife/straws'
import Colhercafe from './../components/produtos/babulife/colhercafe'
import Colhergarfo from './../components/produtos/babulife/colhergarfo'
import Talheres from './../components/produtos/babulife/talheres'
import Estojo from './../components/produtos/babulife/estojo'



import Cert from './../../components/Cert'


//Images 

import seta from './../../assets/icons/seta.png'

import fotoproduct from './../../assets/produtos/brushshot.png'
import lifeproduct from './../../assets/produtos/kitshot.jpg'
import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"
import benficalogo from "./../../assets/logos/benficalogo.png"


const ListItem = ({ to, name,}) => (
    <Route
      path={to}
      children={({ match }) => (
        <Link to={to}>
            <li className={match ? "active" : ""}>
                {name   }
            </li>
        </Link>





      )}
    />
  );



class Produtos extends Component {
    constructor(props){
        super(props)
        this.state={
            product: "brush",
            switch:"higiene",
            openClose:false,

            higienepath: [
                    "/produtos/escova",
                    "/produtos/cana",
                    "/produtos/fio",
                    "/produtos/cotonetes",                    
                    "/produtos/konjac",
                    "/produtos/escovadecabelo",
                    "/produtos/pastadedentes"
                    

                ],
            spot_higiene:[
                {to:"/produtos/escova", name:"Escovas de dentes"},
                {to:"/produtos/cana", name:"Canas de transporte"},
                {to:"/produtos/fio", name:"Fio dentário"},
                {to:"/produtos/cotonetes", name:"Cotonetes"},
                {to:"/produtos/konjac", name:"Esponja konjac"},
                {to:"/produtos/escovadecabelo", name:"Escova de cabelo"},
                {to:"/produtos/pastadedentes", name:"Pasta de Dentes"},
            ],
            spot_babulife:[
                {to:"/produtos/palhinha", name:"Palhinhas"},
                {to:"/produtos/colhercafe", name:"Colheres de café"},
                {to:"/produtos/colhergarfo", name:"Colher-garfo"},
                {to:"/produtos/talheres", name:"Talheres"},
                {to:"/produtos/estojo", name:"Estojo de refeição"},
            ]
        }
        this.handleSwitchState = this.handleSwitchState.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);   
        this.Opener = this.Opener.bind(this)

    }

    componentDidMount(){
        if (this.state.higienepath.includes(this.props.location.pathname) || this.props.location.pathname === "/produtos" ){
            this.setState({
                switch:"higiene"
            })
        }
        else(
            this.setState({
                switch:"babulife"
            })
        )
    }

    handleSwitchState(prop){
        let nextstate = this.state.product;
        nextstate = prop;
        this.setState({product:nextstate})
    }

    handleSwitch(prop){
        let nextstate = prop;
        this.setState({
            switch:nextstate,
        })
    }
    Opener(){
        let openClose = !this.state.openClose
        this.setState({openClose:openClose}) 
    }

    handleSwitchOpener(){
        
    }


  render() {
    return (
        <section className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Os nossos produtos</h2>
                                <p>Os produtos Babu são constituídos por materiais naturais e renováveis, que constituem uma excelente alternativa ecológica ao plástico</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__wrapper">
                {/*<header className="produtos__header">
                    <div className="header__content">
                        <Link to="/produtos/escova"><h3 className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")} >Babu</h3></Link>
                        <Link to="/produtos/palhinha"><h3 className={this.state.switch === "higiene" ?  "" : "active "} onClick={this.handleSwitch}  >Babu life</h3></Link>
                    </div>
                </header> */}
                <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                         <Link to="/produtos/escova"><div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div></Link>
                         <Link to="/produtos/palhinha"><div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div></Link>
                         <div className="benfica"><a href="https://benfica.babueco.com/" target="_blank"><img src={benficalogo} alt="" /></a></div>
                         
                    </div>
                </header>
            </header>
                <div className="menu-icon" ><img className={this.state.openClose ? "menuopener" : "menuopener open"}   onClick={this.Opener.bind(this)} src={seta} alt=""/></div>
                {this.state.switch ==="higiene" ? 
                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                            {this.state.spot_higiene.map((item,index)=>{
                                return(
                                    <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                                )
                            })}
                    </ul>

                    :

                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                        {this.state.spot_babulife.map((item,index)=>{
                            return(
                                <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                            )
                        })}
                    </ul>
                
            
            }

            </header>

            <section>
                <Switch>
                    <Route exact path="/produtos" component={Escova} />
                    <Route path="/produtos/escova" component={Escova}/>
                    <Route path="/produtos/cana" component={Canas}/>
                    <Route path="/produtos/konjac" component={konjac}/>
                    <Route path="/produtos/fio" component={Fio}/>
                    <Route path="/produtos/cotonetes" component={Swabs}/>
                    <Route path="/produtos/escovadecabelo" component={Cabelo}/>
                    <Route path="/produtos/pastadedentes" component={Pasta}/>


                    <Route path="/produtos/palhinha" component={Straw}/>
                    <Route path="/produtos/colhercafe" component={Colhercafe}/>
                    <Route path="/produtos/colhergarfo" component={Colhergarfo}/>
                    <Route path="/produtos/talheres" component={Talheres}/>
                    <Route path="/produtos/estojo" component={Estojo}/>
                    
                </Switch>
            </section>
            <Cert name="Certificações"/>
        </section>
    )
  }
}

export default Produtos
