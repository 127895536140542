import React, { Component } from 'react'
import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/quemsomos.jpg'


import escolher from './../../assets/why_fr.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>À propos de nous</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>Nous sommes passionnés d’apporter des nouvelles idées qui contribuent pour un monde plus durable
                </h4>
                <p>
                    La marque Babu est le fruit de la conviction que nous pouvons et devons créer de nouvelles solutions pour nos besoins actuels.
                    Les produits que nous utilisons tous les jours, même ceux qui semblent insignifiants, ont un impact énorme lorsqu'ils sont utilisés à grande échelle, comme les articles en plastique jetables.
                    <br/>
                    Il n'y a rien de mieux que de contribuer au changement que nous souhaitons tous, en commençant par l'introduction des produits que nous utilisons habituellement, mais fabriqués avec des matériaux qui ne compromettent pas la biodiversité et la préservation de l’écosystème.
                    <br/> 
                    C'est dans cette perspective que commence Babu, une marque qui a débuté avec des brosses à dents en bambou, un produit qui serait d'abord encadré dans un marché niche, mais que nous aspirons introduire chaque fois plus dans les salles de bains, s'affirmant comme une véritable alternative à la vieille solution obsolète des brosses en plastique.
                    <br/>
                    Nous croyons que l'utilisation des produits Babu rend notamment inutile le choix de produits fabriqués à partir de matériels non recyclables, sans aucune innovation depuis plus de 50 ans et qui ont une grande incidence sur les niveaux de pollution de notre planète. 
                    <br/>   
                    Les Babu ne sont pas seulement les plus belles, les meilleurs et les seules qui respectent l'environnement, elles sont aussi l'avenir.  
                    <br/><br/>
                    <strong>Et si nous pouvions créer aujourd'hui l'avenir de demain ?</strong>
                    <br/>           
                    En préférant les produits Babu, vous ne faites pas seulement un choix plus respectueux vis à vis l’environnement, vous nous aidez aussi à contribuer à des causes sociales pertinentes pour nous tous, comme la préservation de l'environnement, la conservation des espèces, et le soutien du processus de prise de habitudes et reformulation des coutumes auprès des nouvelles générations.
                    <br/>
                    L’approche de cet avenir dépend essentiellement de vous-même, c’est pour cette raison que nous avons assumé cet engagement : chaque fois que vous choisissez d’utiliser un produit Babu, vous ne répondez pas seulement à un besoin, vous participez aussi à un changement fort et silencieux dont l’ampleur de son impact dépend de nous tous, chaque jour, par un simple choix !
                    
                </p>
            </div>

          </div>

      </section>
      <section className="aboutus--video">
          <div className="videos__title">
              <h2>Pourquoi le choix d'une Babu est-il si important ?</h2>
          </div>
          <div className="video__info">
                <ReactPlayer
                    playing
                    playsinline	
                    url={[escolher]}
                    loop
                    muted
                />
          </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog