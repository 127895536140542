import React, { Component } from 'react'



import escova from './../../../../assets/produtos/escova.png'


export default class Cabelo extends Component {
    constructor(){
        super()
        this.state={
            link:"https://shop.babueco.com/produto/escova-de-cabelo-bambu/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Escova de cabelo</h5></div>
                       
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={escova} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composição</h3>
                                      <p>Escova biodegradável de cabelo em que o cabo e cerdas são 100% bambu, com uma ergonomia perfeita para uma boa utilização e conforto ao escovar o cabelo. </p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Borracha natural</h3>
                                      <p>Com borracha natural, dispõe de cerdas arredondadas de modo a criar um efeito de massagem no couro cabeludo.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilização</h3>
                                      <p>Para uma boa utilização aconselhamos que sejam removidos os cabelos diariamente, que a sua lavagem seja manual e em caso de contacto com água que seja colocada posteriormente num local seco.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>

              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






