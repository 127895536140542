import React, { Component } from 'react'
import ReactPlayer from 'react-player'

// Videos

import cerdas from './../../../../assets/cerdas.mp4'



/*images */ 

import panda from './../../../../assets/produtos/info/panda.png'

import kids from './../../../../assets/produtos/info/kids.png'


import carbonBrush from './../../../../assets/produtos/info/carvao_img.png'

import adultBrush1 from './../../../../assets/produtos/info/escova_cor1.png'
import adultBrush2 from './../../../../assets/produtos/info/escova_cor2.png'
import adultBrush3 from './../../../../assets/produtos/info/escova_cor3.png'
import adultBrush4 from './../../../../assets/produtos/info/escova_cor4.png'
import kidsBrush1 from './../../../../assets/produtos/info/nuvemkids1.png'
import kidsBrush2 from './../../../../assets/produtos/info/nuvemkids2.png'

import EscovaHoriz from './../../../../assets/produtos/info/escova_horizontal.png'

import Regua from './../../../../assets/produtos/info/reguakids.png'





class Escovas extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/cepillos-de-dientes/",
            progress: "100%",
            current_img: "adultone",
            kids: false,
            carbon:false,
            adult:false,
        }
        this.handleProgressBar = this.handleProgressBar.bind(this);
        this.changeImg = this.changeImg.bind(this);
    }

    handleProgressBar(progress){
        let newprogress = progress; 
        this.setState({
            progress: newprogress
        })
    }

    changeImg(text){
        let newimg = text;
        this.setState({
            current_img: newimg
        })
    }

    adultColours(){
        let adult = !this.state.adult;
        this.setState({adult})
    }

    kidsColours(){
        let kids = !this.state.kids;
        this.setState({kids})
    }

    carbonColours(){
        let carbon = !this.state.carbon;
        this.setState({carbon})
    }

  render() {
    return (
        <main>
            <section className="infoproducts">

                <section className="tipos">
                    
                    <div className="brush">
                        <div className="brush__title"><h5>Cepillos de dientes</h5></div>
                        <div className="brush__controls"> 
                            <div className="measures">
                                <div className="blockb blockbr block1"></div>
                                <div className="blockb blockbl block2"></div>
                                <div className="blockm block--3">3 cm</div>
                                <div className="blockm block--16">16 cm</div>
                            </div>
                            <div className="escova__img">
                                <img src={EscovaHoriz} alt="" />
                            </div>
                        </div>
                    </div>
                    <article className="info__escovas">
                        <section className="info__escovasB">
                            <div className="info__sec info__sec--one">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Las cerdas</h3>
                                        <p>Las cerdas están diseñadas en un formato creciente que permite llegar a los puntos más difíciles para lograr un cepillado más efectivo.</p>
                                    </div>
                                </div>               
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Mango ergonómico</h3>
                                        <p>Los Babu están diseñados para adaptarse perfectamente a tu mano y boca, de tal manera que su manejo sea correcto en el proceso del cepillado de tus dientes, lo que te permite elegir entre dos tamaños: adulto y niño.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Beneficios</h3>
                                        <p>Es la planta con la mayor concentración de sílice en el planeta, lo que refuerza el trabajo del calcio para la construcción de huesos y dientes fuertes. Los nutrientes del bambú también son beneficiosos para la encía al fortalecerla.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                        <div className="dureza">
                            <div className="adulto">
                                <div className="tipo__dureza"><h4>Tipos de cerdas</h4></div>
                                <div className="adulto__dureza">
                                    <ul>
                                        <li onClick={() => this.handleProgressBar("5%")}>Extra suave</li>
                                        <li onClick={() => this.handleProgressBar("40%")}>Suave</li>
                                        <li onClick={() => this.handleProgressBar("70%")}>Media</li>
                                        <li onClick={() => this.handleProgressBar("100%")}>Firme</li>
                                    </ul>
                                    <div style={{width:this.state.progress}} className="progressbar">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cor">
                            <div className="cor_adulto">
                                <div className="cor__title"><h5>Los colores de Babu</h5></div>
                                <div className="cor__choices">
                                    <div className="cor__item"><img src={adultBrush1} alt="" /><h6>Verde y blanco</h6></div>
                                    <div className="cor__item"><img src={adultBrush2} alt="" /><h6>Amarillo y verde</h6></div>
                                    <div className="cor__item"><img src={adultBrush3} alt="" /><h6>Azul y verde</h6></div>
                                    <div className="cor__item"><img src={adultBrush4} alt="" /><h6>Azul y blanco</h6></div>
                                </div>  
                            </div>
                        </div>
                    </section>
                    <section className="hero">
                        <div className="cta__ carbon">
                            <div className="cta__image">
                                <img className="diagonal--inverted" src={carbonBrush} alt="" />
                            </div>
                            <div className="cta__containertext" >
                                <div className="container__text">
                                    <h2>Cepillo de carbón activado</h2>
                                    <div className="paragrafos">
                                        <p>Nuestro cepillo Babu de Carbón Activado es ideal para la eliminación de malos olores, bacterias y  manchas causadas por la alimentación y contribuye también a la protección de la placa bacteriana.</p>
                                        <br/>
                                        <p>Al igual que los demás cepillos de Babu, las cerdas son de nailon sin BPA con la diferencia del componente de carbón activado de bambú.</p>
                                    </div>
                                    <div className="carbon">
                                        <h6>Características</h6>
                                        <div className="carbon_brush">
                                            <div className="carbon_brush--info">
                                                <div className="bola dureza--medium"></div>
                                                <h6>Dureza media</h6>
                                            </div>
                                            <div className="carbon_brush--info">
                                                <div className="bola only--color"></div>
                                                <h6>Color único</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </section>
                    <section className="kids_section">
                        <div className="panda_section">
                            <div className="panda_img"><img src={panda} alt=""/></div>
                        </div>
                        <article className="kids_container">
                            <div className="kids_title">
                                <h3>¿Ya conoces los Babu Kids?</h3>
                                <img src={kids} alt=""/>
                            </div>
                            <div className="kids_info">
                                <div className="kids_info--img">
                                    <img src={Regua} alt=""/>
                                </div>
                                <div className="kids_info--text">
                                    <div className="description">
                                        <div className="description-content">
                                            <p>
                                            Los niños ahora ya pueden ayudar a preservar el planeta con los Babu Kids. Nuestros cepillos están diseñados para un ajuste perfecto a la muñeca y a los dientes de un niño.
                                            </p>
                                            <br/>
                                            <span>Disponible en cerda extra suave</span>
                                        </div>
                                    </div>
                                    <div className="colours">
                                        <h4>NUESTROS COLORES</h4>
                                        <div className="colours-container">
                                            <div className="colour-box"> <img src={kidsBrush1} alt=""/></div>
                                            <div className="colour-box"> <img src={kidsBrush2} alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            
                            
                            </div>
                        
                        
                        
                        
                        </article>
                    
                    </section>
                    <div className="buynow-btn">
                        <a href={this.state.link}><span>Comprar ahora</span></a>
                    </div>
                    <div className="used">
                        <div className="used"><h3>¿Qué hacer con los cepillos Babu cuando se les acaba su vida útil?</h3></div>
                        <div className="video__info">
                            <ReactPlayer
                                playing
                                playsinline	
                                url={[cerdas]}
                                loop
                                muted
                            />
                        </div>
                    </div>
            </section>
        </main>
    )
  }
}

export default Escovas
