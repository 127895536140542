import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 


import esponjas from './../../../../assets/produtos/info/esponjas.png'


// utilização

import Esponja from './../../../../assets/produtos/konjac/esponja.mp4'
import Esfregar from './../../../../assets/produtos/konjac/esfrega.mp4'
import Minacao from './../../../../assets/produtos/konjac/minacao.mp4'


export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/eponge-konjac",
        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }

  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Éponges konjac</h5></div>
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={esponjas} alt="konjac img" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Pure</h3>
                                    <p>Si vous avez une peau mixte ou sèche et vous voulez un gommage doux et profond, l'éponge konjac Pure est idéale pour vous.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Charbon actif</h3>
                                    <p>Si vous avez une peau grasse à tendance acnéique, nous vous conseillons l'éponge konjac de charbon actif qui, après le gommage, rééquilibrera les zones les plus grasses du visage.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Caractéristiques</h3>
                                    <p>Nos éponges de gommage pour le visage sont fabriquées à partir de la racine du konjac, 100% naturelles et sans ingrédients nocifs.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
            <section className="tipos videos">
                <div className="brush">
                    <div className="brush__title">
                        <h5>Utilisation</h5>
                    </div>
                    <p>Découvrez comment utiliser l'éponge konjac en passant la souris sur les images</p>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step1" ? true : false}
                                playsinline	
                                url={[Esponja]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Mouillé avec de l'eau</h3>
                                    <p>Mouiller votre éponge konjac jusqu'à ce qu'elle soit complètement humide.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step2" ? true : false}
                                playsinline	
                                url={[Esfregar]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                                
                            </div>  
                            <div className="info__div">
                                <div className="text">
                                    <h3>Massage</h3>
                                    <p>Massez doucement votre visage avec des mouvements circulaires, pour un nettoyage plus complet.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                           <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>    
                                <ReactPlayer
                                playing={this.state.step === "step3" ? true : false}
                                playsinline	
                                url={[Minacao]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Après l’utilisation</h3>
                                    <p>Après avoir utilisé l'éponge konjac, conservez-la dans un endroit sec. Malgré ses propriétés antibactériennes, il est conseillé de la changer après 1 mois s'elle est utilisée quotidiennement</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
      </section>
    </main>
    )
  }
}
