import React, { Component } from 'react'
import { Route, Link, Switch  } from "react-router-dom";

/* componentes */


//Higiene

import Escova from './../components/produtos/higiene/escova'
import Canas from './../components/produtos/higiene/canas'
import Fio from './../components/produtos/higiene/fio'
import konjac from './../components/produtos/higiene/konjac'
import Swabs from './../components/produtos/higiene/swabs'
import Cabelo from './../components/produtos/higiene/cabelo'
import Pasta from './../components/produtos/higiene/pasta'



//babu-life
import Straw from './../components/produtos/babulife/straws'
import Colhercafe from './../components/produtos/babulife/colhercafe'
import Colhergarfo from './../components/produtos/babulife/colhergarfo'
import Talheres from './../components/produtos/babulife/talheres'
import Estojo from './../components/produtos/babulife/estojo'


import Cert from './../../components/Cert'


//Images 

import seta from './../../assets/icons/seta.png'

import fotoproduct from './../../assets/produtos/brushshot.png'
import lifeproduct from './../../assets/produtos/kitshot.jpg'

import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"

const ListItem = ({ to, name,}) => (
    <Route
      path={to}
      children={({ match }) => (
        <Link to={to}>
            <li className={match ? "active" : ""}>
                {name   }
            </li>
        </Link>





      )}
    />
  );



class Produtos extends Component {
    constructor(props){
        super(props)
        this.state={
            product: "brush",
            switch:"higiene",
            openClose:false,

            higienepath: [
                    "/produits/brosses",
                    "/produits/etuisvoyage",
                    "/produits/fil",
                    "/produits/cotontige",                    
                    "/produits/konjac",
                    "/produits/brosseacheveux",
                    "/produits/dentifrice",
                    

                ],
            spot_higiene:[
                {to:"/produits/brosses", name:"Brosses à dents"},
                {to:"/produits/etuisvoyage", name:"Étuis de voyage"},
                {to:"/produits/fil", name:"Fil dentaire"},
                {to:"/produits/cotontige", name:"Cotons-tiges"},
                {to:"/produits/konjac", name:"Éponges konjac"},
                {to:"/produits/brosseacheveux", name:"Brosse à cheveux"},
                {to:"/produits/dentifrice", name:"Dentifrice"},
            ],
            spot_babulife:[
                {to:"/produits/pailles", name:"Pailles à boire"},
                {to:"/produits/cuillere", name:"Cuillères à café"},
                {to:"/produits/cuillerefourchette", name:"Cuillère Fourchette"},
                {to:"/produits/couverts", name:"Couverts"},
                {to:"/produits/etuis", name:"Étuis à couverts"},
            ]
        }
        this.handleSwitchState = this.handleSwitchState.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);   
        this.Opener = this.Opener.bind(this)

    }

    componentDidMount(){
        if (this.state.higienepath.includes(this.props.location.pathname) || this.props.location.pathname === "/produits" ){
            this.setState({
                switch:"higiene"
            })
        }
        else(
            this.setState({
                switch:"babulife"
            })
        )
    }

    handleSwitchState(prop){
        let nextstate = this.state.product;
        nextstate = prop;
        this.setState({product:nextstate})
    }

    handleSwitch(prop){
        let nextstate = prop;
        this.setState({
            switch:nextstate,
        })
    }
    Opener(){
        let openClose = !this.state.openClose
        this.setState({openClose:openClose}) 
    }

    handleSwitchOpener(){
        
    }


  render() {
    return (
        <section className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Nos produits</h2>
                                <p>Les produits Babu sont fabriqués à partir de matériaux naturels et renouvelables, qui constituent une excellente alternative écologique au plastique.</p>
                            </div>
                        </div>
                </div>
            </section>
            
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <Link to="/produits/brosses"><div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div></Link>
                        <Link to="/produits/pailles"><div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div></Link>
                    </div>
                </header>
            </header>
                <div className="menu-icon" ><img className={this.state.openClose ? "menuopener" : "menuopener open"}   onClick={this.Opener.bind(this)} src={seta} alt=""/></div>
                {this.state.switch ==="higiene" ? 
                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                            {this.state.spot_higiene.map((item,index)=>{
                                return(
                                    <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                                )
                            })}
                    </ul>

                    :

                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                        {this.state.spot_babulife.map((item,index)=>{
                            return(
                                <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                            )
                        })}
                    </ul>
                
            
            }

            <section>
                <Switch>
                    <Route exact path="/produits" component={Escova} />
                    <Route path="/produits/brosses" component={Escova}/>
                    <Route path="/produits/etuisvoyage" component={Canas}/>
                    <Route path="/produits/konjac" component={konjac}/>
                    <Route path="/produits/fil" component={Fio}/>
                    <Route path="/produits/cotontige" component={Swabs}/>
                    <Route path="/produits/brosseacheveux" component={Cabelo}/>
                    <Route path="/produits/dentifrice" component={Pasta}/>


                    <Route path="/produits/pailles" component={Straw}/>
                    <Route path="/produits/cuillere" component={Colhercafe}/>
                    <Route path="/produits/cuillerefourchette" component={Colhergarfo}/>
                    <Route path="/produits/couverts" component={Talheres}/>
                    <Route path="/produits/etuis" component={Estojo}/>
                    
                </Switch>
            </section>
            <Cert name="Certifications"/>
        </section>
    )
  }
}

export default Produtos
