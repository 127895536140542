import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 


import esponjas from './../../../../assets/produtos/info/esponjas.png'


// utilização

import Esponja from './../../../../assets/produtos/konjac/esponja.mp4'
import Esfregar from './../../../../assets/produtos/konjac/esfrega.mp4'
import Minacao from './../../../../assets/produtos/konjac/minacao.mp4'


export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/esponja-de-konjac-es/",
        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }

  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Esponja de konjac</h5></div>
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={esponjas} alt="konjac img" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Pure</h3>
                                    <p>Si tienes la piel mixta o seca y buscas una exfoliación profunda y suave, la esponja Pure konjac es perfecta para ti.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Carbón activado</h3>
                                    <p>Si tienes la piel grasa y propensa al acné, te sugerimos la esponja konjac con carbón activado, que después de la exfoliación equilibrará las zonas más grasas de la piel del rostro.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Características</h3>
                                    <p>Nuestras esponjas exfoliantes faciales están hechas de raíz de konjac, 100% naturales y sin ingredientes nocivos.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
            <section className="tipos videos">
                <div className="brush">
                    <div className="brush__title"><h5>Uso</h5></div>
                    <p>Descubre cómo usar la esponja konjac pasando el ratón por encima de las imágenes</p>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step1" ? true : false}
                                playsinline	
                                url={[Esponja]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Mojar en agua</h3>
                                    <p>Moja tu esponja konjac hasta que esté completamente húmeda</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step2" ? true : false}
                                playsinline	
                                url={[Esfregar]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                                
                            </div>  
                            <div className="info__div">
                                <div className="text">
                                    <h3>Masajear</h3>
                                    <p>Masajea suavemente tu rostro con movimientos circulares, de modo a lograr una limpieza más completa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                           <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>    
                                <ReactPlayer
                                playing={this.state.step === "step3" ? true : false}
                                playsinline	
                                url={[Minacao]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Después del uso</h3>
                                    <p>Después de usar la esponja konjac debes guardarla en un lugar seco. Aunque posea propiedades antibacterianas es aconsejable su cambio al final de 1 mes de utilización.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
      </section>
    </main>
    )
  }
}
