import React, { Component } from 'react'



import escova from './../../../../assets/produtos/escova.png'


export default class Cabelo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/brosse-a-cheveux/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Brosse à cheveux</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={escova} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composition</h3>
                                      <p>Brosse à cheveux biodégradable dont le manche et les poils sont 100% bambou, avec une ergonomie parfaite pour une bonne utilisation et un brossage confortable des cheveux. </p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Caoutchouc naturel</h3>
                                      <p>En caoutchouc naturel avec des poils arrondis, afin de créer un effet de massage sur le cuir chevelu.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilisation</h3>
                                      <p>Pour une bonne utilisation, nous recommandons d’enlever les cheveux tous les jours et de la laver manuellement. Si la brosse est mouillé, la placer dans un endroit sec et ventilé.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
        </section>
      </main>
      )
    }
  }






