import React, { Component } from 'react'
import {Link} from "react-router-dom";

import smf from './../../assets/icons/smf.png'
import smi from './../../assets/icons/smi.png'
import smy from './../../assets/icons/smy.png'


class Footer extends Component {
  render() {
    return (
    <footer className="footer">
          <div className="footer__container">
          <div className="terms">
              <div className="both">
                    <Link to="/preguntasfrecuentes">Preguntas Frecuentes</Link>
                    <Link to="/contactos">Contactos</Link>
                </div>
              </div>
              <ul className="socialmedia">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BabuEcoES/"><img src={smf} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/babueco.es/"><img src={smi} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCafY2gShZXff-3whffG0Ihg"><img src={smy} alt=""/></a></li>
              </ul>
            <div className="terms">
              <div className="both">
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/es/politica-de-privacidad/">Politica de Privacidad</a>
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/es/terminos-y-condiciones/">Términos y condiciones</a>
              </div>
            </div>
         </div>
         <div className="copyright">
          <p>
            © {(new Date().getFullYear())} Babu
          </p>
        </div>

    </footer>
    )
  }
}

export default Footer
