import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 

import floss from './../../../../assets/produtos/dentalfloss.png'


import panda from './../../../../assets/produtos/info/pandacloud.png'


// utilização

import fio1 from './../../../../assets/produtos/fio/fio1.mp4'
import fio2 from './../../../../assets/produtos/fio/fio2.mp4'
import fio3 from './../../../../assets/produtos/fio/fio3.mp4'



export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/fil-dentaire/",
        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }


  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Fil dentaire</h5></div>
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={floss} alt="FIO" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Taille</h3>
                                    <p>Le fil dentaire Babu mesurant 50 mètres de longueur, permet une moyenne de 250 utilisations.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Composition</h3>
                                    <p>Ce fil de nylon contient du charbon actif dans sa composition pour aider à l’élimination des impuretés, permettant une dentition plus fraîche, propre et saine.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Caractéristiques</h3>
                                    <p>L'emballage qui contient le fil dentaire a été conçu pour réduire au maximum le déchet. L'emballage est recyclable en fin de vie du produit.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
            <section className="utilizar">
                <div className="utilizar_container">
                    <div className="panda">
                        <img src={panda} alt="" />
                    </div>
                    <div className="utilizar_title">
                        <h3>Comment utiliser le fil dentaire ?</h3>
                        <p>Découvrez comment utiliser le fil dentaire en passant la souris sur les images</p>
                    </div>
                    <div className="illustration_container">
                        <div className="illustration_content">
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step1" ? true : false}
                                        playsinline	
                                        url={[fio1]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Coupez environ 40 cm de fil dentaire et enroulez les extrémités autour des doigts du milieu.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step2" ? true : false}
                                        playsinline	
                                        url={[fio2]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Tenez le fil en pinçant entre l’index et le pouce.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step3" ? true : false}
                                        playsinline	
                                        url={[fio3]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Glissez le fil tendu entre chaque dent faisant un mouvement en C. Répétez le processus deux fois pour chaque dent.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </section>
    </main>
    )
  }
}
