import React, { Component } from 'react'
import certs from './../assets/produtos/certp.png'

export default class Cert extends Component {
    render() {
        return (
            <div className="dureza certs">
                <div className="cor__title">
                    <h5>{this.props.name}</h5>
                </div>
                <img src={certs} alt=""/>
            </div>
        )
    }
}
