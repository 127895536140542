import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 


import esponjas from './../../../../assets/produtos/info/esponjas.png'




// utilização

import Esponja from './../../../../assets/produtos/konjac/esponja.mp4'
import Esfregar from './../../../../assets/produtos/konjac/esfrega.mp4'
import Minacao from './../../../../assets/produtos/konjac/minacao.mp4'

//componentes 

export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/esponja-de-konjac/",

        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }

  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Esponja de konjac</h5></div>
                     
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={esponjas} alt="konjac img" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Pure</h3>
                                    <p>Se tem uma pele mista ou seca e procura uma esfoliação profunda e suave, a esponja konjac Pure é a ideal para si.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Carvão activado</h3>
                                    <p>Caso tenha uma pele oleosa, com tendência a ter acne, sugerimos a esponja konjac carvão ativado que após a esfoliação irá reequilibrar as zonas mais oleosas do rosto.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Características</h3>
                                    <p>As nossas esponjas de esfoliação para o rosto são feitas a partir da raiz de konjac, 100% naturais e sem ingredientes prejudiciais.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>

            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
            <section className="tipos videos">
                <div className="brush">
                    <div className="brush__title">
                        <h5>Utilização</h5>
                    </div>
                    <p>Descubra como utilizar a esponja konjac passando o rato pelas imagens</p>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step1" ? true : false}
                                playsinline	
                                url={[Esponja]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Molhe em água</h3>
                                    <p>Molhe a sua esponja konjac até ficar completamente húmida.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>
                                <ReactPlayer
                                playing={this.state.step === "step2" ? true : false}
                                playsinline	
                                url={[Esfregar]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                                
                            </div>  
                            <div className="info__div">
                                <div className="text">
                                    <h3>Massage</h3>
                                    <p>Massageie suavemente o seu rosto, em movimentos circulares, de forma a obter uma limpeza mais completa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                           <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")} onMouseLeave={this.mouseOver.bind(this,"nonstep")}>    
                                <ReactPlayer
                                playing={this.state.step === "step3" ? true : false}
                                playsinline	
                                url={[Minacao]}
                                loop
                                muted
                                width="100%"
                                height="100%"
                                />
                            </div>
                            <div className="info__div">
                                <div className="text">
                                    <h3>Após utilizar</h3>
                                    <p>Após utilizar a esponja konjac deve guardá-la num local seco. Apesar de dispor de propriedades antibacterianas é aconselhável a sua troca ao fim de um mês de utilização.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>

      </section>
    </main>
    )
  }
}
