import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 



import panda from './../../../../assets/produtos/info/panda.png'
import kids from './../../../../assets/produtos/info/kids.png'

import canahorizontal from './../../../../assets/produtos/info/cana/canahorizontal.png'
import regua from './../../../../assets/produtos/info/cana/reguakids.png'




import canas from './../../../../assets/cana_pt.mp4'


export default class CanasInfo extends Component {
    constructor(){
        super()
        this.state={
            link:"https://shop.babueco.com/produto/cana-transportadora/",
        }
    }
  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
            
            <div className="brush">
                <div className="brush__title"><h5>Cana de transporte</h5></div>
                 
                <div className="brush__controls"> 
                    <div className="measures brush">
                        <div className="blockb blockbr block1"></div>
                        <div className="blockb  block2"></div>
                        <div className="blockm block--3"></div>
                        <div className="blockm block--16">21 cm</div>
                    </div>
                    <div className="escova__img">
                        <img src={canahorizontal} alt="" />
                    </div>
                </div>
            </div>
            <article className="info__escovas">
                <section className="info__escovasB">
                    <div className="info__sec info__sec--one">
                        <div className="info__div">
                            <div className="text">
                                <h3>Tamanho</h3>
                                <p>A Cana de transporte, tamanho adulto, tem 21cm de altura e 2,5cm de diâmetro o tamanho ideal para caber em qualquer mala ou mochila.</p>
                            </div>
                        </div>               
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Benefícios</h3>
                                <p>A Cana de transporte é 100% feita de bambu. Além de nos auxilar no transporte da escova de dentes, protege-a dos maus odores, bactérias e pó.</p>
                            </div>
                        </div>
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Utilização</h3>
                                <p>A Cana de transporte é bastante versatil na sua utilização. Além de ser usada para transportar as escovas de dentes pode ser usada para transportar as palhinhas ou até mesmo servir de estojo. fica à sua espera.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            </section>
            <section className="kids_section">
                <div className="panda_section">
                    <div className="panda_img"><img src={panda} alt=""/></div>
                </div>
                <article className="kids_container">
                    <div className="kids_title">
                        <h3>Já conhece as Babu</h3>
                        <img src={kids} alt=""/>
                    </div>
                    <div className="kids_info">
                        <div className="kids_info--img">
                            <img src={regua} alt=""/>
                        </div>
                        <div className="kids_info--text">
                            <div className="description">
                                <div className="description-content">
                                    <p>
                                        Com 17cm de altura e 2,5cm de diâmetro, tem o tamanho ideal para caber em qualquer mala ou mochila.
                                        <br/>
                                        <br/>
                                        Feita 100% em bambu, esta caixa portátil, além de ajudar a prevenir contra os odores, bactérias ou pó, vai permitir o transporte e proteção da escova de dentes Babu.
                                    </p>

                                </div>
                            </div>
                            <div className="colours">
                                <div className="colours-container">
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
              </article>
              </section>
                <div className="buynow-btn">
                 <a href={this.state.link}><span>Compre já</span></a>
                </div>
              <div className="used">
                <div className="used"><h3>Preserve a sua cana de transporte</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>
             
      </section>
    </main>
    )
  }
}
