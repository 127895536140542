import React, { Component } from 'react'



import cotonetes from './../../../../assets/produtos/pastadedentes.png'

import nacional from './../../../../assets/produtos/producaonacional.png'

export default class Pasta extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/pasta-de-dentes/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Pasta de dentes</h5></div>
                       
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={cotonetes} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composição</h3>
                                      <p>
                                        A primeira pasta de dentes Babu com ingredientes naturais e sem SLS (Sodium lauryl sulfate) nem Triclosan.
                                        Não adequado a crianças. Contém flúor.
                                      </p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Sabor</h3>
                                      <p>Tem aroma de limão para que depois da escovagem de dentes sinta uma frescura de limpeza aliada ao conforto de saber que é perfeitamente saudável sem com isso introduzir químicos nocivos no organismo.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>100% Reciclavel</h3>
                                      <p>A Babu comprometeu-se fortemente em apresentar uma pasta de dentes com a menor pegada ecológica possível. Assim sendo optámos por uma bisnaga 100% reciclável e com a menor emissão de CO2 em todo o seu ciclo de vida, desde o processo produtivo até ao seu reaproveitamento em reciclagem.
                                        
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>

              </section>
                <div className="buynow-btn">
                    <a href={this.state.link}><span>Compre já</span></a>
                </div>
              <div className="used">
                    <div className="video_info image" style={{marginBottom:"4em"}}>
                        <img src={nacional} alt=""/>
                    </div>
              </div>

        </section>
      </main>
      )
    }
  }






