import React, { Component } from 'react'
//import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/whybabu.png'


//import escolher from './../../assets/why_pt.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>Pourquoi Babu?</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>POURQUOI BABU EN BAMBOU</h4>
                <p>
                Les caractéristiques du bambou en tant que plante, ses propriétés remarquables en tant que matériau et ses applications actuelles dans le monde entier créent un large éventail d'utilisations possibles avec le potentiel d'aborder le développement qui offre une plus grande équité sociale, une amélioration de l'environnement et une meilleure qualité de vie.
                    <br/>
                    Ainsi, la croissance de la population mondiale a provoqué de fortes pressions sur l'environnement, conduisant à une diminution de la quantité et qualité des forêts, contribuant ainsi à l'intérêt de nouveaux matériels végétaux, comme c’est le cas pour l'utilisation du bambou. De cette façon, le bambou a été redécouvert comme une ressource renouvelable et non polluante.
                    <br/>
                    Actuellement, dans un monde où le développement durable n'est plus une option mais une nécessité, le bambou peut être utilisé en abondance et remplacer de nombreux matériaux dans la fabrication de divers produits dû à un temps plus réduit pour sa croissance et à sa capacité d´être un régénérateur de l'environnement.

                </p>
            </div>
          </div>
          <div className="aboutus__sabias">
              <div className="sabias__boxtitle">
                  <h3 className="sabias__titulo">SAVIEZ-VOUS QUE LE BAMBOU…</h3>
              </div>
              <div className="sabias porque">
                  <div className="sabias__column">
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>EST DE CROISSANCE RAPIDE</h4>
                          </div>
                          <p>Le bambou est la plante qui a la croissance journalière la plus rapide du monde. Actuellement, le record a été atteint au Japon, où cette plante a poussé 121 centimètres en seulement 24 heures !</p>
                      </div>
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>A DES PROPRIÉTÉS MÉDICINALES</h4>
                          </div>
                          <p>Le charbon de bambou a plusieurs propriétés médicinales et son utilisation est très variée. Ses feuilles sont aussi utilisées en tisanes donnant multiples bénéfices.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>EST EXCELLENT POUR LES DENTS</h4>
                        </div>
                        <p>C'est la plante avec la plus forte concentration de silice au monde, renforçant la tâche du calcium pour fortifier des os et des dents forts.<br/> Les nutriments du bambou sont également bienfaisants pour le renforcement des gencives.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>EST UN SUBSTITUT AU PAPIER</h4>
                        </div>
                        <p>C'est une plante résistante qui fabrique ses propres agents antibactériens et qui pousse très bien sans pesticides. Ses fibres poreuses permettent de produire des tissus qui respirent et sont aussi doux que la soie.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>A DES OBJECTIFS MULTIPLES</h4>
                        </div>
                        <p>Il y a actuellement plus de 4000 utilisations possibles du bambou et chaque jour de nouvelles formes sont découvertes et appliquées. Des études récentes indiquent que l'alcool éthylique peut être retiré du bambou et peut également être utilisé comme carburant.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>CAPACITÉS DE RÉGÉNÉRATION</h4>
                        </div>
                        <p>Il a la capacité de se régénérer après la coupe, peut vivre jusqu'à cent ans et, si correctement traité, il peut devenir un matériau durable pendant plusieurs générations.<br/> Matière première renouvelable à faible coût et aux usages variés, le bambou est de plus en plus utilisé pour la préservation de l'environnement.</p>
                    </div>
                  </div>

              </div>
          </div>

          <div className="aboutus__sabias">
            <div className="sabias__boxtitle">
                <h3 className="sabias__titulo">POURQUOI LE CHOIX D'UNE BABU EST SI IMPORTANT</h3>
            </div>
            <div className="sabias porque">
                <div className="sabias__column">
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>ALTERNATIVE AUX TRADITIONNELLES</h4>
                        </div>
                        <p>C'est la seule véritable alternative aux brosses en plastique contribuant à la solution d'un problème qui nous concerne tous, puisque les brosses traditionnelles sont responsables pour des tonnes de déchets chaque année, provoquant un impact gigantesque sur l'environnement.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>PRODUITS <br/> BIODÉGRADABLES</h4>
                        </div>
                        <p>Le bambou est totalement biodégradable, contrairement aux produits traditionnels qui prennent plus de 400 ans à se décomposer !<br/> En plus d'être respectueux de l'environnement, vous utilisez un produit qui viens de la nature. </p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>ANTIBACTÉRIEN NATUREL</h4>
                        </div>
                        <p>Le bambou, en tant qu'antibactérien naturel, contient des agents qui empêchent la prolifération de bactéries nocives.<br/> C'est également un matériau non poreux qui empêche la contamination par des fongus et des bactéries, ce qui contribue à une hygiène bucco-dentaire plus saine.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>POIGNÉE <br/> ERGONOMIQUE</h4>
                        </div>
                        <p>Les Babu sont conçus pour s'adapter parfaitement à votre main et bouche, afin que votre prise en main soit parfaite pendant le processus de brossage des dents. Vous pouvez choisir entre deux tailles : adulte et enfant.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>LA MEILLEURE EXPÉRIENCE ORALE</h4>
                        </div>
                        <p>Les poils de Babu ont été spécialement conçus pour vous offrir la meilleure expérience de brossage, en protégeant vos dents et vos gencives, tout en les laissant propres et saines.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>PETITS GESTES, GRANDS CHANGEMENTS</h4>
                        </div>
                        <p>Les petites attitudes répétées par beaucoup de gens ont un impact énorme!<br/> L'utilisation de Babu aidera à des changements importants, en même temps que vous profitez d'une expérience de brossage supérieure aux traditionnelles, vous garantissez aussi un meilleur avenir pour les futures générations.</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog