import React, { Component } from 'react'

import Beach from './../../assets/produtos/dirtybeach.jpg'
import redPanda from './../../assets/produtos/panda-vermelho-carlosnunes.jpg'
import guru from './../../assets/produtos/causas003.jpg'
import causas from './../../assets/produtos/causas.jpg' 

export default class socialCauses extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
  render() {
    return (
    <div className="causes">
        <section className="hero">
            <div className="cta__">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={causas} alt=""/>
                </div>
                <div className="cta__containertext">
                    <div className="container__text--white">
                        <h2>Causas sociales</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="introduction">
            <div className="container_textintro">
                <p>
                Babu considera que su misión va mucho más allá de la puesta a disposición del público de las alternativas ecológicas a diversos bienes de consumo. Tenemos la convicción de que es nuestro deber apoyar las causas, organizaciones y personas apasionadas en hacer que nuestro planeta sea cada vez mejor, más saludable y, sobre todo, más armonioso. 
                
                Por ello, Babu dedica una parte de sus ingresos a apoyar tres vertientes:
                <ul>
                    <li>Preservación ambiental</li>
                    <li>Conservación de especies</li>
                    <li>Reeducación para mejores hábitos y conductas</li>
                </ul>
                </p>
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Preservación ambiental</h2>
                        <div className="paragrafos">
                            <p>Apoyamos a organizaciones y personas que participan en acciones de preservación del medio ambiente y, en particular, en lo que se refiere a la limpieza y recogida de objetos contaminantes y/o de basura desechada inadvertidamente en playas y bosques. 
                            Estas acciones de recogida no solo mejoran la calidad de vida de estos lugares, sino que también son un medio de sensibilización del público para una mayor conciencia ambiental y civismo.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={Beach} alt="" />
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={redPanda} alt="" />
                </div>
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Conservación de especies
                        </h2>
                        <div className="paragrafos">
                            <p>Nos hemos asociado a instituciones que tienen por finalidad la protección y la promoción del desarrollo de las distintas especies de seres vivos que se encuentran en riesgo de extinción en su hábitat natural y que pueden vivir, en estas instituciones, en condiciones similares a las de la naturaleza y, cuando sea posible, se reintegrarán en su entorno de origen, fomentando así la preservación de la especie y mitigando los riesgos de extinción.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Reeducación para mejores hábitos y conductas
                        </h2>
                        <div className="paragrafos">
                            <p>Más que resolver el problema inmediato de la consecuencia, es importante que retrocedamos un poco y que nos centremos también en la causa, de tal forma que no se repitan los mismos resultados. Es por ello que Babu también apuesta por las organizaciones e instituciones educativas que tienen la pasión de transmitir una visión mejorada del rol de la humanidad y de su impacto en nuestro ecosistema, a través de talleres, conferencias y acciones de sensibilización sobre los nuevos hábitos de consumo, así como sobre las nuevas formas de hacer frente al desperdicio o incluso reducirlo por completo.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={guru} alt="" />
                </div> 
            </div>
        </section>

    </div>
    )
  }
}
