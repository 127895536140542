import React, { Component } from 'react'
import ReactPlayer from 'react-player'

// Videos

import cerdas from './../../../../assets/cerdas.mp4'



/*images */ 

import panda from './../../../../assets/produtos/info/panda.png'

import kids from './../../../../assets/produtos/info/kids.png'


import carbonBrush from './../../../../assets/produtos/info/carvao_img.png'

import adultBrush1 from './../../../../assets/produtos/info/escova_cor1.png'
import adultBrush2 from './../../../../assets/produtos/info/escova_cor2.png'
import adultBrush3 from './../../../../assets/produtos/info/escova_cor3.png'
import adultBrush4 from './../../../../assets/produtos/info/escova_cor4.png'
import kidsBrush1 from './../../../../assets/produtos/info/nuvemkids1.png'
import kidsBrush2 from './../../../../assets/produtos/info/nuvemkids2.png'

import EscovaHoriz from './../../../../assets/produtos/info/escova_horizontal.png'

import Regua from './../../../../assets/produtos/info/reguakids.png'





class Escovas extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/brosses-a-dents/",
            progress: "100%",
            current_img: "adultone",
            kids: false,
            carbon:false,
            adult:false,
        }
        this.handleProgressBar = this.handleProgressBar.bind(this);
        this.changeImg = this.changeImg.bind(this);
    }

    handleProgressBar(progress){
        let newprogress = progress; 
        this.setState({
            progress: newprogress
        })
    }

    changeImg(text){
        let newimg = text;
        this.setState({
            current_img: newimg
        })
    }

    adultColours(){
        let adult = !this.state.adult;
        this.setState({adult})
    }

    kidsColours(){
        let kids = !this.state.kids;
        this.setState({kids})
    }

    carbonColours(){
        let carbon = !this.state.carbon;
        this.setState({carbon})
    }

  render() {
    return (
        <main>
            <section className="infoproducts">

                <section className="tipos">
                    
                    <div className="brush">
                        <div className="brush__title"><h5>Brosses à dents</h5></div>
                        <div className="brush__controls"> 
                            <div className="measures">
                                <div className="blockb blockbr block1"></div>
                                <div className="blockb blockbl block2"></div>
                                <div className="blockm block--3">3 cm</div>
                                <div className="blockm block--16">16 cm</div>
                            </div>
                            <div className="escova__img">
                                <img src={EscovaHoriz} alt="" />
                            </div>
                        </div>
                    </div>
                    <article className="info__escovas">
                        <section className="info__escovasB">
                            <div className="info__sec info__sec--one">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Les poils</h3>
                                        <p>Les poils ont été conçus en format croissant de façon à atteindre les points les plus difficiles, ce qui permet un brossage plus efficace.</p>
                                    </div>
                                </div>               
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Poignée ergonomique</h3>
                                        <p>Les Babu sont conçus pour s'adapter parfaitement à votre main et bouche, de façon à que votre prise en main soit parfaite pendant le processus de brossage des dents. Vous pouvez choisir entre deux tailles : adulte et enfant.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info__sec info__sec--two">
                                <div className="info__div">
                                    <div className="text">
                                        <h3>Avantages</h3>
                                        <p>C'est la plante avec la plus forte concentration de silice au monde, renforçant la tâche du calcium pour la fortification des os et des dents. Les nutriments du bambou sont également bienfaisants pour le renforcement des gencives.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                        <div className="dureza">
                            <div className="adulto">
                                <div className="tipo__dureza"><h4>Types de poils</h4></div>
                                <div className="adulto__dureza">
                                    <ul>
                                        <li onClick={() => this.handleProgressBar("5%")}>Extra souple</li>
                                        <li onClick={() => this.handleProgressBar("40%")}>Souple</li>
                                        <li onClick={() => this.handleProgressBar("70%")}>Médium</li>
                                        <li onClick={() => this.handleProgressBar("100%")}>Dur</li>
                                    </ul>
                                    <div style={{width:this.state.progress}} className="progressbar">
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cor">
                            <div className="cor_adulto">
                                <div className="cor__title"><h5>Couleurs des poils</h5></div>
                                <div className="cor__choices">
                                    <div className="cor__item"><img src={adultBrush1} alt="" /><h6>Vert et blanc</h6></div>
                                    <div className="cor__item"><img src={adultBrush2} alt="" /><h6>Jaune et vert</h6></div>
                                    <div className="cor__item"><img src={adultBrush3} alt="" /><h6>Bleu et vert</h6></div>
                                    <div className="cor__item"><img src={adultBrush4} alt="" /><h6>Bleu et blanc</h6></div>

                                </div>  
                            </div>
                        </div>
                    </section>
                    <section className="hero">
                        <div className="cta__ carbon">
                            <div className="cta__image">
                                <img className="diagonal--inverted" src={carbonBrush} alt="" />
                            </div>
                            <div className="cta__containertext" >
                                <div className="container__text">
                                    <h2>Brosse au charbon actif</h2>
                                    <div className="paragrafos">
                                        <p>Notre brosse à charbon actif Babu est idéale pour éliminer les mauvaises odeurs liées aux bactéries et 
                                        les tâches causées par les aliments, contribuant aussi à la protection contre la plaque dentaire.</p>
                                        <br/>
                                        <p>Comme les autres brosses Babu, les poils sont en nylon sans BPA, mais avec la différence d’être infusés au charbon actif de bambou.</p>
                                    </div>
                                    <div className="carbon">
                                        <h6>Caractéristiques</h6>
                                        <div className="carbon_brush">
                                            <div className="carbon_brush--info">
                                                <div className="bola dureza--medium"></div>
                                                <h6>Dureté médium</h6>
                                            </div>
                                            <div className="carbon_brush--info">
                                                <div className="bola only--color"></div>
                                                <h6>Couleur unique</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </section>
                    <section className="kids_section">
                        <div className="panda_section">
                            <div className="panda_img"><img src={panda} alt=""/></div>
                        </div>
                        <article className="kids_container">
                            <div className="kids_title">
                                <h3>Connaissez-vous Babu</h3>
                                <img src={kids} alt=""/>
                            </div>
                            <div className="kids_info">
                                <div className="kids_info--img">
                                    <img src={Regua} alt=""/>
                                </div>
                                <div className="kids_info--text">
                                    <div className="description">
                                        <div className="description-content">
                                            <p>
                                                Les enfants peuvent désormais aider à préserver la planète avec Babu Kids.<br/>
                                                Nos brosses sont conçues pour s'adapter parfaitement au poignet et aux dents d'un enfant.
                                            </p>
                                            <br/>
                                            <span>Disponible en poils extra souple</span>
                                        </div>
                                    </div>
                                    <div className="colours">
                                        <h4>NOS COULEURS</h4>
                                        <div className="colours-container">
                                            <div className="colour-box"> <img src={kidsBrush1} alt=""/></div>
                                            <div className="colour-box"> <img src={kidsBrush2} alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </article>                 
                    </section>
                    <div className="buynow-btn">
                        <a href={this.state.link}><span>Achetez maintenant </span></a>
                    </div>
                    <div className="used">
                        <div className="used"><h3>Que faire des brosses Babu après son utilisation ?</h3></div>
                        <div className="video__info">
                            <ReactPlayer
                                playing
                                playsinline	
                                url={[cerdas]}
                                loop
                                muted
                            />
                        </div>
                    </div>
            </section>
        </main>
    )
  }
}

export default Escovas
