import React, { Component } from 'react'


import estojo from './../../../../assets/produtos/comprar/estojo.png'


export default class Estojo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:" https://shop.babueco.com/es/produto/kit-de-comida/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Kit de comida</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={estojo} alt="Estojo" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Cantidad</h3>
                                      <p>Tiene dos cucharas, una de tamaño tradicional y otra para café o té, un cuchillo y tenedor, una pajita, un cepillo de limpieza, dos palillos y una funda de algodón.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Uso</h3>
                                      <p>Juego completo de comidas, fácil y cómodo de llevar para cualquier ocasión y respetuoso con el medio ambiente.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilidad</h3>
                                      <p>Para preservar su durabilidad, deben lavarse a mano y colocarse en un lugar seco.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






