import React, { Component } from 'react'
import $ from "jquery";
import FAQ from "./extras/FAQ";

//<img src={imgcontact} alt=""/>

import seta from './../../assets/icons/seta.png'
import imgcontact from './../../assets/produtos/foto6.png'

class Faq extends Component {
constructor(props){
    super(props)
    this.state={
        name:"",
        email:"",
        message:"",

    }
    this.handleAddition = this.handleAddition.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForm  = this.handleSubmit.bind(this);
}

handleAddition(e){
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleForm(){
    this.setState({
        formactivation:!this.state.formactivation
    })
}


  handleSubmit(e) {
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: ''
        });

        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "mailer.php",
        type: 'POST',
        data: {
            'name': this.state.name,
            'email': this.state.email,
            'message': this.state.message,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Votre message a été reçu!</h2><p>Nos agents vous contacteront dans les plus brefs délais!</p></div>'
          });
            $('#formContact').slideUp();
            $('#formContact').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: `<h1>Nous sommes désolés mais il y a eu une erreur dans l'algorithme</h1><p>Réessayez plus tard ou contactez-nous directement à info@babueco.com</p>`
          });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
  }
  render() {
    return (
        <main className="titlewrapper faq">
            <header className="hero contact">
                <div className="cta__">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={imgcontact} alt=""/>
                    </div>
                    <div className="cta__containertext">
                        <div className="container__text--white">
                            <h2>Question Fréquentes</h2>
                        </div>
                    </div>
                </div>
            </header>
            <section className="faq_sec">
                <div className="faq_container">
                  <FAQ>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q1">
                        {(isOpen, onToggle) => {
                          return (
                              <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Où sont fabriquées les brosses à dents Babu ?</span>
                              </div>
                              );
                              
                            }
                        }
                      </FAQ.Question>
                      <FAQ.Answer id="q1"> Les Babu sont produites en Asie, où se trouve le bambou de nos poignées. Il s’agit du bambou Moso ( Phyllostachys Edulis ), une des espèces de bambou avec le taux de croissance le plus élevé du monde, qui ne compromet pas l'écosystème car il n'est pas comestible pour plusieurs espèces comme, par exemple, les pandas. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q2">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>De quel matériel sont fabriquées les brosses à dents Babu ?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q2">Le matériel des poils des brosses est le nylon sans BPA et, comme il n'y a pas encore d'alternatives biodégradables (à l'exception des poils de porc qui, bien qu’étant biodégradables, sont terribles sous certains aspects), nous recommandons d'enlever les poils après son utilisation et les mettre dans l’éco-point. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q3">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Quelle est la durée de vie de la brosse à dents ?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q3"> La durée de vie d’une brosse à dents est entre 2 et 3 mois d’utilisation moyenne, mais ça dépend de plusieurs facteurs, tels que la dureté des poils choisis, la pression exercée pendant le brossage, le nombre d’utilisation par jour, entre autres. La meilleure façon d'analyser la durabilité de la brosse est d'observer la conservation des poils : lorsque ses poils présentent une grande usure, cela signifie qu'il est temps de la remplacer.</FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q4">
                        {(isOpen, onToggle) => {
                            return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Après le brossage, où dois-je ranger ma brosse à dents?</span>
                            </div>
                            );
                        }}
                        </FAQ.Question>
                        <FAQ.Answer id="q4"> Le bambou est un matériel absorbant et il est donc recommandé qu’après le brossage, vous rangez votre Babu dans un endroit sec ou sans permanent contact avec de l'eau pour éviter l'humidité. En contact avec l'humidité, la brosse Babu aura une couleur plus foncée.</FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q5">
                            {(isOpen, onToggle) => {
                            return (
                                <div className="question-tab">
                                    <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                    <span>Que faire avec la brosse à dents quand sa durée de vie est épuisée ?</span>
                                </div>
                            );
                            }}
                        </FAQ.Question>
                        <FAQ.Answer id="q5">Lorsque vous remplacez votre Babu, nous recommandons de couper les poils et de les déposer sur un éco-point. À l’exception des poils, tout le reste du matériel est biodégradable et nous conseillons de mettre l’emballage dans un éco-point bleu ( papier ) et la poignée peut être enterré, par exemple, à côté d’un arbre.</FAQ.Answer>
                    </FAQ.QAItem>
                </FAQ>
            </div>
           </section>
            <section className="contactus faq">
                <div className="container_title">
                        <h2>Avez-vous d'autres questions ?</h2>
                </div>
                <div className="container">
                    <form className="contactus__form" id="formContact" action="mailer.php" onSubmit={this.handleSubmit}>
                        <label htmlfor="name"> <span>Nom</span>
                            <input type="text" name="name" value={this.state.name} onChange={this.handleAddition} required />
                        </label>
                        <label htmlfor="email"> <span>E-mail</span>
                            <input type="email" name="email" value={this.state.email} onChange={this.handleAddition} required/>
                        </label>
                        <label htmlfor="message"> <span>Message</span>
                            <textarea name="message" value={this.state.message} onChange={this.handleAddition} required></textarea>
                        </label>
                        <input className="button__babu" type="submit" value="Envoyer" />
                    </form>
                </div> 
            </section>
        </main>
    )
  }
}

export default Faq
