import React, { Component } from 'react'


import colheres from './../../../../assets/produtos/comprar/coffeespoons.png'


export default class ColherCafe extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/cuilleres-a-cafe/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Cuillères à café</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={colheres} alt="colher café" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Quantité</h3>
                                      <p>Chaque emballage contient 4 cuillères en bambou pour remplacer celles en plastique.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilisation</h3>
                                        <p>Ces cuillères ont été faites pour être utilisées avec du café, thé, d'autres boissons et des desserts</p>
                                   </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilité</h3>
                                      <p>Pour préserver leur durabilité, elles doivent être lavées à la main et placées dans un endroit sec.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
        </section>
      </main>
      )
    }
  }






