import React, { Component } from 'react'


import estojo from './../../../../assets/produtos/comprar/estojo.png'


export default class Estojo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/fr/produto/etuis-a-couverts/",
        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Étuis à couverts</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={estojo} alt="Estojo" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Quantité</h3>
                                      <p>Contient deux cuillères, une à soupe et autre à café ou à thé, un couteau, une fourchette, une paille à boire, un écouvillon de nettoyage, deux baguettes chinoises et une pochette en coton.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilisation</h3>
                                      <p>Ensemble complet de repas, facile et pratique à transporter pour toute occasion et écologique!</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilité</h3>
                                      <p>Pour préserver leur durabilité, ils doivent être lavés à la main et placés dans un endroit sec.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Achetez maintenant </span></a>
            </div>
        </section>
      </main>
      )
    }
  }






