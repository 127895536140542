import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 

import floss from './../../../../assets/produtos/dentalfloss.png'


import panda from './../../../../assets/produtos/info/pandacloud.png'


// utilização

import fio1 from './../../../../assets/produtos/fio/fio1.mp4'
import fio2 from './../../../../assets/produtos/fio/fio2.mp4'
import fio3 from './../../../../assets/produtos/fio/fio3.mp4'



export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/fio-dentario/",

        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }


  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Fio dentário</h5></div>
                     
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={floss} alt="FIO" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Tamanho</h3>
                                    <p>O fio dentário da Babu dispõe de 50metros de comprimento, permitindo uma média de 250 utilizações.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Composição</h3>
                                    <p>Este fio de nylon tem na sua composição carvão activado para auxiliar na remoção de impurezas, permitindo uma dentição mais fresca, limpa e saudável.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Características</h3>
                                    <p>A embalagem foi desenhada de modo a reduzir ao máximo o desperdício, de forma a que a própria embalagem contenha o fio dentário, só sendo colocada na reciclagem no fim de vida do produto.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
            <section className="utilizar">
                <div className="utilizar_container">
                    <div className="panda">
                        <img src={panda} alt="" />
                    </div>
                    <div className="utilizar_title">
                        <h3>Como utilizar o fio dentário?</h3>
                        <p>Descubra como utilizar o fio dentário passando o rato pelas imagens</p>
                    </div>
                    <div className="illustration_container">
                        <div className="illustration_content">
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step1" ? true : false}
                                        playsinline	
                                        url={[fio1]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Utilize cerca de 40cm de fio dentário e enrole à volta dos dedos do meio.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step2" ? true : false}
                                        playsinline	
                                        url={[fio2]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Segure o fio com o apoio do indicador e do polegar.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step3" ? true : false}
                                        playsinline	
                                        url={[fio3]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Passe o fio em cada dente com um movimento em C. Repita o processo 2 vezes, em cada um dos dentes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

      </section>
    </main>
    )
  }
}
