import React, { Component } from 'react'
import ReactPlayer from 'react-player'


import escovilhao from './../../../../assets/produtos/paginapalhinhas/escovilhao.png'
import imagemprincipal from './../../../../assets/produtos/paginapalhinhas/imagemprincipal.png'
import palhinhas from './../../../../assets/produtos/paginapalhinhas/palhinhas.png'

import canas from './../../../../assets/palhinhas.mp4'



export default class Straw extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/palhinhas/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Palhinhas</h5></div>
                       
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={imagemprincipal} alt="palhinhas" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Benefícios</h3>
                                      <p>As nossas palhinhas são 100% amigas do ambiente por serem reutilizavéis e feitas em bambu.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Conjunto</h3>
                                      <p>Pode adquirir as palhinhas de forma individual ou num conjunto de 4 palhinhas, escovilhão e saco de pano para transporte.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Escovilhão</h3>
                                      <p>O escovilhão tem filamentos em fibra de côco, um material totalmente biodegradável, que permite limpar todas as impurezas que as palhinhas possam adquirir.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>

              </section>
              <section className="hero">
                <div className="cta__ straws">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={escovilhao} alt="" />
                    </div>
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Fibra de côco</h2>
                            <div className="paragrafos">
                                <p>O escovilhão de fibra de côco é livre de plástico e feito com recursos naturais que não prejudicam o meio ambiente. Foi desenvolvido para chegar aos locais mais difíceis da limpeza das palhinhas.</p>
                            </div>
                        </div>
                    </div> 
                </div>
              </section>
              <section className="hero">
                <div className="cta__ straws inverted">
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Conjunto de palhinhas</h2>
                            <div className="paragrafos">
                                <p>O conjunto de palhinhas da Babu é ideal para partilhar uma boa bebida com a família e amigos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cta__image">
                        <img className="" src={palhinhas} alt="" />
                    </div> 
                </div>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
              <div className="used">
                <div className="used"><h3>Como limpar as suas palhinhas Babu?</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>
        </section>
      </main>
      )
    }
  }






