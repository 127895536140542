import React, { Component } from 'react'
import $ from "jquery";
import FAQ from "./extras/FAQ";

//<img src={imgcontact} alt=""/>

import seta from './../../assets/icons/seta.png'
import imgcontact from './../../assets/produtos/foto6.png'

class Faq extends Component {
constructor(props){
    super(props)
    this.state={
        name:"",
        email:"",
        message:"",

    }
    this.handleAddition = this.handleAddition.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForm  = this.handleSubmit.bind(this);
}

handleAddition(e){
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleForm(){
    this.setState({
        formactivation:!this.state.formactivation
    })
}


  handleSubmit(e) {
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: ''
        });

        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "mailer.php",
        type: 'POST',
        data: {
            'name': this.state.name,
            'email': this.state.email,
            'message': this.state.message,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>A sua mensagem foi recebida!</h2><p>Os nossos agentes entrarão em contacto assim que for possivel!</p></div>'
            });
            $('#formContact').slideUp();
            $('#formContact').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
  }
  render() {
    return (
        <main className="titlewrapper faq">
            <header className="hero contact">
                <div className="cta__">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={imgcontact} alt=""/>
                    </div>
                    <div className="cta__containertext">
                        <div className="container__text--white">
                            <h2>Perguntas frequentes</h2>
                        </div>
                    </div>
                </div>
            </header>
            <section className="faq_sec">
                <div className="faq_container">
                  <FAQ>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q1">
                        {(isOpen, onToggle) => {
                          return (
                              <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Onde são feitas as escovas de dentes Babu?</span>
                              </div>
                              );
                              
                            }
                        }
                      </FAQ.Question>
                      <FAQ.Answer id="q1"> As Babu são produzidas na Ásia, local onde está localizado o bambu dos nossos cabos. A planta é o moso bambu (Phyllostachys Edulis), sendo uma das espécies de bambu com maior taxa de crescimento do mundo, não comprometendo o ecossistema uma vez que não é comestível para várias espécies, como os pandas. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q2">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>De que material são feitas as escovas de dentes Babu?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q2"> O material das cerdas é nylon sem BPA e como não existem ainda alternativas biodegradáveis (excluindo as cerdas de pêlo de porco que, apesar de serem biodegradáveis, são terríveis em vários aspetos), aconselhamos a que se retirar as cerdas depois de utilizar a escova e colocar no ecoponto. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q3">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Qual o tempo de duração da escova?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q3"> O tempo médio fica entre os 2 e os 3 meses de utilização, mas depende de vários fatores, tal como a dureza da cerda escolhida, a pressão exercida durante a escovagem, o número de utilizações diárias, entre outros. A melhor forma de analisar a durabilidade da escova passa por observar a conservação das cerdas: quando as cerdas apresentam já um elevado desgaste significa que está na altura de a trocar.</FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q4">
                        {(isOpen, onToggle) => {
                            return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Após a escovagem, onde devo guardar a escova de dentes?</span>
                            </div>
                            );
                        }}
                        </FAQ.Question>
                        <FAQ.Answer id="q4"> Como o bambu é um material absorvente, é aconselhável após a escovagem deixar a sua Babu num local seco e/ou sem contacto permanente com a água para evitar a humidade. Estando em contacto com a humidade, a Babu apresenta um aspeto mais escuro. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q5">
                            {(isOpen, onToggle) => {
                            return (
                                <div className="question-tab">
                                    <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                    <span>O que fazer à escova de dentes quando terminar a sua utilização?</span>
                                </div>
                            );
                            }}
                        </FAQ.Question>
                        <FAQ.Answer id="q5">Quando trocar a sua Babu, aconselhamos a cortar as cerdas e colocar no ecoponto. Excetuando as cerdas, todo o resto do material é biodegradável pelo que aconselhamos a colocar a embalagem no ecoponto azul do papel e o próprio cabo pode até enterrar junto a uma árvore, por exemplo.</FAQ.Answer>
                    </FAQ.QAItem>
                </FAQ>
            </div>
           </section>
            <section className="contactus faq">
                <div className="container_title">
                        <h2>Tem mais questões?</h2>
                </div>
                <div className="container">
                    <form className="contactus__form" id="formContact" action="mailer.php" onSubmit={this.handleSubmit}>
                        <label htmlfor="name"> <span>Nome</span>
                            <input type="text" name="name" value={this.state.name} onChange={this.handleAddition} required />
                        </label>
                        <label htmlfor="email"> <span>E-mail</span>
                            <input type="email" name="email" value={this.state.email} onChange={this.handleAddition} required/>
                        </label>
                        <label htmlfor="message"> <span>Mensagem</span>
                            <textarea name="message" value={this.state.message} onChange={this.handleAddition} required></textarea>
                        </label>
                        <input className="button__babu" type="submit" value="Enviar" />
                    </form>
                </div> 
            </section>
        </main>
    )
  }
}

export default Faq
