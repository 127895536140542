import React, { Component } from 'react'


import talheres from './../../../../assets/produtos/comprar/talheres.png'





export default class Talheres extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/talheres",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Talheres</h5></div>
                       
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={talheres} alt="talheres" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Quantidade</h3>
                                      <p>Este conjunto dispõe de três talheres em bambu: colher,garfo e faca.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilização</h3>
                                      <p>Um conjunto que permite optar por uma alternativa ecológica e satisfazer as necessidades para qualquer refeição fora de casa.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilidade</h3>
                                      <p>Para preservar a sua durabilidade devem ser lavadas manualmente e colocadas num local seco.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>

              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






