import React, { Component } from 'react'
import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/quemsomos.jpg'


import escolher from './../../assets/why_es.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>QUIÉNES SOMOS</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>Nos apasiona aportar nuevas ideas que contribuyan a un mundo más sostenible.</h4>
                <p>
                    La marca Babu es el fruto de la creencia de que podemos y debemos crear nuevas soluciones a las necesidades actuales de cada uno de nosotros. 
                    Los productos que usamos todos los días, incluso los aparentemente más insignificantes, tienen un enorme impacto cuando se utilizan en gran escala, tales como los artículos desechables de plástico.
                    <br/>
                    No hay nada mejor que contribuir al cambio que todos queremos ver y experimentar, empezando por la introducción de productos que solemos usar, pero que están fabricados con materiales que no pongan en riesgo la biodiversidad y la preservación del ecosistema.
                    <br/> 
                    Desde esta perspectiva se inicia Babu, una marca que comienza con cepillos de dientes de bambú, un producto que inicialmente se enmarcaría en un nicho de mercado, pero que ambicionamos introducir cada vez más en cuartos de baño, afirmándose como una auténtica alternativa a la antigua y obsoleta solución de los cepillos de plástico.
                    <br/>
                    Creemos que el uso de los productos Babu hace que sea marcadamente innecesario elegir productos hechos de materiales no reciclables, que no han sido innovados desde hace más de 50 años y que afecten en gran medida los niveles de contaminación de nuestro planeta.
                    <br/>   
                    Los Babu no solo son los más bonitos, los mejores y los únicos amigables con el medio ambiente, sino que son el futuro. 
                    <br/><br/>
                    <strong>¿Y si podemos crear hoy el futuro de mañana?</strong>
                    <br/>           
                    Al elegir Babu, no estás solamente haciendo una elección más amigable con el medio ambiente, sino que estás ayudándonos a colaborar con causas sociales relevantes para todos nosotros, tales como la preservación del medio ambiente, la conservación de especies y el apoyo para la concienciación y replanteamiento de nuevos hábitos junto a las nuevas generaciones.
                    <br/>
                    La proximidad a ese futuro sólo depende de ti, por lo que asumimos este compromiso: cada vez que eliges usar un producto Babu, no estás solamente satisfaciendo una necesidad, sino que estás formando parte de un fuerte cambio silencioso, cuya magnitud de su impacto depende de todos nosotros, todos los días, ¡con una sencilla elección! 
                    
                </p>
            </div>

          </div>

      </section>
      <section className="aboutus--video">
          <div className="videos__title">
              <h2>¿Por qué es tan importante elegir un Babu?</h2>
          </div>
          <div className="video__info">
                <ReactPlayer
                    playing
                    playsinline	
                    url={[escolher]}
                    loop
                    muted
                />
          </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog