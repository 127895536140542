import React, { Component } from 'react'
import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/quemsomos.jpg'


import escolher from './../../assets/why_pt.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>Quem somos</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>Somos apaixonados por trazer novas ideias que contribuam para um mundo mais sustentável</h4>
                <p>
                    A marca Babu nasce da crença que podemos e devemos criar novas soluções para as atuais necessidades de todos nós.
                    Os produtos que utilizamos diariamente, mesmo aqueles aparentemente mais insignificantes, têm um impacto enorme quando utilizados em grande escala, como os artigos descartáveis em plástico.
                    <br/>
                    Nada melhor do que contribuir para a mudança que todos queremos ver e vivenciar, começando por introduzir produtos já utilizados por nós mas fabricados com materiais que não coloquem em causa a biodiversidade e preservação do ecossistema.
                    <br/> 
                    É sobre esta visão que se inicia a Babu, uma marca que começa com escovas de dentes em bambu, produto que estaria à partida enquadrado num nicho de mercado, mas que ambicionamos colocar em cada vez mais casas de banho, assumindo-se como uma verdadeira alternativa à velha e obsoleta solução das escovas de plástico.
                    <br/>
                    Acreditamos que a utilização dos produtos Babu torna assumidamente desnecessária a escolha por produtos produzidos em materiais não recicláveis, que não sofrem qualquer inovação há mais de 50 anos e que afetam grandemente os níveis de poluição do nosso planeta.
                    <br/>   
                    As Babu não só são as mais bonitas, melhores e as únicas amigas do ambiente, elas são o futuro.
                    <br/><br/>
                    <strong>E se pudermos criar hoje o futuro de amanhã?</strong>
                    <br/>           
                    Ao preferir a Babu não está apenas a fazer uma escolha mais amiga do meio ambiente, está a ajudar-nos a contribuir para causas sociais relevantes para todos nós, como a preservação ambiental, conservação das espécies e apoio na consciencialização e reformulação de novos hábitos junto das novas gerações.
                    <br/>
                    A proximidade a esse futuro só depende de si, daí assumirmos este compromisso: cada vez que escolhe utilizar um produto Babu, não está só a satisfazer uma necessidade, está a fazer parte de uma forte mudança silenciosa cuja dimensão do seu impacto depende de todos nós, todos os dias, com uma simples escolha! 
                    
                </p>
            </div>

          </div>

      </section>
      <section className="aboutus--video">
          <div className="videos__title">
              <h2>Porquê escolher uma Babu é tão importante?</h2>
          </div>
          <div className="video__info">
                <ReactPlayer
                    playing
                    playsinline	
                    url={[escolher]}
                    loop
                    muted
                />
          </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog