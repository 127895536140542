import React, { Component } from 'react'


import colheres from './../../../../assets/produtos/comprar/coffeespoons.png'



export default class ColherCafe extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/colheres-de-cafe/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Colheres de café</h5></div>
                       
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={colheres} alt="colher café" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Quantidade</h3>
                                      <p>Cada embalagem trás 4 colheres feitas em bambu para poder substituir as de plástico.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilização</h3>
                                        <p>Estas colheres foram feitas para serem usadas no café, chá, outras bebidas e sobremesa.</p>
                                   </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilidade</h3>
                                      <p>Para preservar a sua durabilidade devem ser lavadas manualmente e colocadas num local seco.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>

              </section>

            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






