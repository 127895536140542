import React, { Component } from 'react'


/* componentes */




// Media 

import Envio from './../../assets/portes_en.png'

//babu
import escovas from './../../assets/produtos/comprar/escovas.png'
import escovaskids from './../../assets/produtos/comprar/escovaskids.png'
import canas from './../../assets/produtos/comprar/canas-transportadora.png'
import cotonetes from './../../assets/produtos/comprar/cotonetes.png'
import konjac from './../../assets/produtos/comprar/esponjakonjac.png'
import fiodental from './../../assets/produtos/comprar/fiodental.png'
import cabelo from './../../assets/produtos/comprar/cabelo.png'
import pasta from './../../assets/produtos/comprar/pastadedentes.png'


//babulife
import talheres from './../../assets/produtos/comprar/c-talheres.png'
import estojo from './../../assets/produtos/comprar/c-estojo.png'
import coffeespoons from './../../assets/produtos/comprar/c-coffeespoons.png'
import straw from './../../assets/produtos/comprar/palhinha.png'
import colhergarfo from './../../assets/produtos/comprar/c-forkspoon.png'


import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"

import fotoproduct from './../../assets/produtos/foto-produtos.png' //foto background
import lifeproduct from './../../assets/produtos/kitshot.jpg'
import Natallogo from "./../../assets/logos/selllogont-en.png"

import Valentine from "./../../assets/logos/valentine.png"


class Comprar extends Component {
    constructor(props){
        super(props)
        this.state={
            higiene:[
                {id:"escova", name:"Toothbrushes", img:escovas, link:"https://shop.babueco.com/en/produto/toothbrushes/" },
                {id:"kids", name:"Toothbrushes kids", img:escovaskids, link:"https://shop.babueco.com/en/produto/toothbrush-kids/" },
                {id:"cana", name:"Travel cases", img:canas, link:"https://shop.babueco.com/en/produto/travel-case/" },
                {id:"fio", name:"Dental floss", img:fiodental, link:"https://shop.babueco.com/en/produto/dental-floss/" },
                {id:"cotonetes", name:"Swabs", img:cotonetes, link:"https://shop.babueco.com/en/produto/cotton-swabs/" },
                {id:"konjac", name:"Konjac sponges", img:konjac, link:"https://shop.babueco.com/en/produto/konjac-sponges/" },
                {id:"hairbrush", name:"Hairbrush", img:cabelo, link:"https://shop.babueco.com/en/produto/bamboo-hairbrush/" },
                {id:"toothpaste", name:"Toothpaste", img:pasta, link:"https://shop.babueco.com/en/produto/toothpaste/" },

            ],
            babulife:[
                {id:"palhinhas", name:"Straws", img:straw, link:"https://shop.babueco.com/en/produto/straws/" },
                {id:"colhercafe", name:"Coffee spoons", img:coffeespoons, link:"https://shop.babueco.com/en/produto/coffee-spoons/" },
                {id:"talheres", name:"Spoon-fork", img:colhergarfo, link:"https://shop.babueco.com/en/produto/spoon-fork/" },
                {id:"talheres", name:"Cutlery", img:talheres, link:"https://shop.babueco.com/en/produto/cutlery-set/" },
                {id:"estojo", name:"Meal case", img:estojo, link:"https://shop.babueco.com/en/produto/meal-case/" },

            ],
            switch:"higiene",
        }
        this.handleSwitch = this.handleSwitch.bind(this);
    }

handleSwitch(prop){
    let nextstate = prop;
    this.setState({switch:nextstate})
}

  render() {
    return (
        <main className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Store</h2>
                                <p>Babu products can be part of your life and contribute to the protection of our planet. Stay tuned for all the news!</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div>
                        <div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div>
                        {/*<div className=""><a href="https://shop.babueco.com/en/produto/babu-valentine-2" target="_blank"><img src={Valentine} alt="" /></a></div>*/}

                    </div>
                </header>
            </header>
            <section className="item-list">
            {this.state.switch === "higiene" ? 
                this.state.higiene.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <img src={item.img} alt=""/>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                this.state.babulife.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <img src={item.img} alt=""/>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </section>
            <section className="envio-section">
                <div className="envio-img" >
                    <img src={Envio}  alt=""/>
                </div>
            </section>
        </main>
    )
  }
}

export default Comprar