import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 



import panda from './../../../../assets/produtos/info/panda.png'
import kids from './../../../../assets/produtos/info/kids.png'

import canahorizontal from './../../../../assets/produtos/info/cana/canahorizontal.png'
import regua from './../../../../assets/produtos/info/cana/reguakids.png'




import canas from './../../../../assets/cana_es.mp4'


export default class CanasInfo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/estuche-para-cepillo/",

        }
    }
  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
            
            <div className="brush">
                <div className="brush__title"><h5>Estuche para cepillo</h5></div>
                <div className="brush__controls"> 
                    <div className="measures brush">
                        <div className="blockb blockbr block1"></div>
                        <div className="blockb  block2"></div>
                        <div className="blockm block--3"></div>
                        <div className="blockm block--16">21 cm</div>
                    </div>
                    <div className="escova__img">
                        <img src={canahorizontal} alt="" />
                    </div>
                </div>
            </div>
            <article className="info__escovas">
                <section className="info__escovasB">
                    <div className="info__sec info__sec--one">
                        <div className="info__div">
                            <div className="text">
                                <h3>Tamaño</h3>
                                <p>El Estuche para cepillo para adultos mide 21cm de altura y 2,5 cm de diámetro, el tamaño ideal para llevar en un bolso o mochila. </p>
                            </div>
                        </div>               
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Beneficios</h3>
                                <p>El Estuche para cepillo está hecho 100% de bambú. Además de ayudarnos a transportar el cepillo de dientes, lo protege de los malos olores, bacterias y polvo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Uso</h3>
                                <p>El Estuche para cepillo es de uso muy versátil. Además de servir para transportar los cepillos de dientes, también puede servir  para transportar las pajitas o incluso como simple estuche.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            </section>
            <section className="kids_section">
                <div className="panda_section">
                    <div className="panda_img"><img src={panda} alt=""/></div>
                </div>
                <article className="kids_container">
                    <div className="kids_title">
                        <h3>¿Ya conoces los Babu Kids?</h3>
                        <img src={kids} alt=""/>
                    </div>
                    <div className="kids_info">
                        <div className="kids_info--img">
                            <img src={regua} alt=""/>
                        </div>
                        <div className="kids_info--text">
                            <div className="description">
                                <div className="description-content">
                                    <p>
                                         Con 17 cm de altura y 2.5 cm de diámetro, tiene un tamaño ideal para llevar en un bolso o mochila.
                                        <br/>
                                        <br/>
                                        Hecho 100% de bambú, este estuche portátil además de ayudar a prevenir olores, bacterias o polvo, permite el transporte y la protección de los cepillos de dientes Babu.
                                    </p>

                                </div>
                            </div>
                            <div className="colours">
                                <div className="colours-container">
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
              </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
              <div className="used">
                <div className="used"><h3>Preserve tu Estuche para cepillo</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>

      </section>
    </main>
    )
  }
}
