import React, { Component } from 'react'


import estojo from './../../../../assets/produtos/comprar/estojo.png'


export default class Estojo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/meal-case/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Meal Case</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={estojo} alt="Estojo" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Quantity</h3>
                                      <p>It contains two spoons, a tablespoon with a traditional size and one for coffee or tea, a knife and fork, a straw, a cleaning brush, two chopsticks and a cotton case.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Use</h3>
                                      <p>Complete set for meals, easy and convenient to carry for any occasion and environmentally friendly!</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durability</h3>
                                      <p>To preserve their durability they should be washed by hand and placed in a dry place.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






