import React, { Component } from 'react'
import ReactPlayer from 'react-player'






import escovilhao from './../../../../assets/produtos/paginapalhinhas/escovilhao.png'
import imagemprincipal from './../../../../assets/produtos/paginapalhinhas/imagemprincipal.png'
import palhinhas from './../../../../assets/produtos/paginapalhinhas/palhinhas.png'

import canas from './../../../../assets/palhinhas.mp4'




export default class Straw extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/straws/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Straws</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={imagemprincipal} alt="palhinhas" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Benefits</h3>
                                      <p>Our straws are 100% environmentally friendly because they are reusable and made of bamboo.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Set</h3>
                                      <p>You can buy the straws individually or in a set of 4 straws, brush and transport bag.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Brush</h3>
                                      <p>The brush has coconut fiber filaments, a fully biodegradable material that allows to clean all impurities that straws can acquire.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
              <section className="hero">
                <div className="cta__ straws">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={escovilhao} alt="" />
                    </div>
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Coconut fiber brush</h2>
                            <div className="paragrafos">
                                <p>The coconut fiber brush is free of plastic and made with natural resources that do not harm the environment. It is designed to reach the most difficult to clean spots.</p>                            </div>
                        </div>
                    </div> 
                </div>
              </section>
              <section className="hero">
                <div className="cta__ straws inverted">
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Straws Set</h2>
                            <div className="paragrafos">
                                <p>Babu's straw set is ideal for sharing a good drink with family and friends.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cta__image">
                        <img className="" src={palhinhas} alt="" />
                    </div> 
                </div>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
              <div className="used">
                <div className="used"><h3>How to clean your Babu straws?</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>
        </section>
      </main>
      )
    }
  }






