import React, { Component } from 'react'
import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/quemsomos.jpg'


import escolher from './../../assets/why_en.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>About us</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>We are passionate about bringing new ideas that contribute to a more sustainable world.</h4>
                <p>
                    Babu brand was created because we believe that we can and should create new solutions to our current needs. 
                    The products we use every day, even seemingly insignificant ones, have a huge impact when used on a large scale, such as disposable plastic items.
                    <br/>
                    Nothing better than contributing to the change we all want, starting with introducing products already used by us but made with materials that do not jeopardize biodiversity and ecosystem preservation.
                    <br/> 
                    This is where Babu stands, a brand that started providing bamboo toothbrushes, a product that would initially be framed in a niche market, but that we aim to put in more and more bathrooms, becoming a true alternative to the old and obsolete solution of plastic brushes.
                    <br/>
                    We believe that the use of Babu products replaces the need to choose products made from non-recyclable materials that have not been innovated for over 50 years and which greatly affect our planet's pollution levels.
                    <br/>   
                    Babu's are not only the prettiest, the best and the only environmentally friendly, they are the future.
                    <br/><br/>
                    <strong>What if we can create tomorrow's future today?</strong>
                    <br/>           
                    By choosing Babu, you are not just making a more environmentally friendly choice, you are helping to contribute to social causes relevant to all of us, such as environmental preservation, species conservation and support in raising awareness and reshaping new habits for young generations.
                    <br/>
                    Proximity to that future is up to you, so we make this commitment: every time you choose to use a Babu product, you are not just meeting a need, you are part of a strong, silent change, the extent of its impact depends on all of us, every day, with a simple choice!
                    
                </p>
            </div>

          </div>

      </section>
      <section className="aboutus--video">
          <div className="videos__title">
              <h2>Why is choosing a Babu so important?</h2>
          </div>
          <div className="video__info">
                <ReactPlayer
                    playing
                    playsinline	
                    url={[escolher]}
                    loop
                    muted
                />
          </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog