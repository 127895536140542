import React, { Component } from 'react'


import colhergarfo from './../../../../assets/produtos/comprar/forkspoon.png'


export default class ColherGarfo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/spoon-fork/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Spoon-fork</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={colhergarfo} alt="Colher-Garfo" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>2 in 1</h3>
                                      <p>A single article with two possibilities, sometimes a fork, sometimes a spoon.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Use</h3>
                                      <p>Great for picnics, beach trips or any meal away from home.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durability</h3>
                                      <p>To preserve their durability they should be washed by hand and placed in a dry place.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






