import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import {
//  TransitionGroup,
//  CSSTransition,
//} from 'react-transition-group'
import ScrollToTop from './components/ScrollToTop'
//import Cookies from 'js-cookie'
//import PropTypes from "prop-types";


import Home from './pages/home'
import Produtos from './pages/produtos'
import Comprar from './pages/comprar'
import About from './pages/aboutUs'
import Why from './pages/porque'
import Resell from './pages/resell'
import SocialCauses from './pages/socialcauses'
import Contacts from './pages/contactos'
import Faq from './pages/faq'
import ErrorPage from "./pages/404";




// componennts 
import Header from './components/header'
import Footer from './components/footer'

function App() {
  return (
    <div className="wrapper">
    <Router>
      <Router>
        <Route render={({ location })=>(
          <>
          <Header/>
        
          <ScrollToTop>
             <Switch location={location} >
                  <Route exact path="/" render={()=> <Home />} />
                  <Route path="/productos" component={Produtos}/>
                  <Route path="/tienda" component={Comprar}/>
                  <Route path="/sobre" component={About}/>
                  <Route path="/porque" component={Why}/>
                  <Route path="/causas" component={SocialCauses}/>
                  <Route path="/contactos" component={Contacts}/>
                  <Route path="/donde" component={Resell}/>
                  <Route path="/preguntasfrecuentes" component={Faq}/>


                  
                  <Redirect from="/produtos" to="/productos"/>
                  <Redirect from="/loja" to="/tienda"/>
                  <Redirect from="/sobre" to="/sobre"/>
                  <Redirect from="/porque" to="/porque"/>
                  <Redirect from="/causas" to="/causas"/>
                  <Redirect from="/contactos" to="/contactos"/>
                  <Redirect from="/onde" to="/donde"/>
                  <Redirect from="/perguntasfrequentes" to="/preguntasfrecuentes"/>


                  <Redirect from="/products" to="/productos"/>
                  <Redirect from="/store" to="/tienda"/>
                  <Redirect from="/about" to="/sobre"/>
                  <Redirect from="/why" to="/porque"/>
                  <Redirect from="/causes" to="/causas"/>
                  <Redirect from="/contacts" to="/contactos"/>
                  <Redirect from="/wheretofind" to="/donde"/>
                  <Redirect from="/faq" to="/preguntasfrecuentes"/>
                  
                  <Redirect from="/produits" to="/productos"/>
                  <Redirect from="/boutique" to="/tienda"/>
                  <Redirect from="/apropos" to="/sobre"/>
                  <Redirect from="/pourquoi" to="/porque"/>
                  <Redirect from="/causessociales" to="/causas"/>
                  <Redirect from="/contact" to="/contactos"/>
                  <Redirect from="/Outrouver" to="/donde"/>
                  <Redirect from="/questions" to="/preguntasfrecuentes"/>

                  <Route component={ErrorPage}/>
                  
              </Switch>
          </ScrollToTop>
          </>
      )}/>
      <Footer/>
      </Router>
    </Router>
    </div>
    );
}

export default App;
