import React, { Component } from 'react'


import colheres from './../../../../assets/produtos/comprar/coffeespoons.png'


export default class ColherCafe extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/cucharas-de-cafe/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Cucharillas de café</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={colheres} alt="colher café" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Cantidad</h3>
                                      <p>Cada envase contiene 4 cucharillas hechas de bambú para reemplazar las cucharas de plástico.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Uso</h3>
                                        <p>Estas cucharillas han sido diseñadas para usarse en el café, té, otras bebidas y postres.</p>
                                   </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilidad</h3>
                                      <p>Para preservar su durabilidad, deben lavarse a mano y almacenarlas en un lugar seco.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






