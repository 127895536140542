import React, { Component } from 'react'
import ReactPlayer from 'react-player'






import escovilhao from './../../../../assets/produtos/paginapalhinhas/escovilhao.png'
import imagemprincipal from './../../../../assets/produtos/paginapalhinhas/imagemprincipal.png'
import palhinhas from './../../../../assets/produtos/paginapalhinhas/palhinhas.png'

import canas from './../../../../assets/palhinhas.mp4'




export default class Straw extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/pajitas/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Pajitas</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={imagemprincipal} alt="palhinhas" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Beneficios</h3>
                                      <p>Nuestras pajitas son 100% ecológicas porque son reutilizables y están hechas de bambú.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Conjunto</h3>
                                      <p>Puedes comprar pajitas individuales o un conjunto de 4 pajitas, cepillo de limpieza y bolsa de tela para transportar.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Cepillo limpiador</h3>
                                      <p>El cepillo limpiador tiene filamentos de fibra de coco, un material totalmente biodegradable que permite eliminar todas las impurezas que puedan adquirir las pajitas.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
              <section className="hero">
                <div className="cta__ straws">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={escovilhao} alt="" />
                    </div>
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Fibra de coco</h2>
                            <div className="paragrafos">
                                <p>El cepillo limpiador en fibra de coco no contiene plástico y está hecho con recursos naturales que no dañan el medio ambiente. Ha sido diseñado para llegar a los sitios de limpieza más difíciles de las pajitas.</p>
                            </div>
                        </div>
                    </div> 
                </div>
              </section>
              <section className="hero">
                <div className="cta__ straws inverted">
                    <div className="cta__containertext" >
                        <div className="container__text--white">
                            <h2>Conjunto de pajitas</h2>
                            <div className="paragrafos">
                                <p>El conjunto de pajitas de Babu es la opción perfecta para compartir una buena bebida con familiares y amigos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cta__image">
                        <img className="" src={palhinhas} alt="" />
                    </div> 
                </div>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
              <div className="used">
                 <div className="used"><h3>¿Cómo limpiar tus pajitas Babu?</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>







        </section>
      </main>
      )
    }
  }






