import React, { Component } from 'react'
//import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/whybabu.png'


//import escolher from './../../assets/why_pt.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>Porquê Babu?</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>PORQUÊ BABU EM BAMBU</h4>
                <p>
                    As características do bambu enquanto planta, as suas notáveis propriedades como material e as suas atuais aplicações no mundo criam uma vasta gama de possíveis utilizações com potencial para dar resposta a um desenvolvimento que proporcione maior equidade social, melhoria do meio ambiente e aumento da qualidade de vida.
                    <br/>
                    Assim, o crescimento da população mundial tem provocado fortes pressões no meio ambiente proporcionando um decréscimo na quantidade e na qualidade dos recursos florestais, contribuindo assim para o interesse de novos recursos vegetais, como é o caso da utilização do bambu. Dessa forma o bambu foi redescoberto como um recurso renovável e não poluente.
                    <br/>
                    Atualmente, num mundo em que o desenvolvimento sustentável não é mais uma opção e sim uma necessidade, o bambu pode ser utilizado de uma forma abundante e substituir muitos materiais no fabrico de vários produtos devido ao menor tempo de crescimento e capacidade de ser um regenerador ambiental.

                </p>
            </div>
          </div>
          <div className="aboutus__sabias">
              <div className="sabias__boxtitle">
                  <h3 className="sabias__titulo">SABIA QUE O BAMBU…</h3>
              </div>
              <div className="sabias porque">
                  <div className="sabias__column">
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>É de rápido crescimento</h4>
                          </div>
                          <p>O bambu é a planta com maior crescimento diário do mundo, atualmente o recorde foi atingido no Japão, onde esta planta cresceu 121 centímetros em apenas 24 horas!</p>
                      </div>
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>TEM PROPRIEDADES MEDICINAIS</h4>
                          </div>
                          <p>O carvão de bambu apresenta várias propriedades medicinais, a sua utilização é bastante variada, há quem o utilize em banhos de imersão, por exemplo. Assim como as suas folhas, que são utilizadas em chás com múltiplos benefícios.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>É ÓPTIMO PARA OS DENTES</h4>
                        </div>
                        <p>É a planta com maior concentração de sílica em todo o planeta, que reforça o trabalho do cálcio para a construção de ossos e dentes fortes.<br/> Os nutrientes do bambu também são benéficos para a gengiva, fortalecendo-a.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>É UM SUBSTITUTO DO PAPEL</h4>
                        </div>
                        <p>É uma planta robusta, que fabrica os seus próprios componentes antibacterianos e que se desenvolve muito bem sem pesticidas. As suas fibras porosas podem produzir tecidos que respiram e são tão macios como a seda. </p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>TEM MÚLTIPLAS FINALIDADES</h4>
                        </div>
                        <p>Existem atualmente mais de 4000 utilizações possíveis para o bambu e a cada dia que passa novas formas são descobertas e aplicadas. Estudos recentes apontam que o álcool etanol pode ser retirado do bambu, podendo ser utilizado também como combustível.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>CAPACIDADES REGENERATIVAS</h4>
                        </div>
                        <p>É capaz de regenerar após o corte, podendo viver até cem anos e, sendo feito o tratamento adequado, pode-se tornar num material durável por muitas gerações.<br/> Uma matéria prima renovável, de baixo custo, com diversas possibilidades de uso, o bambu é cada vez mais utilizado na conservação ambiental.</p>
                    </div>
                  </div>

              </div>
          </div>

          <div className="aboutus__sabias">
            <div className="sabias__boxtitle">
                <h3 className="sabias__titulo">PORQUE ESCOLHER UMA BABU É TÃO IMPORTANTE</h3>
            </div>
            <div className="sabias porque">
                <div className="sabias__column">
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4> ALTERNATIVA ÀS TRADICIONAIS</h4>
                        </div>
                        <p>É a única verdadeira alternativa às escovas de plástico ao contribuir para a resolução de um problema que nos afeta a todos, pois as escovas tradicionais são responsáveis por toneladas de resíduos todos os anos, causando um impacto ambiental gigantesco.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>PRODUTOS<br/> BIODEGRADÁVEIS</h4>
                        </div>
                        <p>O bambu é totalmente biodegradável, ao contrário das tradicionais que demoram mais de 400 anos a decompor-se!<br/> Para além de amigo do ambiente, está a utilizar um produto vindo da natureza.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>ANTIBACTERIANO NATURAL</h4>
                        </div>
                        <p>O bambu como antibacteriano natural que é, tem na sua constituição agentes que previnem a proliferação de bactérias nocivas.<br/> É também um material sem porosidade que evita assim a contaminação por fungos e bactérias, contribuindo para que a sua higiene oral seja mais saudável.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>CABO <br/> ERGONÓMICO</h4>
                        </div>
                        <p>As Babu estão desenhadas para se adaptarem perfeitamente à sua mão e boca de modo a que o seu manuseamento seja perfeito no processo de escovagem dos seus dentes, dando-lhe a possibilidade de optar por dois tamanhos: adulto e criança.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>A MELHOR EXPERIÊNCIA ORAL</h4>
                        </div>
                        <p>As cerdas das Babu foram especialmente desenhadas para lhe oferecer a melhor experiência de escovagem, protegendo os seus dentes e gengivas ao mesmo tempo que os deixa limpos e saudáveis.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>PEQUENOS GESTOS, GRANDES MUDANÇAS</h4>
                        </div>
                        <p>Pequenas atitudes repetidas por muitos têm um impacto enorme!<br/> Utilizar as Babu contribuirá para que grandes mudanças aconteçam enquanto tem uma experiência de escovagem muito melhor que as tradicionais, está também a assegurar um melhor futuro para as gerações vindouras.</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog