import React, { Component } from 'react'



import cotonetes from './../../../../assets/produtos/pastadedentes.png'


export default class Pasta extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/toothpaste/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Toothpaste</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={cotonetes} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composition</h3>
                                      <p>The first Babu toothpaste, with natural ingredients and without SLS (Sodium lauryl sulfate) nor Triclosan. Not suitable for children. Contains fluoride.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Flavour</h3>
                                      <p>It has a lemon flavor so that after brushing you feel a freshness of cleanliness combined with the comfort of knowing that it is perfectly healthy without introducing harmful chemicals in the body.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>100% Reciclavel</h3>
                                      <p>Babu has strongly committed itself to presenting a toothpaste with healthy ingredients, but also that is practical, hygienic and above all: with the smallest ecological footprint possible. Therefore we opted for a 100% recyclable tube with the lowest CO2 emission in its entire life cycle, from the production process to its recycling.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






