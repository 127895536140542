import React, { Component } from 'react'
import $ from "jquery";
import {Link} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'


import ReactPlayer from 'react-player'

import Fade from 'react-reveal'
import feat1 from './../../assets/destaque/fundo.png'
import feat2 from './../../assets/destaque/image.png'
import Snowfall from 'react-snowfall'
import Natallogo from "./../../assets/logos/selllogont.png"


import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';




/* images */ 
import fotoproduct from './../../assets/produtos/foto-produtos.jpg'
import redPanda from './../../assets/produtos/panda-vermelho-carlosnunes.jpg'
import certs from './../../assets/produtos/certs.png'
import whybabu from './../../assets/produtos/whybabu.png'

/*imagens produtos */ 


import escova from './../../assets/produtos/comprar/escovas.png'
import cana from './../../assets/produtos/comprar/canaadult.png'
import fio from './../../assets/produtos/comprar/fiodental.png'
import swab from './../../assets/produtos/comprar/cotonetes.png'
import konjac from './../../assets/produtos/comprar/esponjakonjac.png'
import cabelo from './../../assets/produtos/comprar/cabelo.png'
import pasta from './../../assets/produtos/comprar/pastadedentes.png'


import straw from './../../assets/produtos/comprar/palhinha.png'
import talheres from './../../assets/produtos/comprar/talheres.png'
import colherescafe from './../../assets/produtos/comprar/coffeespoons.png'
import colhergarfo from './../../assets/produtos/comprar/forkspoon.png'
import estojo from './../../assets/produtos/comprar/estojo.png'


//import b1 from './../../assets/produtos/comprar/b1.png'
//import b2 from './../../assets/produtos/comprar/b2.png'
//import b3 from './../../assets/produtos/comprar/b3.png'
//import b4 from './../../assets/produtos/comprar/b4.png'
//import b5 from './../../assets/produtos/comprar/b5.png' 




//videos

import intro from './../../assets/intro/introbabuPT.mp4'




//Featured
//import Featured from './../components/Featured'




import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"
import benficalogo from "./../../assets/logos/benficalogo.png"
import Valentine from "./../../assets/logos/valentine.png"

class Home extends Component {
    constructor(){
        super()
        this.featured= false;
        this.state={
            formactivation: false,
            switch:"higiene",
            spot_higiene: [
                {id:"01", img:escova, alt:"", name:"Escovas de dentes", to:"/produtos/escova", link:"https://shop.babueco.com/produto/escovas-de-dentes/" },
                {id:"02", img:cana, alt:"", name:"Canas de transporte", to:"/produtos/canas", link:"https://shop.babueco.com/produto/cana-transportadora/" },
                {id:"03", img:fio, alt:"", name:"Fio dentário", to:"/produtos/fio", link:"https://shop.babueco.com/produto/fio-dentario/" },
                {id:"04", img:swab, alt:"", name:"Cotonetes", to:"/produtos/cotonetes", link:"https://shop.babueco.com/produto/cotonetes/" },
                {id:"05", img:konjac, alt:"", name:"Esponjas konjac", to:"/produtos/konjac", link:"https://shop.babueco.com/produto/esponja-de-konjac/" },
                {id:"06", img:cabelo, alt:"", name:"Escova de cabelo", to:"/produtos/escovadecabelo", link:" https://shop.babueco.com/produto/escova-de-cabelo-bambu/" },
                {id:"07", img:pasta, alt:"", name:"Pasta de dentes", to:"/produtos/pastadedentes", link:"  https://shop.babueco.com/produto/pasta-de-dentes/" },

            ],
            spot_babulife: [
                {id:"01", img:straw, alt:"", name:"Palhinhas", to:"/produtos/palhinha", link:"https://shop.babueco.com/produto/palhinhas/" },
                {id:"02", img:talheres, alt:"", name:"Talheres", to:"/produtos/talheres", link:"https://shop.babueco.com/produto/talheres/" },
                {id:"03", img:colherescafe, alt:"", name:"Colheres de café", to:"/produtos/colhercafe", link:"https://shop.babueco.com/produto/colheres-de-cafe/" },
                {id:"04", img:colhergarfo, alt:"", name:"Colher-garfo", to:"/produtos/colhergarfo", link:"https://shop.babueco.com/produto/colher-garfo/" },
                {id:"05", img:estojo, alt:"", name:"Estojo de refeição", to:"/produtos/estojo", link:"https://shop.babueco.com/produto/estojo-de-refeicao/" },
            ],
           /* spot_natal: [
                {id:"b1",previous:"14,97€", name:"13,47€", img:b1, link:"https://shop.babueco.com/produto/conjunto-1/" },
                {id:"b2",previous:"13,97€", name:"12,00€", img:b2, link:"https://shop.babueco.com/produto/conjunto-2/" },
                {id:"b3",previous:"17,47€", name:"15,27€", img:b3, link:"https://shop.babueco.com/produto/conjunto-3/" },
                {id:"b4",previous:"16,97€", name:"13,92€", img:b4, link:"https://shop.babueco.com/produto/conjunto-4/" },
                {id:"b5",previous:"18,98€", name:"14.99€ - Na compra de um Estojo de refeição, oferecemos-te uma Escova de dentes média.", img:b5, link:"https://shop.babueco.com/produto/conjunto-5/" },
            ],*/
            spot: [
                {id:"01", img:escova, alt:"", name:"Escovas de dentes", to:"/produtos/escova", link:"https://shop.babueco.com/produto/escovas-de-dentes/" },
                {id:"02", img:cana, alt:"", name:"Canas de transporte", to:"/produtos/canas", link:"https://shop.babueco.com/produto/cana-transportadora/" },
                {id:"03", img:fio, alt:"", name:"Fio dentário", to:"/produtos/fio", link:"https://shop.babueco.com/produto/fio-dentario/" },
                {id:"04", img:swab, alt:"", name:"Cotonetes", to:"/produtos/cotonetes", link:"https://shop.babueco.com/produto/cotonetes/" },
                {id:"05", img:konjac, alt:"", name:"Esponjas konjac", to:"/produtos/konjac", link:"https://shop.babueco.com/produto/esponja-de-konjac/" },
                {id:"06", img:cabelo, alt:"", name:"Escova de cabelo", to:"/produtos/escovadecabelo", link:" https://shop.babueco.com/produto/escova-de-cabelo-bambu/" },
                {id:"07", img:pasta, alt:"", name:"Pasta de dentes", to:"/produtos/pastadedentes", link:"  https://shop.babueco.com/produto/pasta-de-dentes/" },

            ],
        }
        this.handleForm = this.handleForm.bind(this)
        this.openForm = this.openForm.bind(this)
        this.handleAddition = this.handleAddition.bind(this)
    }
    componentDidMount(){
        console.log(`Hi there! how come you got here?
        This website was done by Creative Discovery! Interested in our work ? Check us out at https://creativediscovery.pt!`)
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
         event.target.playVideo();
         event.target.mute();
      }
      
    _onEnd(event) {
    event.target.playVideo();
    }

    openForm(){
        this.setState({
            formactivation:!this.state.formactivation
        })
    }
    handleSwitch(prop){
        let nextstate = prop;
        this.setState({switch:nextstate})

        if(prop==="higiene"){
            this.setState({spot:this.state.spot_higiene})
        }else if(prop==="natal"){
            this.setState({spot:this.state.spot_natal})
        }
        else{
            this.setState({spot:this.state.spot_babulife})
            
        }
    }

    handleForm(e){
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: '',
        formactivation:!this.state.formactivation
        });
    
        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "newsletter.php",
        type: 'POST',
        data: {
            'email': this.state.email,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Foi adicionado à nossa lista!</h2></div>'
            });
            $('#containerText').slideUp();
            $('#containerText').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
    }

    handleAddition(e){
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

  render() {
    const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
    };

    return (
        <main className="home_babu">
           {/* <header id="home" className="hero masthead" style={{background: `url(${feat1}) no-repeat center center`,backgroundSize:`cover`}}>
                <img className='ball_time' src={feat2} alt=""/>
            </header>*/}
            <header id="home" className="hero masthead" style={{background: `url(${feat1}) no-repeat center center`,backgroundSize:`cover`}}>
                <div className="cta__ hero__container">
                    <Fade forever left >
                        <div className="cta__image">
                        <a href='https://benfica.babueco.com/'><img src={feat2} alt="" /></a>
                        </div>
                    </Fade>
                    <Fade forever right>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <a href='https://benfica.babueco.com/'>
                                    <h2 style={{color:"fff"}}>Ser benfiquista é<br/> ter alma de campeão eco-friendly</h2>
                                    <h4 className='lightname'>Conjunto para verdadeiros campeões</h4>
                                    <h3 className='box' style={{color:"#fff"}}>Portes grátis nos conjuntos do Benfica</h3>
                                </a>
                                <a href="https://benfica.babueco.com/">
                                    <div className='bundle_div'>
                                        <span className="button__babu">Veja os nossos conjuntos</span>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </Fade>
                </div> 

                <h3 className='tinyheader'>A cada 1€ de compras recebe 1 mega ponto, para descontares em futuras compras.</h3>
            </header>
           {/* <header className={this.featured ? "home featured" : "hero"}>
                <div className="hero__container ">
                  <div className="video-background">
                    <div className="video-foreground">

                        <ReactPlayer
                            playing
                            playsinline	
                            url={[intro]}
                            loop
                            muted
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div>
                </div>
            </header>*/}
            {/* {this.featured ? <Featured/> : ""} */}
            <section className="produtos">
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div>
                        <div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div>
                        <div className="benfica"><a href="https://benfica.babueco.com/" target="_blank"><img src={benficalogo} alt="" /></a></div>
                        {/*<div className=""><a href="https://shop.babueco.com/en/produto/babu-valentine" target="_blank"><img src={Valentine} alt="" /></a></div>*/}
                    </div>
                </header>
            </header>
                <div className="produtos_container">
                    <Carousel 
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all 1s"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        { this.state.switch==="natal" ? 
                        this.state.spot.map((item,index)=>{
                            return(
                                <div key={index + item.name} className="produto">
                                    <Link to={item.to}><div className="produto__img"><img src={item.img} alt="" /> </div></Link>
                                    <div className="produto__button">
                                        <Link to={item.to}><button className="button__babu">{item.name}</button></Link>
                                    </div>
                                </div>
                            )
                        })
                        :
                        this.state.spot.map((item,index)=>{
                            return(
                                <div key={index + item.name} className="produto">
                                    <a href={item.link}><div className="produto__img"><img src={item.img} alt="" /> </div></a>
                                    <div className="produto__button">
                                      <a href={item.link}><button className="button__babu">{item.name}</button></a>
                                    </div>
                                </div>
                            )
                        })}


                        {/* THis is the christmas switch 
                       { this.state.switch==="natal" ? 
                        this.state.spot.map((item,index)=>{
                            return(
                                <div key={index + item.name} className="produto">
                                    <div className="produto__img"><img src={item.img} alt="" /> </div>
                                    <div className="produto__button">
                                        <a href={item.link}><button className="button__babu">{item.name}</button></a>
                                        <div className="price_before"><span>Antes:</span> <s>{item.previous}</s></div>
                                    </div>
                                </div>
                            )
                        })
                        
                        
                        */}
                    </Carousel>
                </div>
            </section>
            <section className="hero">
                <div className="cta__">
                    <div className="cta__containertext .cta__containertext.home--container">
                        <div className="container__text--white">
                            <h2>Tudo sobre os produtos Babu e Babu life</h2>
                            <Link to="/produtos"><button className="button__babu">Saber mais</button></Link>
                        </div>
                    </div>
                    <div className="cta__image">
                        <img  src={fotoproduct} alt=""/>
                    </div>
                </div>
            </section>
            <section className="hero">
                <div className="cta__about">
                    <div className="cta__imagetext">
                        <img src={certs} alt="" />
                    </div>
                    <div className="cta__containertext home--container" >
                        <h2>Quem somos</h2>
                        <div className="container__text">
                            <Link to="/sobre"><button className="button__babu">Saber mais</button></Link>
                        </div>
                    </div>
                
                </div>
            </section>

            <section className="hero">
                <div className="cta__">
                    <div className="cta__containertext">
                        <div className="container__text--white">
                            <h2>Conheça as causas sociais que a Babu apoia</h2>
                            <Link to="/causas"><button className="button__babu">Saber mais</button></Link>

                        </div>
                    </div>
                    <div className="cta__image">
                        <img src={redPanda} alt="" />
                    </div>
                   
                </div>
            </section>


            <section className="hero">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={whybabu} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Porquê Babu?</h2>
                                <Link to="/porque"><button className="button__babu">Saber mais</button></Link>
                            </div>
                        </div>
                </div>
            </section>
            <section className="hero subscription">
                <div className="cta__--white">
                    <div className="cta__containertext">
                        <div className="container__text" id="containerText">
                            <h2>
                                Já subscreveu a nossa newsletter?
                            </h2>
                            <form className="news__sub" action="newsletter.php" onSubmit={this.handleForm}>
                                <input type="email" name="email" onChange={this.handleAddition} placeholder="Insira o seu email" required/>
                                <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>Li e compreendi a  <a href="http://shop.babueco.com/politica-privacidade/">Politica de Privacidade</a> da Babu.</span></p></div>
                                <button onSubmit={()=>this.handleForm()} className="button__babu">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
      </main>
    )
  }
}

export default Home

