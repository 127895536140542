import React, { Component } from 'react'
import $ from "jquery";

//<img src={imgcontact} alt=""/>


import imgcontact from './../../assets/produtos/foto-fala-connosco.png'

import map from './../../assets/paises/map.jpg'

class Contactos extends Component {
constructor(props){
    super(props)
    this.state={
        name:"",
        email:"",
        message:"",

    }
    this.handleAddition = this.handleAddition.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForm  = this.handleForm.bind(this);
    this.openForm = this.openForm.bind(this);

}

handleAddition(e){
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleForm(e){
    e.preventDefault();
    this.setState({
    contactEmail: '',
    contactMessage: '',
    formactivation:!this.state.formactivation
    });

    $.ajax({
    url: process.env.NODE_ENV !== "production" ? '/getMail' : "newsletter.php",
    type: 'POST',
    data: {
        'email': this.state.email,
    },
    cache: false,
    success: function(data) {
        // Success..
        this.setState({
        contactEmail: 'success',
        contactMessage: '<div class="sucess_contact"><h2>Foi adicionado à nossa lista!</h2></div>'
        });
        $('#containerText').slideUp();
        $('#containerText').after(this.state.contactMessage);
        console.log('success', data);
    }.bind(this),
    // Fail..
    error: function(xhr, status, err) {
        console.log(xhr, status);
        console.log(err);
        this.setState({
        contactEmail: 'danger',
        contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
        });
        console.log(this.state.email + this.state.message + 'fail');
    }.bind(this)
    })
}


  handleSubmit(e) {
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: ''
        });

        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "mailer.php",
        type: 'POST',
        data: {
            'name': this.state.name,
            'email': this.state.email,
            'message': this.state.message,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>A sua mensagem foi recebida!</h2><p>Os nossos agentes entrarão em contacto assim que for possivel!</p></div>'
            });
            $('#formContact').slideUp();
            $('#formContact').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
  }

  openForm(){
    this.setState({
        formactivation:!this.state.formactivation
    })
}

  render() {
    return (
        <main className="titlewrapper contacts">
            <header className="hero contact">
                <div className="cta__">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={imgcontact} alt=""/>
                    </div>
                    <div className="cta__containertext">
                        <div className="container__text--white">
                            <h2>Fale connosco</h2>
                        </div>
                    </div>
                </div>
            </header>
            <section className="contactmain">
                <section className="contactus">
                    <div className="container">
                        <form className="contactus__form" id="formContact" action="mailer.php" onSubmit={this.handleSubmit}>
                            <label htmlfor="name"> <span>Nome</span>
                                <input type="text" name="name" value={this.state.name} onChange={this.handleAddition} required />
                            </label>
                            <label htmlfor="email"> <span>E-mail</span>
                                <input type="email" name="email" value={this.state.email} onChange={this.handleAddition} required/>
                            </label>
                            <label htmlfor="message"> <span>Mensagem</span>
                                <textarea name="message" value={this.state.message} onChange={this.handleAddition} required></textarea>
                            </label>
                            <label htmlfor="privacy">
                                <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>Eu li e compreendi a </span> <a href="https://shop.babueco.com/politica-privacidade/">Politica de Privacidade</a><span> da Babu.</span></p></div>
                            </label>
                            <input className="button__babu" type="submit" value="Enviar" />
                        </form>
                    </div> 
                </section>
                <div className="linha"></div>
                <div className="info">
                        <div className="info__title">
                            <h3>Contactos</h3>
                        </div>
                        <div className="info_image"><img src={map} alt="imagem mapa"/></div>
                        <div className="info__address">
                            <span>Zona Industrial lote 11</span>
                            <span>2550-171 Cadaval, Portugal</span>
                            <a href="mailto:info@babueco.com"><span>info@babueco.com</span></a>
                        </div>
                </div>
            </section>
            <section className="hero subscription">
                <div className="cta__--white">
                    <div className="cta__containertext">
                        <div className="container__text" id="containerText">
                            <h2>
                                Já subscreveu a nossa newsletter?
                            </h2>
                            <form className="news__sub" action="newsletter.php" onSubmit={this.handleForm}>
                                <input type="email" name="email" onChange={this.handleAddition} placeholder="Insira o seu email" required/>
                                <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>Li e compreendi a  <a href="http://shop.babueco.com/politica-privacidade/">Politica de Privacidade</a> da Babu.</span></p></div>
                                <button onSubmit={()=>this.handleForm()} className="button__babu">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
  }
}

export default Contactos


