import React, { Component } from 'react'
import {Link} from "react-router-dom";

import smf from './../../assets/icons/smf.png'
import smi from './../../assets/icons/smi.png'
import smy from './../../assets/icons/smy.png'

import fundo from './../../assets/POCentro_PT2020_FEDER_Branco_Bom.png'


class Footer extends Component {
  render() {
    return (
    <footer className="footer">
          <div className="footer__container">
            <div className="terms">
              <div className="both">
                  <Link to="/perguntasfrequentes">Perguntas Frequentes</Link>
                  <a target="_blank" rel="noopener noreferrer" href="https://blog.babueco.com/">Blog</a>
                  <Link to="/contactos">Contactos</Link>
              </div>
            </div>
            <div className='social_funds'>

              <ul className="socialmedia">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BabuEco/"><img src={smf} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/babueco/"><img src={smi} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCafY2gShZXff-3whffG0Ihg"><img src={smy} alt=""/></a></li>
              </ul>
             <a target="_blank" rel="noopener noreferrer" href='https://shop.babueco.com/ficha-de-projeto-portugal-2020/'>
                <img src={fundo} style={{width:450}} alt="" />
    </a>
            </div>
            <div className="terms">
              <div className="both">
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/politica-privacidade/">Politica de Privacidade</a>
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/termos-e-condicoes/">Termos e condições</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.livroreclamacoes.pt/">Livro de reclamações</a>

              </div>
            </div>
         </div>
         <div className="copyright">
          <p>
            © {(new Date().getFullYear())} Babu
          </p>
        </div>

    </footer>
    )
  }
}

export default Footer
