import React, { Component } from 'react'

import Beach from './../../assets/produtos/dirtybeach.jpg'
import redPanda from './../../assets/produtos/panda-vermelho-carlosnunes.jpg'
import guru from './../../assets/produtos/causas003.jpg'
import causas from './../../assets/produtos/causas.jpg' 

export default class socialCauses extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
  render() {
    return (
    <div className="causes">
        <section className="hero">
            <div className="cta__">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={causas} alt=""/>
                </div>
                <div className="cta__containertext">
                    <div className="container__text--white">
                        <h2>Causes sociales</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="introduction">
            <div className="container_textintro">
                <p>
                Babu considère que sa mission est bien plus que de fournir des alternatives écologiques au divers produits de consommation. Nous sommes convaincus que nous devons soutenir les causes, les organisations et les personnes qui sont passionnées pour rendre notre planète meilleure, plus saine et surtout plus harmonieuse. 
                
                Ainsi, Babu consacre une partie de ses recettes au soutien de trois dimensions :
                <ul>
                    <li><span>Préservation de l'environnement</span></li>
                    <li><span>Conservation des espèces</span></li>
                    <li><span>Rééducation pour améliorer</span></li>
                </ul>
                </p>
            </div>  
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Préservation de l'environnement</h2>
                        <div className="paragrafos">
                            <p>Nous soutenons des organisations et personnes qui oeuvrent en faveur de la protection de l’environnement, notamment en ce qui concerne le nettoyage et le ramassage d'objets polluants et/ou des déchets jetés par mégarde sur les  plages et forêts.
                             Ces actions de collecte n'améliorent pas seulement la qualité de ces endroits, mais sont aussi un moyen de sensibilisation pour une plus grande conscience environnementale et civique.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={Beach} alt="" />
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={redPanda} alt="" />
                </div>
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Conservation des espèces
                        </h2>
                        <div className="paragrafos">
                            <p>Nous avons établi des partenariats avec des institutions dont le but est la protection et promotion du développement de diverses espèces d'êtres vivants, menacées dans leur habitat naturel, qui puissent y vivre dans des conditions semblables à la nature et, si possible, être réintégrées dans leur milieu d’origine, favorisant ainsi la préservation de l'espèce et atténuant les risques d'extinction</p>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Rééducation pour améliorer coutumes et conduites
                        </h2>
                        <div className="paragrafos">
                            <p>Plus que résoudre le problème immédiat de la conséquence, il est important reculer un peu et se concentrer sur la cause, de façon à ne pas répéter les mêmes erreurs. C'est pourquoi Babu s’engage également sur des organisations et institutions éducatives qui ont la passion de transmettre une vision améliorée du rôle de l'humanité et de son impact sur notre écosystème,  à travers d’ateliers, conférences et actions de sensibilisation sur les nouvelles habitudes de consommation, ainsi que sur les nouvelles façons de faire face aux déchets ou même de les réduire complètement.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={guru} alt="" />
                </div> 
            </div>
        </section>

    </div>
    )
  }
}
