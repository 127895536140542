import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 



import panda from './../../../../assets/produtos/info/panda.png'
import kids from './../../../../assets/produtos/info/kids.png'

import canahorizontal from './../../../../assets/produtos/info/cana/canahorizontal.png'
import regua from './../../../../assets/produtos/info/cana/reguakids.png'




import canas from './../../../../assets/cana_en.mp4'


export default class CanasInfo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/en/produto/travel-case/",

        }
    }
  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
            
            <div className="brush">
                <div className="brush__title"><h5>Travel Case</h5></div>
                <div className="brush__controls"> 
                    <div className="measures brush">
                        <div className="blockb blockbr block1"></div>
                        <div className="blockb  block2"></div>
                        <div className="blockm block--3"></div>
                        <div className="blockm block--16">21 cm</div>
                    </div>
                    <div className="escova__img">
                        <img src={canahorizontal} alt="" />
                    </div>
                </div>
            </div>
            <article className="info__escovas">
                <section className="info__escovasB">
                    <div className="info__sec info__sec--one">
                        <div className="info__div">
                            <div className="text">
                                <h3>Size</h3>
                                <p>The Adult Size Travel Case is 21cm high and 2.5cm in diameter - the ideal size to fit in any suitcase or backpack.</p>
                            </div>
                        </div>               
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Benefits</h3>
                                <p>The Travel case is 100% made of bamboo. Besides helping us to transport the toothbrush, it protects it from bad odors, bacteria and dust.</p>
                            </div>
                        </div>
                    </div>
                    <div className="info__sec info__sec--two">
                        <div className="info__div">
                            <div className="text">
                                <h3>Use</h3>
                                <p>The Travel case is very versatile in its use. Besides being used to carry the toothbrushes it can be used to carry the straws or even as a case.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            </section>
            <section className="kids_section">
                <div className="panda_section">
                    <div className="panda_img"><img src={panda} alt=""/></div>
                </div>
                <article className="kids_container">
                    <div className="kids_title">
                        <h3>Do you already know Babu</h3>
                        <img src={kids} alt=""/>
                    </div>
                    <div className="kids_info">
                        <div className="kids_info--img">
                            <img src={regua} alt=""/>
                        </div>
                        <div className="kids_info--text">
                            <div className="description">
                                <div className="description-content">
                                    <p>
                                        Being 17cm in height and 2.5cm in diameter, it is the ideal size to fit in any suitcase or backpack.
                                        <br/>
                                        <br/>
                                        Made from 100% bamboo, this portable case will not only help prevent odors, bacteria or dust, but will also carry and protect the Babu toothbrush.
                                    </p>

                                </div>
                            </div>
                            <div className="colours">
                                <div className="colours-container">
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                    <div className="colour-box"> <img src="" alt=""/></div>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
              </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Buy now</span></a>
            </div>
              <div className="used">
                <div className="used"><h3>Preserve Your Travel Case</h3></div>
                <div className="video__info">
                    <ReactPlayer
                        playing
                        playsinline	
                        url={[canas]}
                        loop
                        muted
                    />
                </div>
              </div>

      </section>
    </main>
    )
  }
}
