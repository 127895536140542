import React, { Component } from 'react'



import cotonetes from './../../../../assets/produtos/pastadedentes.png'


export default class Pasta extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/pasta-de-dientes/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
  
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Pasta de dientes</h5></div>
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={cotonetes} alt="FIO" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Composición</h3>
                                      <p>La primera pasta dental Babu, con ingredientes naturales y sin SLS (Sodium lauryl sulfate) ni Triclosan. No es adecuado para los niños. Contiene flúor.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Sabor</h3>
                                      <p>Tiene un sabor a limón para que después de cepillarse sienta una frescura de limpieza combinada con la comodidad de saber que está perfectamente sana sin introducir químicos dañinos en el cuerpo.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>100% reciclable</h3>
                                      <p>Babu se ha comprometido firmemente a presentar una pasta de dientes con ingredientes saludables, pero también que sea práctica, higiénica y sobre todo: con la menor pisada ecológica posible. Por eso hemos optado por un tubo 100% reciclable con la menor emisión de CO2 en todo su ciclo de vida, desde el proceso de producción hasta su reciclaje.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>
              </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






