import React, { Component } from 'react'


import erro from './../../assets/erro.png'


class ErrorPage extends Component {
  static propTypes = {

  }

  render() {
    return (
      <section className="errorpage"> 
        <div className="errorpage__img">
            <img src={erro} alt=""/>
        </div>
        <p> Encontrou um Erro 404. Não conseguimos encontrar a página que pretende. :( </p>

      </section>
    )
  }
}

export default ErrorPage
