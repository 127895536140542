import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import {
//  TransitionGroup,
//  CSSTransition,
//} from 'react-transition-group'
import ScrollToTop from './components/ScrollToTop'
//import Cookies from 'js-cookie'
//import PropTypes from "prop-types";


import Home from './pages/home'
import Produtos from './pages/produtos'
import Comprar from './pages/comprar'
import About from './pages/aboutUs'
import Why from './pages/porque'
import Resell from './pages/resell'
import SocialCauses from './pages/socialcauses'
import Contacts from './pages/contactos'
import Faq from './pages/faq'
import ErrorPage from "./pages/404";




// componennts 
import Header from './components/header'
import Footer from './components/footer'

function App() {
  return (
    <div className="wrapper">
    <Router>
      <Router>
        <Route render={({ location })=>(
          <>
          <Header/>
        
          <ScrollToTop>
             <Switch location={location} >
                  <Route exact path="/" render={()=> <Home />} />
                  <Route path="/products" component={Produtos}/>
                  <Route path="/store" component={Comprar}/>
                  <Route path="/about" component={About}/>
                  <Route path="/why" component={Why}/>
                  <Route path="/causes" component={SocialCauses}/>
                  <Route path="/contacts" component={Contacts}/>
                  <Route path="/wheretofind" component={Resell}/>
                  <Route path="/faq" component={Faq}/>
                  

                  <Redirect from="/productos" to="/products"/>
                  <Redirect from="/tienda" to="/store"/>
                  <Redirect from="/sobre" to="/about"/>
                  <Redirect from="/porque" to="/why"/>
                  <Redirect from="/causas" to="/causes"/>
                  <Redirect from="/contactos" to="/contacts"/>
                  <Redirect from="/donde" to="/wheretofind"/>
                  <Redirect from="/preguntasfrecuentes" to="/faq"/>


                  <Redirect from="/produtos" to="/products"/>
                  <Redirect from="/loja" to="/store"/>
                  <Redirect from="/sobre" to="/about"/>
                  <Redirect from="/porque" to="/why"/>
                  <Redirect from="/causas" to="/causes"/>
                  <Redirect from="/contactos" to="/contacts"/>
                  <Redirect from="/onde" to="/wheretofind"/>
                  <Redirect from="/perguntasfrequentes" to="/faq"/>
                  
                  <Redirect from="/produits" to="/products"/>
                  <Redirect from="/boutique" to="/store"/>
                  <Redirect from="/apropos" to="/about"/>
                  <Redirect from="/pourquoi" to="/why"/>
                  <Redirect from="/causessociales" to="/causes"/>
                  <Redirect from="/contact" to="/contacts"/>
                  <Redirect from="/Outrouver" to="/wheretofind"/>
                  <Redirect from="/questions" to="/faq"/>
                    
                  <Route component={ErrorPage}/>
                  
              </Switch>
          </ScrollToTop>
          </>
      )}/>
      <Footer/>
      </Router>
    </Router>
    </div>
    );
}

export default App;
