import React from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import './App.css'
import './fonts/fonts.css'
import En from './en/App'
import Pt from './pt/App'
import Es from './es/App'
import Fr from './fr/App'
import LangSwitch from './LangSwitch'


const SwitchFunc = (serv)=>{
    switch(serv){
        case 'pt-PT':
            return <Pt/> ;

        case 'es-ES':
            return <Es/> ;

        case 'fr-FR':
            return <Fr/> ;
        default:
            return <En/> ;
    }
}


const cookieFunc= (lang)=>{
    
let idioma = detectBrowserLanguage();
    let caselang
    if ( lang ){
        caselang = lang;
    }
    else{
        caselang = idioma;
    }
    let cookie = "";
    switch(caselang){
        case 'pt-PT':
             cookie = {
                allow: "Aceito os cookies",
                message: "Este website utiliza cookies estritamente necessários para o seu funcionamento ou dos serviços aqui prestados, mas também cookies de desempenho e cookies de segmentação que nos ajudam a melhorar a sua experiência e nos fornecem informações sobre a sua utilização e navegação no website. Com exceção dos cookies estritamente necessários, a utilização de cookies depende sempre da sua aceitação. ",
                dismiss: "",
                close: '&#x274c;',
                deny: "Não aceito",
                link: "Política de privacidade",
                href: "shop.babueco.com/politicadeprivacidade",
            } 
            return cookie;
            break;

        case 'es-ES':
             cookie = {
                allow: "Acepto cookies",
                message: "Este sitio web utiliza cookies estrictamente necesarias para su funcionamiento o los servicios proporcionados aquí, pero también cookies de rendimiento y cookies de segmentación que nos ayudan a mejorar su experiencia y nos proporcionan información sobre su uso y navegación en el sitio web. Con la excepción de las cookies estrictamente necesarias, el uso de cookies siempre depende de su aceptación. ",
                dismiss: "",
                close: '&#x274c;',
                deny: "No acepto",
                link: "Política de privacidad",
                href: "shop.babueco.com/es/politicadeprivacidad",
            } 
            return cookie;
            break;

        case 'fr-FR':
            cookie = {
                allow: "J'accepte les cookies",
                message: "Ce site internet utilise des cookies strictement nécessaires à son fonctionnement ou aux services rendus, mais utilise aussi des cookies de performance et des cookies de segmentation qui aident à améliorer votre interaction et fournissent des informations par rapport à votre utilisation et navigation sur le site internet. À l'exception des cookies qui sont strictement nécessaires, l'utilisation des cookies dépend toujours de votre acceptation ",
                dismiss: "",
                close: '&#x274c;',
                deny: "Je refuse",
                link: "Politique de confidentialité",
                href: "shop.babueco.com/fr/politiquedeconfidentialité",
            } 
            return cookie;
            break;

        default:
            cookie = {
                allow: "Accept cookies",
                message: "This website uses cookies strictly necessary for its operation or the services provided here, but also performance cookies and segmentation cookies that help us to improve your experience and provide us with information about your use and navigation on the website. With the exception of strictly necessary cookies, the use of cookies always depends on your acceptance. ",
                dismiss: "",
                close: '&#x274c;',
                deny: "I do not accept",
                link: "Privacy policy",
                href: "shop.babueco.com/en/privacypolicy",
            } 
            return cookie;
            break;
    }
}



class App extends React.Component {
    constructor(props){
        super(props)
        this.state={
            chosenlanguage: localStorage.getItem("chosenlanguage") ? localStorage.getItem("chosenlanguage") : detectBrowserLanguage(),
            currentcookie: cookieFunc(localStorage.getItem("chosenlanguage")),

        }
        this.handleLang = this.handleLang.bind(this)
        this.fireCookieTranslate=this.fireCookieTranslate.bind(this)
    }

    componentWillUpdate(){
        this.fireCookieTranslate();
    }

   

    handleLang(lang,click){
        if(click === true){
            this.setState({
                chosenlanguage: lang,
                currentcookie:cookieFunc(lang)
            })
            localStorage.setItem("chosenlanguage", lang)
            this.fireCookieTranslate(); 
            
        }
        else if(localStorage.getItem("chosenlanguage")){
            this.setState({
                chosenlanguage: localStorage.getItem("chosenlanguage"),
                currentcookie:cookieFunc(lang)
            })
            this.fireCookieTranslate();

        }
        else{
            this.setState({
                chosenlanguage: detectBrowserLanguage(),
                currentcookie:cookieFunc(lang)
            })
            this.fireCookieTranslate();
            
        }
        
    }
    fireCookieTranslate(){
        window.cookieconsent.initialise({
            "palette": {
            "popup": {
                "background": "#ffffff",
                "text": "#333333"
            },
            "button": {
                "background": "#bed600",
                "text": "#ffffff"
            }
            },
            "theme": "classic",
            "type": "opt-out",
            "content": {
            "allow": cookieFunc(this.state.chosenlanguage).allow,
            "message": this.state.currentcookie.message,
            "dismiss": "",
            "close": '&#x274c;',
            "deny": this.state.currentcookie.deny,
            "link": this.state.currentcookie.link,
            "href": this.state.currentcookie.href,
            "target": '_blank',
            "policy": 'Cookies'
            }
        })
    }


render(){
    let idioma = detectBrowserLanguage();
    const Language = this.state.chosenlanguage;
  return (
    <section className="Start">
        
        {/**/}<LangSwitch ChangeLang={this.handleLang}/>
        {SwitchFunc(Language)}
            <script>
            {window.cookieconsent.initialise({
                "palette": {
                "popup": {
                    "background": "#ffffff",
                    "text": "#333333"
                },
                "button": {
                    "background": "#bed600",
                    "text": "#ffffff"
                }
                },
                "theme": "classic",
                "type": "opt-out",
                "content": {
                "allow": cookieFunc(this.state.chosenlanguage,idioma).allow,
                "message": this.state.currentcookie.message,
                "dismiss": "",
                "close": '&#x274c;',
                "deny": this.state.currentcookie.deny,
                "link": this.state.currentcookie.link,
                "href": this.state.currentcookie.href,
                "target": '_blank',
                "policy": 'Cookies'
                }
            })}
            </script>
    </section>
    );
}
}

export default App;
