import React, { Component } from 'react'
import $ from "jquery";
import FAQ from "./extras/FAQ";

//<img src={imgcontact} alt=""/>

import seta from './../../assets/icons/seta.png'
import imgcontact from './../../assets/produtos/foto-fala-connosco.png'

class Faq extends Component {
constructor(props){
    super(props)
    this.state={
        name:"",
        email:"",
        message:"",

    }
    this.handleAddition = this.handleAddition.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForm  = this.handleSubmit.bind(this);
}

handleAddition(e){
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleForm(){
    this.setState({
        formactivation:!this.state.formactivation
    })
}


  handleSubmit(e) {
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: ''
        });

        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "mailer.php",
        type: 'POST',
        data: {
            'name': this.state.name,
            'email': this.state.email,
            'message': this.state.message,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Your message was received!</h2><p>Our agents will contact you as soon as possible!</p></div>'
            });
            $('#formContact').slideUp();
            $('#formContact').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Sorry but there was an error in the algorithm</h1><p>Try again later, or contact info@babueco.com directly</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
  }
  render() {
    return (
        <main className="titlewrapper faq">
            <header className="hero contact">
                <div className="cta__">
                    <div className="cta__image">
                        <img className="diagonal--inverted" src={imgcontact} alt=""/>
                    </div>
                    <div className="cta__containertext">
                        <div className="container__text--white">
                            <h2>Frequently asked questions</h2>
                        </div>
                    </div>
                </div>
            </header>
            <section className="faq_sec">
                <div className="faq_container">
                  <FAQ>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q1">
                        {(isOpen, onToggle) => {
                          return (
                              <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>Where are Babu toothbrushes made?</span>
                              </div>
                              );
                              
                            }
                        }
                      </FAQ.Question>
                      <FAQ.Answer id="q1"> Babu are produced in Asia, where the bamboo for our handles is located. The plant is moso bambú (Phyllostachys Edulis), being one of the fastest growing bamboo species in the world, not compromising the ecosystem as it is not edible for many species such as pandas. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q2">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>What material are Babu toothbrushes made of?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q2"> The bristle material is BPA-free nylon and as there are no biodegradable alternatives yet (excluding pig hair bristles which, although biodegradable, are terrible in many ways), we advise you to cut the bristles after using the brush and put in the ecopoints. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                      <FAQ.Question answerId="q3">
                        {(isOpen, onToggle) => {
                          return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>For how long can i keep my brush?</span>
                            </div>
                          );
                        }}
                      </FAQ.Question>
                      <FAQ.Answer id="q3"> The average time is between 2 and 3 months of use, but depends on several factors, such as the hardness of the chosen bristle, the pressure exerted during brushing, the number of daily uses, among others. The best way to analyze the durability of the brush is to observe the bristles conservation: when the bristles already have a high wear it means that it is time to change them.</FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q4">
                        {(isOpen, onToggle) => {
                            return (
                            <div className="question-tab">
                                <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                <span>After brushing, where should I store my toothbrush?</span>
                            </div>
                            );
                        }}
                        </FAQ.Question>
                        <FAQ.Answer id="q4">As bamboo is an absorbent material, it is advisable after brushing to leave your Babu in a dry place and / or without permanent contact with water to avoid moisture. Being in contact with moisture, the Babu looks darker. </FAQ.Answer>
                    </FAQ.QAItem>
                    <FAQ.QAItem>
                        <FAQ.Question answerId="q5">
                            {(isOpen, onToggle) => {
                            return (
                                <div className="question-tab">
                                    <img className={isOpen ? "Open-seta " : "Close-seta "} src={seta} alt=""/>
                                    <span>What to do with the toothbrush when it is finished?</span>
                                </div>
                            );
                            }}
                        </FAQ.Question>
                        <FAQ.Answer id="q5">When changing your Babu, we advise you to cut the bristles and put them in the ecopoints. Except for the bristles, all the rest of the material is biodegradable so we advise you to put the package in the blue ecopoint of the paper and the handle itself can even be buried next to a tree, for example.</FAQ.Answer>
                    </FAQ.QAItem>

                  </FAQ>
                </div>
           </section>
            <section className="contactus faq">
                <div className="container_title">
                        <h2>Do you have more questions? </h2>
                </div>
                <div className="container">
                    <form className="contactus__form" id="formContact" action="mailer.php" onSubmit={this.handleSubmit}>
                        <label htmlfor="name"> <span>Name</span>
                            <input type="text" name="name" value={this.state.name} onChange={this.handleAddition} required />
                        </label>
                        <label htmlfor="email"> <span>E-mail</span>
                            <input type="email" name="email" value={this.state.email} onChange={this.handleAddition} required/>
                        </label>
                        <label htmlfor="message"> <span>Message</span>
                            <textarea name="message" value={this.state.message} onChange={this.handleAddition} required></textarea>
                        </label>
                        <input className="button__babu" type="submit" value="Send" />
                    </form>
                </div> 
            </section>
        </main>
    )
  }
}

export default Faq
