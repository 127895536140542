import React, { Component } from 'react'
//import ReactPlayer from 'react-player'

import photoAbout from './../../assets/produtos/whybabu.png'


//import escolher from './../../assets/why_pt.mp4'



class About extends Component {

  render() {
    return (
      <main className="quem">
      <header className="hero">
          <div className="cta__">
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>¿Por qué Babu?</h2>
                  </div>
              </div>
              <div className="cta__image"> 
                  <img  src={photoAbout} alt=""/> </div>
          </div>
      </header>
      <section className="aboutus"> 
          <div className="aboutus__inicial">
            <div className="aboutus__container">
                <h4>¿POR QUÉ BABU EN BAMBÚ?</h4>
                <p>
                Las características del bambú como planta, sus excelentes propiedades como material y sus aplicaciones actuales en el mundo crean una amplia gama de usos posibles con potencial para responder a un desarrollo que proporciona una mayor equidad social, mejora del medio ambiente e incremento de la calidad de vida.
                    <br/>
                    Por tanto, el crecimiento de la población mundial ha dado lugar a una presión fuerte sobre el medio ambiente, proporcionando una disminución en la cantidad y en la calidad de los recursos forestales, contribuyendo de esta manera al interés de los nuevos recursos vegetales, como por ejemplo la utilización del bambú. De este modo, el bambú ha sido redescubierto como un recurso renovable y no contaminante.
                    <br/>
                    En la actualidad y en un mundo en el que desarrollo sostenible ya no es una opción, sino una necesidad, el bambú se puede utilizar abundantemente y reemplazar muchos materiales en la fabricación de diversos productos debido al menor tiempo de crecimiento y a la capacidad de ser un regenerador ambiental.

                </p>
            </div>
          </div>
          <div className="aboutus__sabias">
              <div className="sabias__boxtitle">
                  <h3 className="sabias__titulo">SABÍAS QUE EL BAMBÚ…</h3>
              </div>
              <div className="sabias porque">
                  <div className="sabias__column">
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>ES DE RÁPIDO CRECIMIENTO</h4>
                          </div>
                          <p>El bambú es la planta que más crece a diario del mundo. 
                          Actualmente el récord se alcanzó en Japón, donde esta planta ¡creció 121 centímetros en tan solo 24 horas!
                          </p>
                      </div>
                      <div className="sabias__box">
                          <div className="box__title">
                              <h4>POSEE
                              PROPIEDADES MEDICINALES
                              </h4>
                          </div>
                          <p>El carbón de bambú tiene varias propiedades medicinales y su uso es muy diversificado. Hay quienes lo usan en baños de inmersión, por ejemplo, además de la utilización sus hojas en infusiones con múltiples beneficios.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>ES EXCELENTE
                            PARA LOS DIENTES
                            </h4>
                        </div>
                        <p>Es la planta con la mayor concentración de sílice en el planeta, lo que refuerza el trabajo del calcio para la construcción de huesos y dientes fuertes.<br/> Los nutrientes del bambú también son beneficiosos para la encía al fortalecerla.</p>
                      </div>
                      <div className="sabias__box">
                        <div className="box__title">
                            <h4>ES UN SUSTITUTO DEL PAPEL</h4>
                        </div>
                        <p>Es una planta vigorosa que produce sus propios agentes antibacterianos y que se desarrolla muy bien sin pesticidas. Sus fibras porosas permiten producir tejidos que respiran y que son tan suaves como la seda. </p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>TIENE MÚLTIPLES
                            FINES
                            </h4>
                        </div>
                        <p>Actualmente hay más de 4000 usos posibles para el bambú y cada día más se descubren y se aplican nuevas formas. Estudios recientes indican que el etanol se puede eliminar del bambú y también se puede utilizar como combustible.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>CAPACIDADES REGENERATIVAS</h4>
                        </div>
                        <p>Tiene la capacidad de regenerarse después del corte, puede vivir hasta 100 años y, si se trata adecuadamente, puede convertirse en un material duradero durante muchas generaciones.<br/> Una materia prima renovable de bajo coste con muchas posibilidades de uso, el bambú se utiliza cada vez más para la conservación del medio ambiente.</p>
                    </div>
                  </div>

              </div>
          </div>

          <div className="aboutus__sabias">
            <div className="sabias__boxtitle">
                <h3 className="sabias__titulo">POR QUÉ ELEGIR UN BABU ES TAN IMPORTANTE</h3>
            </div>
            <div className="sabias porque">
                <div className="sabias__column">
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4> ALTERNATIVA A LOS TRADICIONALES</h4>
                        </div>
                        <p>Es la única alternativa real a los cepillos de plástico contribuyendo a la resolución de un problema que nos afecta a todos, ya que los cepillos tradicionales son responsables de toneladas de desechos cada año, lo que ocasiona un gran impacto ambiental.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>PRODUCTOS<br/> BIODEGRADABLES</h4>
                        </div>
                        <p>El bambú es totalmente biodegradable, a diferencia de los tradicionales que tardan ¡más de 400 años en descomponerse!<br/> Además de ser amigable con el medio ambiente, estás utilizando un producto de la naturaleza.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>ANTIBACTERIANO NATURAL</h4>
                        </div>
                        <p>El bambú como antibacteriano natural contiene agentes que impiden la proliferación de bacterias dañinas<br/> También es un material no poroso que evita la contaminación por hongos y bacterias, lo que contribuye a una higiene bucal más saludable.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>MANGO <br/> ERGONÓMICO</h4>
                        </div>
                        <p>Los Babu están diseñados para adaptarse perfectamente a su mano y boca, permitiendo un manejo correcto en el cepillado de dientes, lo que te permite elegir entre dos tamaños: adulto y niño.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>LA MEJOR
                            EXPERIENCIA ORAL
                            </h4>
                        </div>
                        <p>Las cerdas de los Babu están especialmente diseñadas para ofrecerte la mejor experiencia de cepillado, protegiendo sus dientes y encías mientras los deja limpios y sanos.</p>
                    </div>
                    <div className="sabias__box">
                        <div className="box__title">
                            <h4>PEQUEÑOS GESTOS, GRANDES CAMBIOS</h4>
                        </div>
                        <p>¡Las pequeñas actitudes repetidas por muchos tienen un gran impacto!<br/> El uso de los Babu ayudará a que grandes cambios ocurran, y al mismo tiempo que disfrutas de una experiencia de cepillado superior al tradicional, estás también garantizando un futuro mejor para las generaciones venideras.</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
  </main>
    )
  }
}

export default About
//tOWOMZK1Kog