import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import {
//  TransitionGroup,
//  CSSTransition,
//} from 'react-transition-group'
import ScrollToTop from './components/ScrollToTop'
//import Cookies from 'js-cookie'
//import PropTypes from "prop-types";


import Home from './pages/home'
import Produtos from './pages/produtos'
import Comprar from './pages/comprar'
import About from './pages/aboutUs'
import Why from './pages/porque'
import Resell from './pages/resell'
import SocialCauses from './pages/socialcauses'
import Contacts from './pages/contactos'
import Faq from './pages/faq'
import ErrorPage from "./pages/404";




// componennts 
import Header from './components/header'
import Footer from './components/footer'

function App() {
  return (
    <div className="wrapper">
    <Router>
      <Router>
        <Route render={({ location })=>(
          <>
          <Header/>
        
          <ScrollToTop>
          <Switch location={location} >
                  <Route exact path="/" render={()=> <Home />} />
                  <Route path="/produits" component={Produtos}/>
                  <Route path="/boutique" component={Comprar}/>
                  <Route path="/apropos" component={About}/>
                  <Route path="/pourquoi" component={Why}/>
                  <Route path="/causessociales" component={SocialCauses}/>
                  <Route path="/contact" component={Contacts}/>
                  <Route path="/Outrouver" component={Resell}/>
                  <Route path="/questions" component={Faq}/>

                  
                  <Redirect from="/produtos" to="/produits"/>
                  <Redirect from="/loja" to="/boutique"/>
                  <Redirect from="/sobre" to="/apropos"/>
                  <Redirect from="/porque "to="/pourquoi"/>
                  <Redirect from="/causas" to="/causessociales"/>
                  <Redirect from="/contactos" to="/contact"/>
                  <Redirect from="/onde" to="/Outrouver"/>
                  <Redirect from="/perguntasfrequentes" to="/questions"/>


                  <Redirect from="/productos" to="/produits"/>
                  <Redirect from="/tienda" to="/boutique"/>
                  <Redirect from="/sobre" to="/apropos"/>
                  <Redirect from="/porque" to="/pourquoi"/>
                  <Redirect from="/causas" to="/causessociales"/>
                  <Redirect from="/contactos" to="/contact"/>
                  <Redirect from="/donde" to="/Outrouver"/>
                  <Redirect from="/preguntasfrecuentes" to="/questions"/>


                  <Redirect from="/products" to="/produits"/>
                  <Redirect from="/store" to="/boutique"/>
                  <Redirect from="/about" to="/apropos"/>
                  <Redirect from="/why" to="/pourquoi"/>
                  <Redirect from="/causes" to="/causessociales"/>
                  <Redirect from="/contacts" to="/contact"/>
                  <Redirect from="/wheretofind" to="/Outrouver"/>
                  <Redirect from="/faq" to="/questions"/>
                  
                  <Route component={ErrorPage}/>

              </Switch>
          </ScrollToTop>
          </>
      )}/>
      <Footer/>
      </Router>
    </Router>
    </div>
    );
}

export default App;




/*

*/