import React, { Component } from 'react'


import estojo from './../../../../assets/produtos/comprar/estojo.png'



export default class Estojo extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/produto/estojo-de-refeicao/",

        }
    }
    render() {
      return (
          <main className="produtos--changed">
            <section className="infoproducts">
              <section className="tipos">
                  <div className="brush">
                      <div className="brush__title"><h5>Estojo de refeição</h5></div>
                       
                      <div className="brush__controls"> 
                          <div className="escova__img">
                              <img src={estojo} alt="Estojo" />
                          </div>
                      </div>
                  </div>
                  <article className="info__escovas">
                      <section className="info__escovasB">
                          <div className="info__sec info__sec--one">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Quantidade</h3>
                                      <p>Dispõe de duas colheres, uma de tamanho tradicional e outra para cafés ou chás, uma faca e um garfo, uma palhinha, um escovilhão de limpeza, dois pauzinhos chineses e um estojo em algodão.</p>
                                  </div>
                              </div>               
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Utilização</h3>
                                      <p>Conjunto completo de refeições, sendo fácil e prático de transportar para qualquer ocasião e ecológico!</p>
                                  </div>
                              </div>
                          </div>
                          <div className="info__sec info__sec--two">
                              <div className="info__div">
                                  <div className="text">
                                      <h3>Durabilidade</h3>
                                      <p>Para preservar a sua durabilidade devem ser lavadas manualmente e colocadas num local seco.</p>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </article>

              </section>

            <div className="buynow-btn">
                <a href={this.state.link}><span>Compre já</span></a>
            </div>
        </section>
      </main>
      )
    }
  }






