import React, { Component } from 'react'
import ReactPlayer from 'react-player'

/* media */ 

import floss from './../../../../assets/produtos/dentalfloss.png'


import panda from './../../../../assets/produtos/info/pandacloud.png'


// utilização

import fio1 from './../../../../assets/produtos/fio/fio1.mp4'
import fio2 from './../../../../assets/produtos/fio/fio2.mp4'
import fio3 from './../../../../assets/produtos/fio/fio3.mp4'



export default class Konjac extends Component {
    constructor(props){
        super(props)
        this.state={
            link:"https://shop.babueco.com/es/produto/hilo-dental/",
        }
    }

    mouseOver(step){
        console.log('got this far')
        let astep = step;
        this.setState({
            step: astep,
        })
        console.log(astep)
    }


  render() {
    return (
        <main className="produtos--changed">
          <section className="infoproducts">

            <section className="tipos">
                <div className="brush">
                    <div className="brush__title"><h5>Hilo dental</h5></div>
                    <div className="brush__controls"> 
                        <div className="escova__img">
                            <img src={floss} alt="FIO" />
                        </div>
                    </div>
                </div>
                <article className="info__escovas">
                    <section className="info__escovasB">
                        <div className="info__sec info__sec--one">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Tamaño</h3>
                                    <p>El hilo dental de Babu tiene 50 metros de longitud, lo que permite un promedio de 250 usos.</p>
                                </div>
                            </div>               
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Composición</h3>
                                    <p>Este hilo de nailon contiene carbón activado en su composición para ayudar a remover las impurezas, lo que posibilita una dentición más fresca, limpia y sana.</p>
                                </div>
                            </div>
                        </div>
                        <div className="info__sec info__sec--two">
                            <div className="info__div">
                                <div className="text">
                                    <h3>Características</h3>
                                    <p>El envase ha sido diseñado con el fin de reducir el desperdicio al máximo, por lo que el mismo envase contiene el hilo dental y solo se deposita en el reciclaje al final de la vida útil del producto.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
            <div className="buynow-btn">
                <a href={this.state.link}><span>Comprar ahora</span></a>
            </div>
            <section className="utilizar">
                <div className="utilizar_container">
                    <div className="panda">
                        <img src={panda} alt="" />
                    </div>
                    <div className="utilizar_title">
                        <h3>¿Cómo usar el hilo dental?</h3>
                        <p>Descubre cómo usar el hilo dental pasando el ratón por encima de las imágenes.</p>
                    </div>
                    <div className="illustration_container">
                        <div className="illustration_content">
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step1")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step1" ? true : false}
                                        playsinline	
                                        url={[fio1]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Usa aproximadamente 40 cm de hilo dental y envuélvelo alrededor de los dedos medios.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step2")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step2" ? true : false}
                                        playsinline	
                                        url={[fio2]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Sujeta el hilo con los dedos índice y pulgar.</p>
                                </div>
                            </div>
                            <div className="steps">
                                <div className="video" onMouseOver={this.mouseOver.bind(this,"step3")}>
                                    <ReactPlayer
                                        playing={this.state.step === "step3" ? true : false}
                                        playsinline	
                                        url={[fio3]}
                                        loop
                                        muted
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div className="steps_text">
                                    <p>Desliza el hilo entre cada diente con un movimiento en forma de C. Repite el proceso 2 veces en cada diente.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </section>
    </main>
    )
  }
}
