import React, { Component } from 'react'

import Beach from './../../assets/produtos/dirtybeach.jpg'
import redPanda from './../../assets/produtos/panda-vermelho-carlosnunes.jpg'
import guru from './../../assets/produtos/causas003.jpg'
import causas from './../../assets/produtos/causas.jpg' 

export default class socialCauses extends Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
  render() {
    return (
    <div className="causes">
        <section className="hero">
            <div className="cta__">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={causas} alt=""/>
                </div>
                <div className="cta__containertext">
                    <div className="container__text--white">
                        <h2>Social Causes</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="introduction">
            <div className="container_textintro">
                <p>
                We truly believe that our mission is much more than to provide green alternatives for various consumer products: to support causes, organizations and people who are passionate about making our planet ever better, healthier and above all more harmonious.
                
                Therefore, Babu dedicates part of its revenues to supporting three strands:
                <ul>
                    <li>Environmental preservation</li>
                    <li>Conservation of species</li>
                    <li>Reeducation for better habits and conduct</li>
                </ul>
                </p>
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Environmental preservation</h2>
                        <div className="paragrafos">
                            <p>We support organizations and people who take action to preserve the environment, specifically with regard to cleaning and collecting polluting objects and / or inadvertently discarded garbage on beaches and forests. These actions will not only improve the quality of these places, but also promote increased environmental awareness and public civility.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={Beach} alt="" />
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws">
                <div className="cta__image">
                    <img className="diagonal--inverted" src={redPanda} alt="" />
                </div>
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Conservation of species
                        </h2>
                        <div className="paragrafos">
                            <p>We´ve established partnerships with institutions whose purpose is to protect and promote the development of various species of living beings that are endangered in their natural habitats and which can live in these institutions in similar conditions to nature and, where possible, be reintegrated into their environment, thus promoting the preservation of the species and mitigating the risks of extinction.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <section className="hero">
            <div className="cta__ straws inverted">
                <div className="cta__containertext" >
                    <div className="container__text--white">
                        <h2>Reeducation for better habits and behaviors
                        </h2>
                        <div className="paragrafos">
                            <p>Rather than solving the immediate problem of the consequence, it is important to step back and focus on the cause, so that the same results are not repeated. This is why Babu also bets on educational organizations and institutions that are passionate about conveying an enhanced view of the role of humanity and its impact on our ecosystem through several events:  workshops; lectures and awareness actions,leading to new consumer habits and new ways of dealing with waste or even reducing it altogether.</p>
                        </div>
                    </div>
                </div>
                <div className="cta__image">
                    <img className="" src={guru} alt="" />
                </div> 
            </div>
        </section>

    </div>
    )
  }
}
